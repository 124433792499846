<template>
  <div>
    <div class="calendar-head">
      <label class="switch switch-left-right">
        <input @click="changePlanningFunction" id="cbxAutoPlan" class="switch-input" type="checkbox"/>
        <span class="switch-label" data-on="Auto" data-off="Manuel"></span>
        <span class="switch-handle"></span>
      </label>

      <label class="switch switch-left-right">
        <input id="cbx_mode" class="switch-input" type="checkbox"/>
        <span class="switch-label" data-on="Classique" data-off="Indispo"></span>
        <span class="switch-handle"></span>
      </label>

      <SimpleTypeahead
          id="typeahead_id"
          placeholder="Rechercher un élève..."
          :items="eleves"
          :minInputLength="1"
          :itemProjection="
                  (item) => {
                    return item.fullname;
                  }
                "
          @selectItem="selectItem"
      >
      </SimpleTypeahead>

      <b-button variant="danger" v-if="this.itemSelect" @click="deleteEleveSelect">X</b-button>
      <div class="infoEleve" v-if="this.itemSelect">
        <router-link :to="{name:'solde', params: {id:this.itemSelect.id}}">
          <b-button variant="outline-primary" v-if="this.itemSelect">Solde</b-button>
        </router-link>
        <router-link :to="{name:'agence-trame', params: {id:this.itemSelect.id}}">
          <b-button variant="outline-primary" v-if="this.itemSelect">Fiche</b-button>
        </router-link>

      </div>

      <b-button variant="outline-info" id="myBtnMono" @click="btnModalMono">Monos</b-button>

    </div>

    <!-- The Modal -->
    <div id="myModalMono" class="modal">

      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span id="closeMono" class="close mono" @click="closeModalMono">&times;</span>
          <h2>Monos Modal</h2>
        </div>
        <div class="modal-body">
          <h2>Liste des moniteurs</h2>
          <template v-for="(moniteur, index) in moniteurs" :key="index">
            <label class="container">{{ moniteur.nom }} {{ moniteur.prenom }}
              <input class="monoCheck" @click="moniteurChoice" type="checkbox" checked="checked" :value="moniteur.id">
              <span class="checkmark"></span>
            </label>
            <Accordion>
              <AccordionTab header="Lieux du moniteurs">
                <template v-for="(lieu, i) in moniteur.lieux" :key="i">
                  {{ lieu.nom }} <br>
                </template>
              </AccordionTab>
            </Accordion>
            <button>Semaine Type</button>
            <template v-if="nbHeureMono != ''">

              <template v-for="(nb, i) in nbHeureMono" :key="i">
                <template v-if="nb.idMono == moniteur.idMonos">
                  <h4>{{nb.toth}} H</h4>
                </template>
              </template>

            </template>

          </template>
        </div>
        <div class="modal-footer">

        </div>
      </div>

    </div>

    <!-- The Modal -->
    <div id="CancelResaAg" class="modal">

      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span id="closeCancelResa" class="close mono" @click="closeModalMono">&times;</span>
          <h2>Cancel réservation</h2>
        </div>
        <div class="modal-body">
          <h2>Liste des moniteurs</h2>
          <select id="selectCancel">
            <option value="choix">Choix un type de motif</option>
            <template v-for="(type, index) in typeMotif" :key="index">
              <option :value="type.id">{{ type.label }}</option>
            </template>
          </select>
          <input type="text" placeholder="Veuillez sasir un motif" v-model="motif"/>
          <button @click="validerCancel">Valider</button>
        </div>
        <div class="modal-footer">

        </div>
      </div>

    </div>


    <div id="ModalResaAgenceEleve" class="modal">

      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span id="closeResaEleve" class="close mono" @click="closeModalMono">&times;</span>
          <h2>Réservation <b>{{ this.itemSelect.nom }}</b> {{ this.itemSelect.prenom }}</h2>
        </div>
        <div class="modal-body">
          <h2>Liste des lieux</h2>
          <select class="LieuResaEleve">
            <option value="choix">Veuillez choisir un lieu</option>
            <template v-for="(lieu, index) in lieuMono" :key="index">
              <option :value="lieu.id">{{ lieu.nom }}</option>
            </template>
          </select>
          <button @click="valideEleveResaAgence">Valider</button>
        </div>
        <div class="modal-footer">

        </div>
      </div>

    </div>
    <Dialog v-model:visible="display" modal=true>
      <template #header>
        <h3>CP / MA</h3>
      </template>

      <div>

        <input type="radio" id="Cp" name="cpma" value="CP"
               checked>
        <label for="Cp">CP</label>

        <input type="radio" id="Ma" name="cpma" value="MA">
        <label for="Ma">MA</label>

      </div>

      <template #footer>
        <Button @click="MakeMaCp" label="Valider" icon="pi pi-check" autofocus/>
      </template>
    </Dialog>

    <!-- The Modal -->
    <div id="ModalResaAgence" class="modal">


      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span id="closeResa" class="close resaAgence" @click="closeModalMono">&times;</span>
          <h2>Réservation Modal</h2>
        </div>
        <div class="modal-body">
          <div class="head-resa">

            <button
                @click="this.allEleveResa = false; this.DispoEleveThisMono = true; this.DispoEleveNotThisMono = false;this.ElevePDispo = false;">
              Elèves dispos pour ce mono
            </button>
            <button
                @click="this.allEleveResa = false; this.DispoEleveThisMono = false; this.DispoEleveNotThisMono = true;this.ElevePDispo = false;">
              Elèves dispos pour les autres moniteurs
            </button>
            <button @click="elevePdispo">Élève partiellement dispo</button>
            <button
                @click="this.allEleveResa = true; this.DispoEleveThisMono = false; this.DispoEleveNotThisMono = false;this.ElevePDispo = false;">
              Tous les élèves
            </button>

          </div>
          <div class="content-resa">
            <template v-if="this.DispoEleveThisMono">
              <h1>Dispo pour ce mono</h1>
              <table>
                <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prenom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Solde</th>
                  <th>Lieu choisit par l'élève</th>
                  <th>Selectionner</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(eleve, index) in eleveDispoThisMono" :key="index">
                  <td>{{ eleve.nom }}</td>
                  <td>{{ eleve.prenom }}</td>
                  <td>{{ eleve.email }}</td>
                  <td>{{ eleve.telephone }}</td>
                  <td>{{ eleve.solde }}</td>
                  <select>
                    <option value="choix">Lieu de l'élève</option>
                    <template v-for="(eleve, index) in eleveDispoThisMono" :key="index">
                      <template v-for="(dispo, i) in eleve.choixDispo" :key="i">
                        <template v-for="(d, id) in dispo" :key="id">
                          <template v-for="(lieu, idx) in lieuMono" :key="idx">
                            <option v-if="lieu.id == d" :value="lieu.id">{{ lieu.nom }}</option>
                          </template>
                        </template>
                      </template>
                    </template>
                  </select>
                  <td><input type="radio" name="allEleveResaRadio" :value="eleve.id"/></td>
                </tr>
                </tbody>
              </table>

              <select class="allEleveSelect">
                <option value="choix">Veuillez choisir un lieu</option>
                <template v-for="(lieu, idx) in lieuMono" :key="idx">
                  <option :value="lieu.id">{{ lieu.nom }}</option>
                </template>
              </select>
              <template v-if="working.resa == false">
                <button @click="ValiderResa" id="allEleve">Valider</button>
              </template>
              <template v-else>
                <button @click="ValiderResa" id="allEleve" disabled><span><i class="fa fa-spinner fa-spin"></i> Valider </span></button>
              </template>


            </template>

            <template v-if="this.DispoEleveNotThisMono">
              <h1>Eleve non dispo pour ce mono</h1>
              <table>
                <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prenom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Solde</th>
                  <th>Selectionner</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(eleve, index) in eleveNotDispoThisMono" :key="index">
                  <td>{{ eleve.nom }}</td>
                  <td>{{ eleve.prenom }}</td>
                  <td>{{ eleve.email }}</td>
                  <td>{{ eleve.telephone }}</td>
                  <td>{{ eleve.solde }}</td>
                  <td><input type="radio" name="allEleveResaRadio" :value="eleve.id"/></td>
                </tr>
                </tbody>
              </table>

              <select class="allEleveSelect">
                <option value="choix">Veuillez choisir un lieu</option>
                <template v-for="(lieu, index) in lieuMono" :key="index">
                  <option :value="lieu.id">{{ lieu.nom }}</option>
                </template>
              </select>
              <template v-if="working.resa == false">
                <button @click="ValiderResa" id="allEleve">Valider</button>
              </template>
              <template v-else>
                <button @click="ValiderResa" id="allEleve" disabled><span><i class="fa fa-spinner fa-spin"></i> Valider </span></button>
              </template>

            </template>

            <template v-if="this.allEleveResa">
              <h1>Tous les élèves</h1>
              <table>
                <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prenom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Solde</th>
                  <th>Selectionner</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(eleve, index) in eleves" :key="index">
                  <td>{{ eleve.nom }}</td>
                  <td>{{ eleve.prenom }}</td>
                  <td>{{ eleve.email }}</td>
                  <td>{{ eleve.telephone }}</td>
                  <td>{{ eleve.solde }}</td>
                  <td><input type="radio" name="allEleveResaRadio" :value="eleve.id"/></td>
                </tr>
                </tbody>
              </table>

              <select class="allEleveSelect">
                <option value="choix">Veuillez choisir un lieu</option>
                <template v-for="(lieu, index) in lieuMono" :key="index">
                  <option :value="lieu.id">{{ lieu.nom }}</option>
                </template>
              </select>
              <template v-if="working.resa == false">
                <button @click="ValiderResa" id="allEleve">Valider</button>
              </template>
              <template v-else>
                <button @click="ValiderResa" id="allEleve" disabled><span><i class="fa fa-spinner fa-spin"></i> Valider </span></button>
              </template>
            </template>

            <template v-if="this.ElevePDispo">
              <h1>Elève partiellement dispo</h1>
              <table>
                <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prenom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Solde</th>
                  <th>Dispo ini</th>
                  <th>Selectionner</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(eleve, index) in elevesP" :key="index">
                  <td>{{ eleve.nom }}</td>
                  <td>{{ eleve.prenom }}</td>
                  <td>{{ eleve.email }}</td>
                  <td>{{ eleve.tel }}</td>
                  <td>{{ eleve.tel }}</td>
                  <td>{{ eleve.pdispo }}</td>
                  <td><input type="radio" name="allEleveResaRadio" :value="eleve.id"/></td>
                </tr>
                </tbody>
              </table>

              <select class="allEleveSelect">
                <option value="choix">Veuillez choisir un lieu</option>
                <template v-for="(lieu, index) in lieuMono" :key="index">
                  <option :value="lieu.id">{{ lieu.nom }}</option>
                </template>
              </select>
              
              <template v-if="working.resa == false">
                <button @click="ValiderResa" id="allEleve">Valider</button>
              </template>
              <template v-else>
                <button @click="ValiderResa" id="allEleve" disabled><span><i class="fa fa-spinner fa-spin"></i> Valider </span></button>
              </template>
            </template>
          </div>
        </div>
        <div class="modal-footer">

        </div>
      </div>

    </div>


    <FullCalendar :options="calendarOptions"/>
  </div>
</template>

<script>

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
//import dayGridPlugin from '@fullcalendar/daygrid'

import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import SimpleTypeahead from 'vue3-simple-typeahead'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

import moment from 'moment';


export default {

  components: {
    FullCalendar,
    SimpleTypeahead
  },

  data() {
    return {
      nbHeureMono: '',
      display: false,
      allEleveResa: false,
      DispoEleveThisMono: false,
      DispoEleveNotThisMono: false,
      ElevePDispo: false,

      working : {
        resa : false,
        resaEleve : false,
        cancelResa : false
      },

      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin, resourceTimeGridPlugin],
        initialView: 'resourceTimeGridWeek',
        themeSystem: "bootstrap",
        selectable: true,
        nowIndicator: true,
        navLinks: true,
        firstDay: 1,
        allDaySlot: true,
        slotMinTime: "07:00:00",
        slotMaxTime: "20:00:00",
        height: "auto",
        datesAboveResources: true,
        locale: "fr",
        timeZone: "local",
        editable: true,
        eventResizableFromStart: true,
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "resourceTimeGridWeek,resourceTimeGridDay",
        },
        buttonText: {
          today: "Aujourd'hui",
          resourceTimeGridDay: "Jour",
          resourceTimeGridWeek: "Semaine",
          prev: "<",
          next: ">"
        },
        resources: [],
        resourceOrder: "title",
        selectAllow: this.selectAllow,
        select: this.selectEvent,
        eventClick: this.deleteEvent,
        events: []

      },
      moniteurs: [],
      eleves: [],
      itemSelect: "",
      selectionItem: "",
      lieuMono: [],
      choixDateResa: "",
      eleveDispoThisMono: [],
      eleveNotDispoThisMono: [],
      lieuChoixEleve: [],
      typeMotif: [],
      motif: "",
      elevesP: [],
      conduiteMontant: 0,
      eCpMa: [],


    }
  },

  mounted() {
    this.getFonctionnalite();
    this.getMoniteurs();
    this.getEleves();
    this.getAgence();
  },

  methods: {

    MakeMaCp() {
      let radios = document.getElementsByName("cpma");
      let motif = "";
      if (radios[0].checked == true) {
        motif = "CP";
      } else {
        motif = "MA";
      }
      this.display = true;

      window.api.post("/rdv/cancel", {
            start: this.eCpMa["start"],
            motif: motif,
            id: this.eCpMa["resource"]["_resource"]["id"],
          },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        response.data.rdvs.forEach(rdv => {
          let resa = this.calendarOptions.events.find(event => {
            if (event.id == rdv.id && event.type == "resa") {
              return true
            }
          })

          resa.color = "grey";
          resa.motif = "Mono Absent";
          resa.title = resa.title + " Motif : " + motif;

          let resultResa = this.calendarOptions.events.filter(event => {
            if (event.id != rdv.id && event.type == "resa") {
              return true
            }
          })
          let resultDispo = this.calendarOptions.events.filter(event => {
            if (event.type == "dispo") {
              return true
            }
          })

          let resultIndispo = this.calendarOptions.events.filter(event => {
            if (event.type == "Indispo") {
              return true
            }
          })

          let result = []

          resultResa.push(resa)

          resultDispo.forEach(resDispo => {
            result.push(resDispo)
          })

          resultResa.forEach(resResa => {
            result.push(resResa)
          })

          resultIndispo.forEach(resIndispo => {
            result.push(resIndispo)
          })

          this.calendarOptions.events = result
        })
        window.api.post("/indisponibilites", {
              start: moment(this.eCpMa.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(this.eCpMa.endStr).format("YYYY-MM-DDTHH:mm:ss"),
              moniteur: "api/moniteurs/" + this.eCpMa["resource"]["_resource"]["id"],
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
          this.display = false;
          this.calendarOptions.events.push({
            id: response.data.id,
            title: "INDISPO",
            start: moment(this.eCpMa.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(this.eCpMa.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            color: "grey",
            type: "Indispo",
            "motif": false,
            "resourceId": this.eCpMa["resource"]["_resource"]["id"]
          })
        }).catch(error => {
          console.log(error)
        })
      }).catch(error => {
        console.log(error)
      })
    },
    getFonctionnalite() {
      window.api.get("/fonctionnalites/4", {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.conduiteMontant = response.data.montant;
      }).catch(error => {
        console.log(error)
      })
    },

    elevePdispo() {

      window.api.post("/elevespdispo/agence/" + this.$store.state.user.agence.id, {
            start: this.choixDateResa.start,
            end: this.choixDateResa.end
          },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        this.allEleveResa = false;
        this.DispoEleveThisMono = false;
        this.DispoEleveNotThisMono = false;
        this.ElevePDispo = true;
        this.elevesP = response.data.eleves;
      }).catch(error => {
        console.log(error)
      })

    },

    getAgence() {
      window.api.get("/agences/" + this.$store.state.user.agence.id, {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        if (response.data.autoPlanning) {
          document.getElementById("cbxAutoPlan").checked = true
        } else {
          document.getElementById("cbxAutoPlan").checked = false
        }
      }).catch(error => {
        console.log(error)
      })
    },

    validerCancel() {
      let selectCancel = document.getElementById("selectCancel");

      let typemot = this.typeMotif.find(typmot => {
        return typmot.id == selectCancel.value
      })
      window.api.post("/motifs", {
            typeMotif: "api/type_motifs/" + selectCancel.value,
            label: this.motif
          },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        let now = new Date
        now.setHours(now.getHours() + 1)
        window.api.patch("/apointments/" + this.choixDateResa.event["_def"]["publicId"], {
              motif: response.data["@id"],
              cancelRdv: now,
              CancelBy: "api/utilisateurs/"+this.$store.state.user.id.toString()
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json"
              },
            }).then(res => {

          let eleve = this.eleves.find(el => {
            return el.id == res.data.eleve.substring(18);
          })
          window.api.get("/solde/" + eleve.id, {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
            let resp = this.eleves.filter(ele => {
              return ele.id != eleve.id
            })
            eleve.solde = response.data.solde
            resp.push(eleve);
            this.eleves = resp;
          }).catch(error => {
            console.log(error)
          })
          let resa = this.calendarOptions.events.find(event => {
            if (event.id == res.data.id && event.type == "resa") {
              return true
            }
          })

          if (typemot.label == "Mono Absent") {
            resa.color = "grey";
            resa.motif = "Mono Absent";
          } else if (typemot.label == "Incident") {
            resa.color = "yellow";
            resa.motif = "Incident";
            resa.textColor = "black"
          }

          let resultResa = this.calendarOptions.events.filter(event => {
            if (event.id != res.data.id && event.type == "resa") {
              return true
            }
          })
          let resultDispo = this.calendarOptions.events.filter(event => {
            if (event.type == "dispo") {
              return true
            }
          })

          let resultIndispo = this.calendarOptions.events.filter(event => {
            if (event.type == "Indispo") {
              return true
            }
          })

          let result = []

          if (typemot.label != "Eleve Absent" && typemot.label != "Autre") {
            resultResa.push(resa)
          }

          resultDispo.forEach(resDispo => {
            result.push(resDispo)
          })

          resultResa.forEach(resResa => {
            result.push(resResa)
          })

          resultIndispo.forEach(resIndispo => {
            result.push(resIndispo)
          })


          this.calendarOptions.events = result
          document.getElementById("CancelResaAg").style.display = "none";
        }).catch(error => {
          console.log(error)
        })


      }).catch(error => {
        console.log(error)
      })
    },


    deleteEleveSelect() {
      this.itemSelect = '';
      this.calendarOptions.events = this.calendarOptions.events.filter(event => event.type != "dispo")
    },

    valideEleveResaAgence() {
      let idMono = this.choixDateResa.resource["_resource"]["id"]
      let select = document.querySelector(".LieuResaEleve");
      if (select.value != "choix") {

        let eleve = this.eleves.find(el => {
          return el.id == this.itemSelect.id;
        })
        let starth = moment(this.choixDateResa.startStr).format("HH")
        let endh = moment(this.choixDateResa.endStr).format("HH")

        let condMontant = this.conduiteMontant * (endh - starth);

        let solde = eleve.solde - condMontant;
        if (solde >= 0) {
          eleve.solde = solde

          let resp = this.eleves.filter(ele => {
            return ele.id != eleve.id
          })
          resp.push(eleve);
          this.eleves = resp;

          window.api.post("/apointments", {
                start: moment(this.choixDateResa.startStr).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(this.choixDateResa.endStr).format("YYYY-MM-DDTHH:mm:ss"),
                eleve: "api/utilisateurs/" + this.itemSelect.id,
                done: false,
                lieu: "api/lieus/" + select.value,
                moniteur: "api/moniteurs/" + idMono,
                priceConduite: condMontant
              },
              {
                headers: {
                  "Authorization": "Bearer " + this.$store.state.token
                },
              }).then(response => {

            let monitor = this.moniteurs.find(moniteur => {
              return moniteur.idMonos == idMono;
            })

            let lieu = monitor.lieux.find(lieu => {
              return lieu.id == select.value
            })

            this.calendarOptions.events.push({
              id: response.data.id,
              title: this.itemSelect.nom + " " + this.itemSelect.prenom + "  " + lieu.nom,
              start: moment(this.choixDateResa.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(this.choixDateResa.endStr).format("YYYY-MM-DDTHH:mm:ss"),
              color: "green",
              type: "resa",
              done: false,
              "editable": false,
              "motif": false,
              "resourceId": monitor.idMonos
            })
            document.getElementById("ModalResaAgenceEleve").style.display = "none";
          }).catch(error => {
            console.log(error)
          })
        } else {
          alert("Le solde de l'élève n'est pas suffisant")
        }


      } else {
        alert("Veuillez saisir un lieu")
      }
    },


    ValiderResa(e) {
      this.working.resa = true;
      let idMono = this.choixDateResa.resource["_resource"]["id"]
      if (e.target.id == "allEleve") {

        let select = document.querySelector(".allEleveSelect");
        if (select.value != "choix") {
          let choix = document.querySelector('input[name="allEleveResaRadio"]:checked').value;
          let eleve = this.eleves.find(el => {
            return el.id == choix;
          })
          let starth = moment(this.choixDateResa.startStr).format("HH")
          let endh = moment(this.choixDateResa.endStr).format("HH")

          let condMontant = this.conduiteMontant * (endh - starth);

          let solde = eleve.solde - condMontant;
          if (solde >= 0) {
            eleve.solde = eleve.solde - condMontant;
            let resp = this.eleves.filter(ele => {
              return ele.id != eleve.id
            })
            resp.push(eleve);
            this.eleves = resp;

            window.api.post("/apointments", {
                  start: moment(this.choixDateResa.startStr).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(this.choixDateResa.endStr).format("YYYY-MM-DDTHH:mm:ss"),
                  eleve: "api/utilisateurs/" + choix,
                  done: false,
                  lieu: "api/lieus/" + select.value,
                  moniteur: "api/moniteurs/" + idMono,
                  priceConduite: condMontant
                },
                {
                  headers: {
                    "Authorization": "Bearer " + this.$store.state.token
                  },
                }).then(response => {

              let monitor = this.moniteurs.find(moniteur => {
                return moniteur.idMonos == idMono;
              })

              let lieu = monitor.lieux.find(lieu => {
                return lieu.id == select.value
              })

              let eleve = this.eleves.find(uneleve => {
                return uneleve.id == choix
              })

              this.calendarOptions.events.push({
                id: response.data.id,
                title: eleve.nom + " " + eleve.prenom + "  " + lieu.nom,
                start: moment(this.choixDateResa.startStr).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(this.choixDateResa.endStr).format("YYYY-MM-DDTHH:mm:ss"),
                color: "green",
                type: "resa",
                done: false,
                "editable": false,
                "motif": false,
                "resourceId": monitor.idMonos
              })
              document.getElementById("ModalResaAgence").style.display = "none";
              this.working.resa = false;
            }).catch(error => {
              console.log(error)
            })
          } else {
            alert("Le solde de l'élève choisis n'est pas assez élevé")
          }


        } else {
          alert("Veuillez saisir un lieu")
        }
      }
    },

    deleteEvent(e) {
      if (!document.getElementById("cbx_mode").checked) {
        if (e.event["_def"].extendedProps.type == "Indispo") {
          window.api.delete("/indisponibilites/" + e.event["_def"].publicId,
              {
                headers: {
                  "Authorization": "Bearer " + this.$store.state.token
                },
              }).then(() => {
            let res = this.calendarOptions.events.filter(event => {
              return event.id != e.event["_def"].publicId;
            })
            this.calendarOptions.events = res;
          }).catch(error => {
            console.log(error)
          })
        }

      } else {
        if (e.event["_def"].extendedProps.type == "resa") {
          this.choixDateResa = e;
          this.getTypeMotif();
        }
      }
    },

    getTypeMotif() {
      window.api.get("/type_motifs", {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.typeMotif = response.data["hydra:member"]
        document.getElementById("CancelResaAg").style.display = "block";
      }).catch(error => {
        console.log(error)
      })
    },

    getIndispo() {
      this.moniteurs.forEach(moniteur => {
        moniteur.indispos.forEach(indispo => {
          this.calendarOptions.events.push({
            id: indispo.id,
            start: indispo.start.date,
            end: indispo.end.date,
            title: "INDISPO",
            color: "grey",
            type: "Indispo",
            "resourceId": moniteur.idMonos
          })
        })
      })
    },

    selectEvent(e) {
      this.allEleveResa = false;
      this.DispoEleveThisMono = false;
      this.DispoEleveNotThisMono = false;
      this.ElevePDispo = false;
      this.eleveDispoThisMono = [];
      this.eleveNotDispoThisMono = [];
      this.lieuMono = [];

      let cbxMode = document.getElementById("cbx_mode");

      if (!cbxMode.checked && !e.allDay) {

        window.api.post("/indisponibilites", {
              start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
              moniteur: "api/moniteurs/" + e["resource"]["_resource"]["id"],
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
          this.calendarOptions.events.push({
            id: response.data.id,
            title: "INDISPO",
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            color: "grey",
            type: "Indispo",
            "motif": false,
            "resourceId": e["resource"]["_resource"]["id"]
          })
        }).catch(error => {
          console.log(error)
        })

      } else if (cbxMode.checked && this.itemSelect == ""  && e.allDay == false) {
        this.moniteurs.forEach(mono => {
          if (e["resource"]["_resource"]["id"] == mono.idMonos) {
            mono.lieux.forEach(lieu => {
              this.lieuMono.push(lieu)
            })
          }
        })
        this.eleveDispoThisMono = [];

        this.eleves.forEach(eleve => {
          eleve.dispos.forEach(dispo => {

            let start = new Date(dispo.start.date);
            let end = new Date(dispo.end.date)
            if (start <= e.start && end >= e.end) {
              let keys = Object.keys(eleve.choixDispo);
              let find = false;
              keys.forEach(key => {

                if (key == e["resource"]["_resource"]["id"]) {
                  this.eleveDispoThisMono.push(eleve);
                  find = true;
                }

              })

              if (find == false) {
                this.eleveNotDispoThisMono.push(eleve);
              }

            }


          })
        })
        this.choixDateResa = e;
        document.getElementById("ModalResaAgence").style.display = "block";

      } else if (!cbxMode.checked && e.allDay == true) {
        this.eCpMa = e;
        this.display = true;
      } else if(e.allDay == false) {
        this.lieuMono = [];
        this.choixDateResa = e;

        this.moniteurs.forEach(mono => {
          if (e["resource"]["_resource"]["id"] == mono.idMonos) {
            mono.lieux.forEach(lieu => {
              this.lieuMono.push(lieu)
            })
          }
        })

        document.getElementById("ModalResaAgenceEleve").style.display = "block";
      }


    },

    changePlanningFunction(e) {

      if (e.target.checked) {
        window.api.patch("/agences/" + this.$store.state.user.agence.id, {
              autoPlanning: true
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json"
              },
            }).then(() => {
        }).catch(error => {
          console.log(error)
        })
      } else {
        window.api.patch("/agences/" + this.$store.state.user.agence.id, {
              autoPlanning: false
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json"
              },
            }).then(() => {
        }).catch(error => {
          console.log(error)
        })
      }
    },

    moniteurChoice() {
      let checkboxMoniteur = document.querySelectorAll(".monoCheck");
      let moniteurchoix = [];
      checkboxMoniteur.forEach(checkbox => {
        if (checkbox.checked) {
          let res = this.moniteurs.find(moniteur => {
            return moniteur.id == checkbox.value
          });
          moniteurchoix.push({title: res.prenom, id: res.idMonos});
        }
      })
      this.calendarOptions.resources = moniteurchoix;
    },

    btnModalMono() {
      let now = new Date;
      if(this.itemSelect == ''){
        document.getElementById("myModalMono").style.display = "block";
        this.nbHeureMono = '';
      }else{
        window.api.post("/rdv/hour/monitor", {
              start: now,
              id: this.itemSelect.id,
              ida: this.$store.state.user.agence.id,
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
          this.nbHeureMono = response.data.rdvs
          document.getElementById("myModalMono").style.display = "block";
        }).catch(error => {
          console.log(error)
        })
      }

    },

    closeModalMono(e) {
      if (e.target.id == "closeResa") {
        document.getElementById("ModalResaAgence").style.display = "none";
      } else if (e.target.id == "closeMono") {
        document.getElementById("myModalMono").style.display = "none";
      } else if (e.target.id == "closeResaEleve") {
        document.getElementById("ModalResaAgenceEleve").style.display = "none";
      } else if (e.target.id == "closeCancelResa") {
        document.getElementById("CancelResaAg").style.display = "none";
      }

    },

    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },

    getMoniteurs() {
      window.api.get("/moniteurs/agence/" + this.$store.state.user.agence.id, {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.moniteurs = response.data.Moniteur
        response.data.Moniteur.forEach(moniteur => {
          this.calendarOptions.resources.push({title: moniteur.prenom, id: moniteur.idMonos})

          moniteur.rdv.forEach(app => {
            if (app.motif.type == "Mono Absent") {
              this.calendarOptions.events.push({
                id: app.id,
                title: app.eleve + "  " + app.lieu + " Motif : " + app.motif.lemotif,
                start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "grey",
                type: "resa",
                done: false,
                "editable": false,
                "motif": "Mono Absent",
                "resourceId": moniteur.idMonos
              })
            } else if (app.motif.type == "Incident") {
              this.calendarOptions.events.push({
                id: app.id,
                title: app.eleve + "  " + app.lieu,
                start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "yellow",
                textColor: "black",
                type: "resa",
                done: false,
                "editable": false,
                "motif": "Incident",
                "resourceId": moniteur.idMonos
              })
            } else if (app.motif == "null") {
              if (app.done == true) {
                this.calendarOptions.events.push({
                  id: app.id,
                  title: app.eleve + "  " + app.lieu,
                  start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                  color: "mediumturquoise",
                  type: "resa",
                  done: false,
                  "editable": false,
                  "motif": false,
                  "resourceId": moniteur.idMonos
                })
              } else {
                this.calendarOptions.events.push({
                  id: app.id,
                  title: app.eleve + "  " + app.lieu,
                  start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                  color: "green",
                  type: "resa",
                  done: false,
                  "editable": false,
                  "motif": false,
                  "resourceId": moniteur.idMonos
                })
              }

            }
          })
        })
        this.getIndispo();

      }).catch(error => {
        console.log(error)
      })
    },

    getEleves() {

      window.api.get("/eleves/agence/" + this.$store.state.user.agence.id, {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.eleves = response.data.utilisateurs
      }).catch(error => {
        console.log(error)
      })
    },

    selectItem(item) {
      this.itemSelect = item;
      if (item.dispos.length != 0) {
        item.dispos.forEach(dispo => {
          this.calendarOptions.resources.forEach(() => {
            this.calendarOptions.events.push({
              id: dispo.id,
              start: dispo.start.date,
              end: dispo.end.date,
              title: item.fullname + " DISPO",
              color: "blue",
              type: "dispo",
              display: "background"
            })
          })
        })
      }
    },


  },
}
</script>

<style scoped>

div.calendar-head {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-around;
}

#typeahead_id_wrapper {
  width: min-content;
}

.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 100px;
  height: 30px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}

.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #FFFFFF;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked ~ .switch-label {
  background-color: #BDEB82;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.switch-input:checked ~ .switch-handle {
  left: 74px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
    ========================== */
.switch-label, .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */

  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s
}

/* Modal Content */
.modal-content {

  margin: auto;
  margin-top: 5em;
  background-color: #fefefe;
  width: 50%;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #93C2FF;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #FFFFFF;
  color: white;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>