<template>
  <div class="Conduite row">

    <div class="header-calendar row">
      <label class="col-4">
        <input type="radio" checked="checked" @click="checkRadioButton" name="radio-monos" class="radio-dispo">
        Poser mes disponibilités
      </label>
      <label class="col-6">
        <input type="radio" @click="checkRadioButton" name="radio-monos" class="radio-conduite">
        Conduire avec un moniteur
      </label>
      <router-link v-if="eleve != null" :to="{name:'solde', params: {id:this.eleve.id}}">
        <b-button variant="outline-primary">Solde {{ soldeEleve }} €</b-button>
      </router-link>
    </div>

    <div class="col-7">
      <FullCalendar :options="calendarOptions"/>
    </div>
    <div class="col-5 adress">


      <div class="head-adresse">
        <input type="text" name="adresse" v-model="adresse.rue" placeholder="Adresse..."/>
        <input type="text" name="ville" v-model="adresse.ville" placeholder="Ville.."/>
        <input type="text" name="dep" v-model="adresse.dep" placeholder="Département..."/>
        <button @click="postAdress">VAL</button>
      </div>

      <div class="content-adresse">

        <div class="monitor row" v-for="(moniteur, index) in moniteurs" :key="index">
          <label class="col-5">
            <input class="monoCheck checkbox-conduite" @click="choixEleve" type="checkbox" :value="moniteur.idMonos"
                   name="checkbox-monos">
            {{ moniteur.nom }} {{ moniteur.prenom }}
          </label>

          <div class="multiselec col-4">
            <Accordion>
              <AccordionTab header="Choisir un lieu">
                <div id="checkboxes">
                  <label v-for="(lieu, index) in moniteur.lieux" :key="index" :title="lieu.adresse + ', ' +lieu.ville">
                    <input type="checkbox" @click="choixEleve" class="checkbox-conduite lieuCheck"
                           :id="moniteur.idMonos" :value="lieu.id"/>{{ lieu.nom }}</label>
                </div>
              </AccordionTab>
            </Accordion>
          </div>

        </div>


      </div>

    </div>

    <div class="modals-conduite">
      <div id="modalResaEleve" class="modal">

        <!-- Modal content -->
        <div class="modal-content">
          <span @click="closeModalResaConduite" id="resa" class="close">&times;</span>
          <h4>Réserver un rendez-vous de <span class="start-resa"></span> à <span class="end-resa"></span></h4>
          <template v-if="choixEleves != null">
            <div class="content row">


              <p class="col-3">Choisissez un moniteur</p>
              <select class="col-3 select-mono-resa">
                <option value="choisissez">Choisissez un moniteur</option>

                <template v-for="(moniteur, i) in moniteurs" :key="i">

                  <template v-for="(monitor, index) in moniteurchoix" :key="index">
                    <option v-if="monitor == moniteur.idMonos" :value="moniteur.idMonos" @click="ResaChoixMoniteur">
                      {{ moniteur.nom }} {{ moniteur.prenom }}
                    </option>
                  </template>

                </template>

              </select>


              <p class="col-4">Choisir un lieu de rendez-vous</p>
              <select class="col-2 select-lieu-resa">
                <option value="choisissez">Choisissez un lieu</option>
              </select>
            </div>
          </template>


          <div>
            <button type="button" @click="postResa" class="btn btn-primary">Valider</button>
          </div>
        </div>

      </div>
      <div id="modalMapEleve" class="modal">

        <!-- Modal content -->
        <div class="modal-content">
          <span @click="closeModalResaConduite" id="map" class="close">&times;</span>
          <h4>Map</h4>

          <div></div>
        </div>

      </div>

    </div>


    <div id="mapContainer"></div>


  </div>
</template>

<script>

import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from 'axios'

export default {
  name: 'Conduite',
  components: {
    FullCalendar, // make the <FullCalendar> tag available

  },

  data() {
    return {
      expanded: false,
      adresse: {
        rue: "",
        ville: "",
        dep: "",
        id: 0,
      },
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        nowIndicator: true,
        eventResizableFromStart: true,
        firstDay: 1,
        allDaySlot: false,
        slotMinTime: "07:00:00",
        slotMaxTime: "21:00:00",
        height: "auto",
        locale: "fr",
        selectable: true,
        timeZone: "local",
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "timeGridWeek,timeGridDay",
        },
        buttonText: {
          today: "Aujourd'hui",
          prev: "<",
          next: ">"
        },
        events: [],
        select: this.select,
        eventClick: this.deleteEvent,
        editable: true,
        selectAllow: this.selectAllow,
        eventChange: this.putDispoEleve,
      },
      moniteurs: [],
      agence: [],
      choixEleves: null,
      eleve: null,
      moniteurchoix: [],
      motif: "",
      typeMotif: [],
      conduiteMontant: 0,
      soldeEleve: 0,
      interval: null,
    }
  },
  mounted() {
    //this.calendarOptions = this.event;
    this.getMoniteurs();
    this.getEvents();
    this.getUser();
    this.getAgence();
    this.checkAutoPlanning();
    this.getTypeMotif();
    this.getFonctionnalite();
  },
  unmounted() {
    if (this.interval != null) {
      clearInterval(this.interval)
    }
  },
  methods: {
    postAdress() {
      if (this.adresse.id == 0) {
        window.api.post("/adresse_conduites", {
              adresse: this.adresse.rue,
              ville: this.adresse.ville,
              codePostal: this.adresse.dep,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
          this.adresse.id = response.data.id;
          this.getMoniteurs();
        }).catch(error => {
          console.log(error)
        })
      } else {
        window.api.put("/adresse_conduites/" + this.adresse.id, {
              adresse: this.adresse.rue,
              ville: this.adresse.ville,
              codePostal: this.adresse.dep,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(() => {
          this.getMoniteurs();
        }).catch(error => {
          console.log(error)
        })
      }
    },
    getFonctionnalite() {
      window.api.get("/fonctionnalites/4", {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.conduiteMontant = response.data.montant;
      }).catch(error => {
        console.log(error)
      })
    },

    goToSolde() {
      this.$router.push({name: 'Solde'});
    },

    getMap() {
      // document.querySelector("#mapContainer").remove()
      // let mapdiv = document.createElement("div")
      // mapdiv.id = 'mapContainer';
      // document.querySelector(".Conduite").appendChild(mapdiv)
      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      });
      axios.get("https://api-adresse.data.gouv.fr/search/?q=" + this.adresse)
          .then(response => {
            var map = L.map('mapContainer').setView([response.data.features[0].geometry.coordinates[1], response.data.features[0].geometry.coordinates[0]], 16);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            var marker = L.marker([response.data.features[0].geometry.coordinates[1], response.data.features[0].geometry.coordinates[0]]).addTo(map);
            marker.bindPopup("<b>Votre Agence !</b><br>I am a popup.").openPopup();

            this.moniteurs.forEach(moniteur => {
              moniteur.lieux.forEach(lieu => {
                axios.get("https://api-adresse.data.gouv.fr/search/?q=" + lieu.adresse + " " + lieu.codePostal + " " + lieu.ville)
                    .then(res => {
                      L.marker([res.data.features[0].geometry.coordinates[1], res.data.features[0].geometry.coordinates[0]]).addTo(map).bindPopup("<b>" + moniteur.nom + " " + moniteur.prenom + "</b><br>" + lieu.nom + "");
                    })
                    .catch(er => {
                      console.log(er)
                    })
              })
            })

          }).catch(error => {
        console.log(error)
      })
    },

    getTypeMotif() {
      window.api.get("/type_motifs", {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.typeMotif = response.data["hydra:member"]
      }).catch(error => {
        console.log(error)
      })
    },

    openModalMap() {
      document.getElementById("modalMapEleve").style.display = "block";
    },

    postResa() {

      if (document.querySelector(".select-lieu-resa").value == "choisissez" || document.querySelector(".select-mono-resa").value == "choisissez") {
        alert("Veuillez saisir un mono et/ou un lieu")
      } else {
        let starth = moment(document.querySelector(".start-resa").id).format("HH")
        let endh = moment(document.querySelector(".end-resa").id).format("HH")
        let condMontant = this.conduiteMontant * (endh - starth);
        let solde = this.soldeEleve - condMontant;
        if (solde >= 0) {
          this.soldeEleve = this.soldeEleve - condMontant;
          window.api.post("/apointments", {
                start: moment(document.querySelector(".start-resa").id).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(document.querySelector(".end-resa").id).format("YYYY-MM-DDTHH:mm:ss"),
                eleve: "api/utilisateurs/" + this.$store.state.user.id,
                done: false,
                lieu: "api/lieus/" + document.querySelector(".select-lieu-resa").value,
                moniteur: "api/moniteurs/" + document.querySelector(".select-mono-resa").value,
                priceConduite: condMontant
              },
              {
                headers: {
                  "Authorization": "Bearer " + this.$store.state.token
                },
              }).then(response => {
            let monitor = this.moniteurs.find(moniteur => {
              return moniteur.idMonos == document.querySelector(".select-mono-resa").value;
            })

            let lieu = monitor.lieux.find(lieu => {
              return lieu.id == document.querySelector(".select-lieu-resa").value
            })
            this.calendarOptions.events.push({
              id: response.data.id,
              title: monitor.nom + " " + monitor.prenom + "  " + lieu.nom,
              start: moment(document.querySelector(".start-resa").id).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(document.querySelector(".end-resa").id).format("YYYY-MM-DDTHH:mm:ss"),
              color: "green",
              type: "resa",
              done: false,
              "editable": false,
              "motif": false
            })
            document.getElementById("modalResaEleve").style.display = "none"
          }).catch(error => {
            console.log(error)
          })
        } else {
          alert("Votre solde n'est pas suffisant")
        }


      }

    },

    ResaChoixMoniteur(e) {

      for (let i = document.querySelector(".select-lieu-resa").length; i >= 1; i--) {
        document.querySelector(".select-lieu-resa").remove(i);
      }

      let idMoniteur = e.target.value;
      let idLieux = [];
      let lieuxOfMonitor = [];

      for (const [key, value] of Object.entries(this.choixEleves.choix)) {
        if (key == idMoniteur) {
          idLieux.push(value)
        }
      }

      this.moniteurs.forEach(moniteur => {
        if (moniteur.idMonos == idMoniteur) {
          moniteur.lieux.forEach(lieu => {
            idLieux[0].forEach(lieuid => {
              if (lieu.id == lieuid) {
                let option = document.createElement("option")
                option.value = lieu.id;
                option.text = lieu.nom
                document.querySelector(".select-lieu-resa").add(option, null)
                lieuxOfMonitor.push(lieu);

              }
            })
          })
        }
      })
    },

    checkRadioButton(e) {
      if (e.target.className === "radio-conduite") {
        this.calendarOptions.events.forEach(event => {
          if (event.type === "dispo") {
            event.display = "background";
          }
        })
      } else {
        this.calendarOptions.events.forEach(event => {
          if (event.type === "dispo") {
            event.display = "";
          }
        })
      }

      if (e.target.className === "radio-dispo") {
        this.calendarOptions.events.forEach(event => {
          if (event.type === "resa") {
            event.display = "background";
          }
        })
      } else {
        this.calendarOptions.events.forEach(event => {
          if (event.type === "resa") {
            event.display = "";
          }
        })
      }


    },

    checkAutoPlanning() {
      let agence = this.$store.state.user.agence;
      let token = this.$store.state.token;
      if (agence != null) {
        if (this.$route.name == "eleve-conduite") {
          this.interval = setInterval(function () {
            window.api.get("/agences/" + agence.id, {
              headers: {
                "Authorization": "Bearer " + token
              },
            }).then(response => {
              if (!response.data.autoPlanning) {
                document.querySelector(".radio-conduite").disabled = true;
              } else {
                document.querySelector(".radio-conduite").disabled = false;
              }
            }).catch(error => {
              console.log(error)
            })
          }, 30000)
        }
      }
    },

    getUser() {

      window.api.get("/utilisateur/" + this.$store.state.user.id,
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        if (response.data.utilisateur[1]["adresse"] != "null") {
          this.adresse.rue = response.data.utilisateur[1]["adresse"][0]["adresse"]
          this.adresse.ville = response.data.utilisateur[1]["adresse"][0]["ville"]
          this.adresse.dep = response.data.utilisateur[1]["adresse"][0]["codePostal"]
          this.adresse.id = response.data.utilisateur[1]["adresse"][0]["id"]
        }

        this.eleve = response.data.utilisateur[0];
        window.api.get("/solde/" + this.eleve.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.soldeEleve = response.data.solde
        }).catch(error => {
          console.log(error)
        })
        if (response.data.utilisateur[1] != undefined) {
          this.choixEleves = response.data.utilisateur[1]

          this.moniteurchoix = Object.keys(this.choixEleves.choix);

          let moniteurcheckbox = document.querySelectorAll(".monoCheck");
          let lieuxcheckbox = document.querySelectorAll(".lieuCheck");

          for (const [key, value] of Object.entries(this.choixEleves.choix)) {
            moniteurcheckbox.forEach(moniteurcheck => {

              if (key == moniteurcheck.value) {
                moniteurcheck.checked = true;
              }
            })

            lieuxcheckbox.forEach(lieucheck => {
              if (key == lieucheck.id) {
                value.forEach(v => {
                  if (v == lieucheck.value) {
                    lieucheck.checked = true;
                  }
                })
              }
            })

          }

        }

        //this.choix = response.data.choix
      }).catch(error => {
        console.log(error)
      })

    },

    choixEleve() {
      let moniteurcheckbox = document.querySelectorAll(".monoCheck");
      let lieux = document.querySelectorAll(".lieuCheck");

      let moniteurId = [];

      moniteurcheckbox.forEach(moniteur => {
        if (moniteur.checked) {
          moniteurId.push(moniteur.value)
        }

      })

      let choix = {};

      moniteurId.forEach(id => {
        let lieuchoix = [];
        lieux.forEach(lieu => {
          if (lieu.checked) {
            if (id == lieu.id) {
              lieuchoix.push(lieu.value)
            }
          }
        })
        choix[id] = lieuchoix;
      })

      if (this.choixEleves == null) {
        window.api.post("/choix_dispo_eleves", {
              choix: choix,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
          this.choixEleves = response.data
          this.moniteurchoix = Object.keys(this.choixEleves.choix);
        }).catch(error => {
          console.log(error)
        })
      } else {
        window.api.put("/choix_dispo_eleves/" + this.choixEleves.id, {
              choix: choix,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
          this.choixEleves = response.data
          this.moniteurchoix = Object.keys(this.choixEleves.choix);
        }).catch(error => {
          console.log(error)
        })
      }


    },

    showOption(e) {
      if (!this.expanded) {
        e.target.parentNode.nextSibling.style.display = "block";
        this.expanded = true;
      } else {
        e.target.parentNode.nextSibling.style.display = "none";
        this.expanded = false;
      }
    },

    putDispoEleve(arg) {
      if (arg.event["_def"].title == "Dispo" && document.getElementsByClassName("radio-dispo")[0].checked == true) {

        window.api.put("/disponibilites/" + arg.event._def.publicId, {
          start: arg.event._instance.range.start,
          end: arg.event._instance.range.end,
          eleve: "api/utilisateurs/" + this.$store.state.user.id,

        }, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(() => {

        }).catch(error => {
          console.log(error)
        })
      }
    },


    deleteEvent(e) {
      if (e.event["_def"].title == "Dispo" && document.getElementsByClassName("radio-dispo")[0].checked == true) {
        this.deleteDispo(e);
      } else if (e["event"]["_def"]["extendedProps"]["motif"] == false) {

        let now = new Date
        now.setHours(now.getHours() + 1)
        window.api.patch("/apointments/" + e.event["_def"]["publicId"], {
              motif: "api/motifs/2",
              cancelRdv: now,
              CancelBy: "api/utilisateurs/"+this.$store.state.user.id.toString()
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json"
              },
            }).then(res => {
          window.api.get("/solde/" + this.eleve.id, {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
            this.soldeEleve = response.data.solde
          }).catch(error => {
            console.log(error)
          })
          let CancelDate = new Date(res.data.cancelRdv);
          let startDate = new Date(res.data.start);
          let hDiff = this.diff_hours(startDate, CancelDate)
          let resa = this.calendarOptions.events.find(event => {
            if (event.id == res.data.id && event.type == "resa") {
              return true
            }
          })
          resa.color = "red";
          resa.motif = true;
          let resultResa = this.calendarOptions.events.filter(event => {
            if (event.id != res.data.id && event.type == "resa") {
              return true
            }
          })
          let resultDispo = this.calendarOptions.events.filter(event => {
            if (event.type == "dispo") {
              return true
            }
          })
          let result = []

          if (hDiff < 48) { // si l'annulation est dans les 48h on affiche en rouge
            resultResa.push(resa)
          }

          resultDispo.forEach(resDispo => {
            result.push(resDispo)
          })

          resultResa.forEach(resResa => {
            result.push(resResa)
          })

          this.calendarOptions.events = result
        }).catch(error => {
          console.log(error)
        })
      } else if (e["event"]["_def"]["ui"]["backgroundColor"] == "red") {
        window.api.post("/rdv/exist", {

              start: e["event"]["_instance"]["range"]["start"],
              end: e["event"]["_instance"]["range"]["end"],
              id: e["event"]["_def"]["publicId"],
            },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
          if (response.data.cani == true) {
            window.api.patch("/apointments/" + e.event["_def"]["publicId"], {
                  motif: null,
                  cancelRdv: null,
                  CancelBy: "api/utilisateurs/"+this.$store.state.user.id.toString()
                },
                {
                  headers: {
                    "Authorization": "Bearer " + this.$store.state.token,
                    "Content-Type": "application/merge-patch+json"
                  },
                }).then(() => {
              let laResa = this.calendarOptions.events.filter(event => {
                if (event.id == e.event["_def"]["publicId"] && event.type == "resa") {
                  return true;
                } else {
                  return false;
                }
              })

              let resas = this.calendarOptions.events.filter(event => {
                if (event.id != e.event["_def"]["publicId"] && event.type == "resa") {
                  return true;
                } else {
                  return false;
                }
              })

              let dispos = this.calendarOptions.events.filter(event => {
                if (event.type == "dispo") {
                  return true;
                } else {
                  return false;
                }
              })

              laResa[0].color = "green";
              laResa[0].motif = false;

              resas.push(laResa[0]);
              let events = [];

              resas.forEach(resa => {
                events.push(resa);
              })
              dispos.forEach(dispo => {
                events.push(dispo);
              })

              this.calendarOptions.events = events;
            }).catch(error => {
              console.log(error)
            })

          } else {
            alert("Vous ne pouvez plus réserver ce créneau")
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    deleteDispo(e) {
      window.api.delete("/disponibilites/" + e.event["_def"].publicId,
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(() => {
        let res = this.calendarOptions.events.filter(event => {
          return event.id != e.event["_def"].publicId;
        })
        this.calendarOptions.events = res;
      }).catch(error => {
        console.log(error)
      })
    },
    getEvents() {
      this.getDispos();
      this.getApointments();
    },

    closeModalResaConduite(e) {
      if (e.target.id == "resa") {
        document.getElementById("modalResaEleve").style.display = "none"
      } else if (e.target.id == "map") {
        document.getElementById("modalMapEleve").style.display = "none";
      }

    },

    select(arg) {
      if (document.getElementsByClassName("radio-dispo")[0].checked) {
        this.postDispos(arg);
      } else {
        this.openModalResa(arg);
      }
    },
    openModalResa(arg) {
      document.querySelector(".start-resa").id = arg.startStr;
      document.querySelector(".start-resa").innerHTML = arg.start.toLocaleString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
      document.querySelector(".end-resa").id = arg.endStr;
      document.querySelector(".end-resa").innerHTML = arg.end.toLocaleString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
      document.getElementById("modalResaEleve").style.display = "block";

      for (let i = document.querySelector(".select-lieu-resa").length; i >= 1; i--) {
        document.querySelector(".select-lieu-resa").remove(i);
      }
    },
    postDispos(arg) {
      window.api.post("/disponibilites", {

            start: moment(arg.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(arg.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            eleve: "api/utilisateurs/" + this.$store.state.user.id,
          },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        this.calendarOptions.events.push({
          id: response.data.id,
          title: "Dispo",
          start: moment(arg.startStr).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(arg.endStr).format("YYYY-MM-DDTHH:mm:ss"),
          color: "blue",
          type: "dispo"
        })
      }).catch(error => {
        console.log(error)
      })


    },
    getDispos() {
      window.api.get("/eleves/" + this.$store.state.user.id + "/dispos",
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        let dispos = response.data.disponibilites;

        for (let i = 0; i < dispos.length; i++) {
          this.calendarOptions.events.push({
            id: dispos[i].id,
            start: dispos[i].start.date,
            end: dispos[i].end.date,
            title: "Dispo",
            color: "blue",
            type: "dispo"
          })
        }

      }).catch(error => {
        console.log(error)
      })
    },
    diff_hours(dt2, dt1) {

      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= (60 * 60);
      return Math.abs(Math.round(diff));

    },
    getApointments() {
      window.api.get("/eleves/" + this.$store.state.user.id + "/apointments",
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        let apointment = response.data.apointments;

        for (let i = 0; i < apointment.length; i++) {
          if (apointment[i].motif == "null") {
            if (apointment[i].done == true) {
              this.calendarOptions.events.push({
                id: apointment[i].id,
                start: apointment[i].start.date,
                end: apointment[i].end.date,
                title: apointment[i].moniteur.fullname + " " + apointment[i].lieu.nom,
                color: "mediumturquoise",
                display: "background",
                type: "resa",
                done: apointment[i].done,
                "editable": false,
                "motif": false
              })
            } else {
              this.calendarOptions.events.push({
                id: apointment[i].id,
                start: apointment[i].start.date,
                end: apointment[i].end.date,
                title: apointment[i].moniteur.fullname + " " + apointment[i].lieu.nom,
                color: "green",
                display: "background",
                type: "resa",
                done: apointment[i].done,
                "editable": false,
                "motif": false
              })
            }

          } else {
            if (apointment[i].typeMotif == "Eleve Absent") {
              let hDiff = this.diff_hours(new Date(apointment[i].start.date), new Date(apointment[i].cancelRdv.date))
              if (hDiff < 48) {
                this.calendarOptions.events.push({
                  id: apointment[i].id,
                  start: apointment[i].start.date,
                  end: apointment[i].end.date,
                  title: apointment[i].moniteur.fullname + " " + apointment[i].lieu.nom,
                  color: "red",
                  display: "background",
                  type: "resa",
                  done: apointment[i].done,
                  "editable": false,
                  "motif": true
                })
              }
            } else if (apointment[i].typeMotif == "Incident") {
              this.calendarOptions.events.push({
                id: apointment[i].id,
                start: apointment[i].start.date,
                end: apointment[i].end.date,
                title: apointment[i].moniteur.fullname + " " + apointment[i].lieu.nom,
                color: "yellow",
                textColor: "black",
                display: "background",
                type: "resa",
                done: apointment[i].done,
                "editable": false,
                "motif": true
              })
            }


          }

        }

      }).catch(error => {
        console.log(error)
      })
    },


    getAgence() {
      if (this.$store.state.user.agence != null) {
        window.api.get("/agences/" + this.$store.state.user.agence.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.agence = response.data;
          if (this.adresse.rue == "") {
            this.adresse.rue = response.data.adresse;
            this.adresse.dep = response.data.codePostal;
            this.adresse.ville = response.data.ville;
          }

          this.getMap()

          if (!this.agence.autoPlanning) {
            document.querySelector(".radio-conduite").disabled = true;
          } else {
            document.querySelector(".radio-conduite").disabled = false;
          }

        }).catch(error => {
          console.log(error)
        })
      }

    },

    getMoniteurs() {
      if (this.$store.state.user.agence != null) {
        window.api.get("/moniteurs/agence/" + this.$store.state.user.agence.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.moniteurs = response.data.Moniteur
        }).catch(error => {
          console.log(error)
        })
      } else {
        window.api.get("/allmoniteurs/noagence/" + this.$store.state.user.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.moniteurs = response.data.Moniteur
        }).catch(error => {
          console.log(error)
        })
      }

    },

    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },
  },
}
</script>

<style scoped>

div.Conduite {
  margin-top: 2em;
}

.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}


div.header-calendar {
  margin-bottom: 2em;
}

div.adress {
  border: 1px solid black;
}

div.head-adresse {
  border-bottom: 1px solid black;
  height: 10%;
  width: 100%;
}

div.head-adresse input {
  border-bottom: 1px solid black;
  margin-left: 10%;
  margin-top: 5%;
  width: 20%;
}

div.head-adresse button {
  margin-left: 3%;
}

div.content-adresse .monitor {
  border-bottom: 1px solid black;

}


/* The Modal (background) */
div.modals-conduite .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
div.modals-conduite .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
}

/* The Close Button */
div.modals-conduite .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

div.modals-conduite .close:hover,
div.modals-conduite .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

span.start-resa {
  color: red;
}

span.end-resa {
  color: red;
}

#mapContainer {
  height: 500px;
  width: 70%;
}
</style>