<template>
    <br />
    <h1>Choix du planning</h1>

    <template v-for="agence in agences" :key="agence.id">
    
        <router-link :to="{name:'admin-planning', params: {choix:agence.id}}"><button>{{agence.nom}}</button></router-link>

    </template>

    <router-link :to="{name:'admin-planning', params: {choix:'admin'}}"><button>Admin</button></router-link>

</template>

<script>
export default {
  name: "Choix Planning",

  data(){
      return {
          agences: []
      }
  },

  mounted() {
      this.getAgences();
  },

  methods: {
      getAgences(){
        window.api.get("/agences/", {
            headers: {
            "Authorization": "Bearer " + this.$store.state.token
            },
        }).then(response => {
            this.agences = response.data["hydra:member"];
        }).catch(error => {
            console.log(error)
        })
      }
  },

  computed: {},
};
</script>
