<template>
  <div id="conteneur">
    <template v-if="spin >= 7">
      <div id="reservation" class="container">
        <div class="header-page" @click="goToHome">
          <img :src="require('../../../assets/images/logoMonPermis.png')" />

          <!--</router-link>-->
          <p class="mt-4" id="namesize">
            {{ this.$store.state.user.prenom }}
            {{ this.$store.state.user.nom }}
          </p>
        </div>

        <div class="header-calendar row mt-4">
          <p class="sizeTitrechoix mt-3">
            1) Je choisis un mode de réservation :
          </p>
          <label class="col-4">
            <input type="radio" checked="checked" @click="checkRadioButton" name="radio-monos"
              class="radio-dispo mt-3" />
            Je pose mes dispos
          </label>
          <label class="col-4 mb-4" v-if="checkCours == true">
            <input type="radio" @click="checkRadioButton" name="radio-monos" class="radio-conduite mt-3" />
            Je réserve un cours
          </label>
          <label class="col-4 mb-4">
            <input type="radio" @click="checkRadioButton" name="radio-monos" class="radio-sim mt-3" />
            Je réserve un simulateur
          </label>
        </div>

        <div id="adresse" class="col-12">
          <p class="sizeTitrechoix">2) Je choisis une adresse :</p>
          <div class="inputadress">
            <input class="form-control mt-1 rue" type="text" name="adresse" v-model="adresse.rue"
              placeholder="Adresse..." />
            <input class="form-control mt-1 dp" type="text" name="dep" v-model="adresse.dep"
              placeholder="Département..." />
            <input class="form-control mt-1 ville" type="text" name="ville" v-model="adresse.ville"
              placeholder="Ville.." />
          </div>
          <b-button @click="postAdressAgence" class="btnBlue mt-3" v-if="agence != null">Agence</b-button>
          <b-button @click="postAdress" class="btnBlue mt-3">Valider</b-button>
        </div>

        <div class="content-adresse">
          <template v-if="showMono == false">
            Veuillez choisir l'option "je réserve un cours" pour voir les
            moniteurs
          </template>
          <template v-else>
            <template v-if="showAdresse == false">
              valider une adresse pour afficher les moniteurs dispo
            </template>
            <template v-else>
              <template v-if="haveMono == false">
                Désolé nous ne sommes pas encore implanté dans votre département
              </template>
              <template v-else>
                <p class="sizeTitrechoix mb-5">3) Je choisis un moniteur :</p>

                <i class="pi pi-info-circle me-5 testi" id="iconHome" style="font-size: 1rem"
                  title="Nb d'heures dispo communes entre vous et chacun des mono - poser un maximum de dispo et privilégiez le mono qui a le plus de dispo commune avec vous"></i>

                <div class="monitor row" v-for="(moniteur, index) in moniteurs" :key="index">
                  <label class="col-5">
                    <input class="monoCheck checkbox-conduite mb-4" @click="choixEleve" type="checkbox"
                      :value="moniteur.idMonos" name="checkbox-monos" :data-id="moniteur.idMonos" />
                    {{ moniteur.nom }}
                    {{ moniteur.prenom }}
                  </label>

                  <template v-for="(com, index) in commonHour" :key="index">
                    <template v-if="com.id == moniteur.idMonos">{{ com.nbh / 60 }} H</template>
                  </template>

                  <div class="multiselec col-4">
                    <Accordion>
                      <AccordionTab header="Lieux de RDV ">
                        <template v-for="(lieu, index) in moniteur.lieux" :key="index">
                          <template v-if="lieu.visibleEleve">
                            <label id="lieus" :title="lieu.adresse + ', ' + lieu.ville">
                              <input type="checkbox" @click="choixEleve" class="checkbox-conduite lieuCheck"
                                :id="moniteur.idMonos" :value="lieu.id" :data-id="moniteur.idMonos" />{{ lieu.nom
                                }}</label>
                          </template>
                        </template>
                      </AccordionTab>
                    </Accordion>
                  </div>
                </div>
              </template>
            </template>
          </template>

          <b-button @click="ordreVueMobile" id="btnSuivantVueMobile" class="btnBlue mt-5 mb-4">Suivant</b-button>
        </div>
      </div>

      <div id="calendrier">
        <div id="fullCalendar" class="container col-11">
          <router-link v-if="eleve != null" :to="{ name: 'solde', params: { id: this.eleve.id } }">
            <b-button class="btnBlue mt-5 mb-4">Solde {{ soldeEleve }} €</b-button>
          </router-link>
          <b-button class="btnBlue mt-5 mb-4" @click="displaypermis = true">Nb Heure par semaine autorisé {{
              limiteParSemaine
          }}H</b-button>
          <b-button class="btnBlue mt-5 mb-4" @click="displaypermis = true">Permis en accéléré {{ permisAcc }} €
          </b-button>
          <FullCalendar :options="calendarOptions" />
          <div id="btnCalendar">
            <b-button @click="ordreVueMobile" id="btnRetourVueMobile" class="btnBlue mt-5">Retour</b-button>
          </div>
        </div>
      </div>

      <div class="modals-conduite">
        <div id="modalResaEleve" class="modal">
          <div class="modal-content">
            <div class="modal-header">
              <span @click="closeModalResaConduite" id="resa" class="close">&times;</span>
              <h2>Réservation</h2>
            </div>
            <div class="modal-body">
              <template v-if="showMono == true">
                <h4>
                  Réserver un rendez-vous de <span class="start-resa"></span> à
                  <span class="end-resa"></span>
                </h4>
                <template v-if="choixEleves != null">
                  <div class="content row">
                    <p class="col-3">Choisissez un moniteur</p>
                    <select class="col-3 select-mono-resa" @click="ResaChoixMoniteur">
                      <option value="choisissez">Choisissez un moniteur</option>
                      <template v-for="(moniteur, i) in moniteurs" :key="i">
                        <template v-for="(monitor, index) in moniteurchoix" :key="index">
                          <option v-if="monitor == moniteur.idMonos" :value="moniteur.idMonos">
                            {{ moniteur.nom }} {{ moniteur.prenom }}
                          </option>
                        </template>
                      </template>
                    </select>
                    <p class="col-4">Choisir un lieu de rendez-vous</p>
                    <select class="col-2 select-lieu-resa">
                      <option value="choisissez">Choisissez un lieu</option>
                      <template v-for="(lieu, index) in choixMonoLieu" :key="index">
                        <option :value="lieu.id">{{ lieu.nom }}</option>
                      </template>
                    </select>
                  </div>
                </template>
              </template>
              <template v-else>
                <h4>
                  Réserver le simulateur de <span class="start-resa"></span> à
                  <span class="end-resa"></span>
                </h4>
              </template>

              <div>
                <template v-if="isWorking.resa == false">
                  <button type="button" @click="postResa" class="btn btn-primary">
                    Valider
                  </button>
                </template>
                <template v-else>
                  <button type="button" @click="postResa" class="btn btn-primary" disabled>
                    <span><i class="fa fa-spinner fa-spin"></i> Valider </span>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>

        <Dialog header="Permis en accéléré" v-model:visible="displaypermis" modal="true">
          Pour {{ this.permisAcc }} €, vous pourrez passer en accéléré, ce qui
          vous permettra de réserver autant d'heures que vous le souhaitez !
          <br />
          <template v-if="choixPaiementAcc == false">
            <b-button class="btnBlue mt-5 mb-4" @click="choixPaiementAcc = true">Je souhaite passer en Accéléré
            </b-button>
            <b-button class="btnBlue mt-6 mb-4" @click="displaypermis = false">Non merci</b-button>
          </template>
          <template v-else>
            <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
          </template>
        </Dialog>

        <div id="modalMapEleve" class="modal">
          <!-- Modal content -->
          <div class="modal-content">
            <span @click="closeModalResaConduite" id="map" class="close">&times;</span>
            <h4>Map</h4>
            <div></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Loader />
    </template>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import Loader from "@/components/Loader.vue";
import BoutonPaiement from "@/components/eleve/BoutonPaiement.vue";

export default {
  name: "Conduite",
  components: {
    Loader,
    FullCalendar, // make the <FullCalendar> tag available
    BoutonPaiement,
  },

  data() {
    return {
      spin: 0,
      expanded: false,
      adresse: {
        rue: "",
        ville: "",
        dep: "",
        id: 0,
      },

      isWorking: {
        resa: false,
      },

      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        nowIndicator: true,
        eventResizableFromStart: true,
        firstDay: 1,
        allDaySlot: false,
        slotMinTime: "07:00:00",
        slotMaxTime: "21:00:00",
        height: "auto",
        locale: "fr",
        selectable: true,
        timeZone: "local",
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "timeGridWeek,timeGridDay",
        },
        buttonText: {
          today: "Aujourd'hui",
          prev: "<",
          next: ">",
        },
        events: [],
        select: this.select,
        eventClick: this.deleteEvent,
        editable: true,
        selectAllow: this.selectAllow,
        eventChange: this.putDispoEleve,
        eventDidMount: this.eventhover,
      },
      moniteurs: [],
      agence: null,
      choixEleves: null,
      eleve: null,
      moniteurchoix: [],
      motif: "",
      typeMotif: [],
      conduiteMontant: 0,
      simuMontant: 0,
      soldeEleve: 0,
      interval: null,
      selectedLieu: null,
      choixMonoLieu: [],
      limiteParSemaine: 0,
      permisAcc: 0,
      commonHour: [],
      findA: false,
      showAdresse: false,
      haveMono: false,
      showMono: false,
      lesimu: [],
      displaypermis: false,
      checkCours: "",
      choixPaiementAcc: false,
      aac: false,
      formation: false,
      takeRdvp: false,
      countRdv: 0,
    };
  },
  mounted() {
    //this.calendarOptions = this.event;

    this.getEvents();
    this.getUser();
    this.getAgence();
    this.checkAutoPlanning();
    this.getTypeMotif();
    this.getFonctionnalite();
    this.checkAac();
  },
  unmounted() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  },
  methods: {

    checkAac() {
      window.api
        .get("checkAac/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {

          this.aac = response.data["utilisateur"];
          this.formation = response.data["formation"];
          this.takeRdvp = response.data["takeRdvp"];
          this.countRdv = response.data["countRdv"];
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkIfCours() {
      window.api
        .get("cantakerdv/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          if (this.checkCours == true && response.data.utilisateur == false) {
            let radio = document.querySelector(".radio-conduite");
            radio.checked = false;
          }
          this.checkCours = response.data.utilisateur;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goToPaiement(mode) {
      this.$store.dispatch("startSaveBouton", mode);
      let fonctionnalite = "Permis en accéléré";
      let router = this.$router;
      this.$store.dispatch("endSaveBouton", { mode, fonctionnalite, router });
    },

    goToHome() {
      this.$router.push({ name: "Home" });
    },
    eventhover(info) {
      info.el.title = info.event.title;
    },

    postAdressAgence() {
      this.adresse.rue = this.agence.adresse;
      this.adresse.ville = this.agence.ville;
      this.adresse.dep = this.agence.codePostal;
      this.postAdress();
    },

    postAdress() {
      if (this.adresse.id == 0) {
        window.api
          .post(
            "/adresse_conduites",
            {
              adresse: this.adresse.rue,
              ville: this.adresse.ville,
              codePostal: this.adresse.dep,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.adresse.id = response.data.id;
            //this.getMoniteurs();
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .put(
            "/adresse_conduites/" + this.adresse.id,
            {
              adresse: this.adresse.rue,
              ville: this.adresse.ville,
              codePostal: this.adresse.dep,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            //this.getMoniteurs();
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getFonctionnalite() {
      window.api
        .get("/fonctionnalites/", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          response.data["hydra:member"].forEach((fonc) => {
            if (fonc.label == "conduite") {
              this.conduiteMontant = fonc.montant;
            } else if (fonc.label == "Limite par semaine") {
              this.limiteParSemaine = fonc.montant;
            } else if (fonc.label == "Permis en accéléré") {
              this.permisAcc = fonc.montant;
            } else if (fonc.label == "simulateur") {
              this.simuMontant = fonc.montant;
            }
            this.spin++;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goToSolde() {
      this.$router.push({ name: "Solde" });
    },

    getMap() {
      // document.querySelector("#mapContainer").remove()
      // let mapdiv = document.createElement("div")
      // mapdiv.id = 'mapContainer';
      // document.querySelector(".Conduite").appendChild(mapdiv)
      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      axios
        .get("https://api-adresse.data.gouv.fr/search/?q=" + this.adresse)
        .then((response) => {
          var map = L.map("mapContainer").setView(
            [
              response.data.features[0].geometry.coordinates[1],
              response.data.features[0].geometry.coordinates[0],
            ],
            16
          );
          L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          }).addTo(map);

          var marker = L.marker([
            response.data.features[0].geometry.coordinates[1],
            response.data.features[0].geometry.coordinates[0],
          ]).addTo(map);
          marker
            .bindPopup("<b>Votre Agence !</b><br>I am a popup.")
            .openPopup();

          this.moniteurs.forEach((moniteur) => {
            moniteur.lieux.forEach((lieu) => {
              axios
                .get(
                  "https://api-adresse.data.gouv.fr/search/?q=" +
                  lieu.adresse +
                  " " +
                  lieu.codePostal +
                  " " +
                  lieu.ville
                )
                .then((res) => {
                  L.marker([
                    res.data.features[0].geometry.coordinates[1],
                    res.data.features[0].geometry.coordinates[0],
                  ])
                    .addTo(map)
                    .bindPopup(
                      "<b>" +
                      moniteur.nom +
                      " " +
                      moniteur.prenom +
                      "</b><br>" +
                      lieu.nom +
                      ""
                    );
                })
                .catch((er) => {
                  console.log(er);
                });
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTypeMotif() {
      window.api
        .get("/type_motifs", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.typeMotif = response.data["hydra:member"];
          this.spin++;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openModalMap() {
      document.getElementById("modalMapEleve").style.display = "block";
    },

    postResa() {
      this.isWorking.resa = true;
      if (this.showMono == true) {
        if (
          document.querySelector(".select-lieu-resa").value == "choisissez" ||
          document.querySelector(".select-mono-resa").value == "choisissez"
        ) {
          alert("Veuillez saisir un mono et/ou un lieu");
        } else {
          let starth = moment(document.querySelector(".start-resa").id).format(
            "HH"
          );
          let endh = moment(document.querySelector(".end-resa").id).format(
            "HH"
          );
          let startm = moment(document.querySelector(".start-resa").id).format(
            "mm"
          );
          let endm = moment(document.querySelector(".end-resa").id).format(
            "mm"
          );

          let condMontant = 0;
          if (endm == 30 && startm == 0) {
            let div2 = this.conduiteMontant / 2;
            let heures = endh - starth;
            condMontant = this.conduiteMontant * heures + div2;
          } else if (endm == 0 && startm == 30) {
            let div2 = this.conduiteMontant / 2;
            let heures = endh - starth;
            condMontant = this.conduiteMontant * (heures - 1) + div2;
          } else {
            condMontant = this.conduiteMontant * (endh - starth);
          }
          condMontant = this.conduiteMontant * (endh - starth);
          let solde = this.soldeEleve - condMontant;
          if (solde >= 0) {
            this.soldeEleve = this.soldeEleve - condMontant;

            window.api
              .post(
                "/check/limit/hour/" + this.$store.state.user.id,
                {
                  date: moment(document.querySelector(".start-resa").id).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                  start: moment(
                    document.querySelector(".start-resa").id
                  ).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(document.querySelector(".end-resa").id).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                if (response.data.limit) {
                  window.api
                    .post(
                      "/rdv/check",
                      {
                        start: moment(
                          document.querySelector(".start-resa").id
                        ).format("YYYY-MM-DDTHH:mm:ss"),
                        end: moment(
                          document.querySelector(".end-resa").id
                        ).format("YYYY-MM-DDTHH:mm:ss"),
                        idMono:
                          document.querySelector(".select-mono-resa").value,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then((response) => {
                      if (
                        (response.data.cani &&
                          this.checkCours != "null" &&
                          this.formation == false) ||
                        (this.formation == true &&
                          response.data.cani &&
                          this.checkCours != "null" &&
                          this.takeRdvp == true)
                      ) {
                        let rdvp = false;
                        let rdvpname = null;
                        if (this.formation == true) {
                          rdvp = true;
                          if (this.countRdv == 0) {
                            rdvpname = "RDVP"
                          } else if (this.countRdv == 1) {
                            rdvpname = "RDVP1"
                          } else if (this.countRdv == 2) {
                            rdvpname = "RDVP2"
                          }
                        }
                        window.api
                          .post(
                            "/apointments",
                            {
                              start: moment(
                                document.querySelector(".start-resa").id
                              ).format("YYYY-MM-DDTHH:mm:ss"),
                              end: moment(
                                document.querySelector(".end-resa").id
                              ).format("YYYY-MM-DDTHH:mm:ss"),
                              eleve:
                                "api/utilisateurs/" + this.$store.state.user.id,
                              done: false,
                              lieu:
                                "api/lieus/" +
                                document.querySelector(".select-lieu-resa")
                                  .value,
                              moniteur:
                                "api/moniteurs/" +
                                document.querySelector(".select-mono-resa")
                                  .value,
                              priceConduite: condMontant,
                              rdvp: rdvp,
                              rdvpName: rdvpname
                            },
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + this.$store.state.token,
                              },
                            }
                          )
                          .then((response) => {
                            this.checkAac();
                            let monitor = this.moniteurs.find((moniteur) => {
                              return (
                                moniteur.idMonos ==
                                document.querySelector(".select-mono-resa")
                                  .value
                              );
                            });
                            this.checkIfCours();
                            window.api
                              .post(
                                "/sendMail",
                                {
                                  type: "rdv ok",
                                  id: response.data.id,
                                  email: this.$store.state.user.email,
                                },
                                {
                                  headers: {
                                    Authorization:
                                      "Bearer " + this.$store.state.token,
                                  },
                                }
                              )
                              .then(() => { })
                              .catch((error) => {
                                console.log(error);
                              });

                            let lieu = monitor.lieux.find((lieu) => {
                              return (
                                lieu.id ==
                                document.querySelector(".select-lieu-resa")
                                  .value
                              );
                            });
                            this.calendarOptions.events.push({
                              id: response.data.id,
                              title:
                                lieu.nom +
                                " " +
                                monitor.nom +
                                " " +
                                monitor.prenom,
                              start: moment(
                                document.querySelector(".start-resa").id
                              ).format("YYYY-MM-DDTHH:mm:ss"),
                              end: moment(
                                document.querySelector(".end-resa").id
                              ).format("YYYY-MM-DDTHH:mm:ss"),
                              color: "green",
                              type: "resa",
                              done: false,
                              editable: false,
                              motif: false,
                            });
                            document.querySelector(".select-mono-resa").value =
                              "Choisissez un moniteur";
                            document.getElementById(
                              "modalResaEleve"
                            ).style.display = "none";
                            this.isWorking.resa = false;
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        if (this.checkCours == "null") {
                          alert(
                            "Veuillez saisir votre dossier et signer le contrat votre contrat dans la partie dossier pour pouvoir prendre rendez-vous merci !"
                          );
                        } else if (this.takeRdvp == false) {
                          alert(
                            "Vous avez déjà pris tous vos rendez-vous pédagogiques"
                          );
                        } else {
                          alert(
                            "Vous ne pouvez pas prendre de rendez-vous avec ce moniteur pour cette date"
                          );
                        }

                        this.isWorking.resa = false;
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  alert(
                    "Vous avez atteint votre limite d'heures cette semaine"
                  );
                  this.isWorking.resa = false;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert("Votre solde n'est pas suffisant");
            this.isWorking.resa = false;
          }
        }
      } else {
        let leLieu = "";
        this.lesimu.lieux.forEach((lieu) => {
          if (lieu.nom == this.agence.nom) {
            leLieu = lieu;
          }
        });
        let starth = moment(document.querySelector(".start-resa").id).format(
          "HH"
        );
        let endh = moment(document.querySelector(".end-resa").id).format("HH");
        let startm = moment(document.querySelector(".start-resa").id).format(
          "mm"
        );
        let endm = moment(document.querySelector(".end-resa").id).format("mm");

        let condMontant = 0;
        if (endm == 30 && startm == 0) {
          let div2 = this.simuMontant / 2;
          let heures = endh - starth;
          condMontant = this.simuMontant * heures + div2;
        } else if (endm == 0 && startm == 30) {
          let div2 = this.simuMontant / 2;
          let heures = endh - starth;
          condMontant = this.simuMontant * (heures - 1) + div2;
        } else {
          condMontant = this.simuMontant * (endh - starth);
        }
        condMontant = this.simuMontant * (endh - starth);
        let solde = this.soldeEleve - condMontant;
        if (solde >= 0) {
          this.soldeEleve = this.soldeEleve - condMontant;

          window.api
            .post(
              "/rdv/check",
              {
                start: moment(document.querySelector(".start-resa").id).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                end: moment(document.querySelector(".end-resa").id).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                idMono: this.lesimu.idMonos,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              if (response.data.cani && this.checkCours != "null") {
                let rdvp = false;
                let rdvpname = null;
                if (this.formation == true) {
                  rdvp = true;
                  if (this.countRdv == 0) {
                    rdvpname = "RDVP"
                  } else if (this.countRdv == 1) {
                    rdvpname = "RDVP1"
                  } else if (this.countRdv == 2) {
                    rdvpname = "RDVP2"
                  }
                }
                window.api
                  .post(
                    "/apointments",
                    {
                      start: moment(
                        document.querySelector(".start-resa").id
                      ).format("YYYY-MM-DDTHH:mm:ss"),
                      end: moment(
                        document.querySelector(".end-resa").id
                      ).format("YYYY-MM-DDTHH:mm:ss"),
                      eleve: "api/utilisateurs/" + this.$store.state.user.id,
                      done: false,
                      lieu: "api/lieus/" + leLieu.id,
                      moniteur: "api/moniteurs/" + this.lesimu.idMonos,
                      priceConduite: condMontant,
                      rdvp: rdvp,
                      rdvpName: rdvpname
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + this.$store.state.token,
                      },
                    }
                  )
                  .then((response) => {
                    this.checkAac();
                    let monitor = this.lesimu;
                    this.checkIfCours();
                    window.api
                      .post(
                        "/sendMail",
                        {
                          type: "rdv ok",
                          id: response.data.id,
                          email: this.$store.state.user.email,
                        },
                        {
                          headers: {
                            Authorization: "Bearer " + this.$store.state.token,
                          },
                        }
                      )
                      .then(() => { })
                      .catch((error) => {
                        console.log(error);
                      });

                    let lieu = leLieu;
                    this.calendarOptions.events.push({
                      id: response.data.id,
                      title:
                        lieu.nom + " " + monitor.nom + " " + monitor.prenom,
                      start: moment(
                        document.querySelector(".start-resa").id
                      ).format("YYYY-MM-DDTHH:mm:ss"),
                      end: moment(
                        document.querySelector(".end-resa").id
                      ).format("YYYY-MM-DDTHH:mm:ss"),
                      color: "green",
                      type: "resa",
                      done: false,
                      editable: false,
                      motif: false,
                    });
                    document.getElementById("modalResaEleve").style.display =
                      "none";
                    this.isWorking.resa = false;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                if (this.checkCours == "null") {
                  alert(
                    "Veuillez saisir votre dossier et signer le contrat votre contrat dans la partie dossier pour pouvoir prendre rendez-vous merci !"
                  );
                } else if (!this.takeRdvp) {
                  alert("Vous avez déjà pris tous vos rendez-vous pédagogiques")
                } else {
                  alert(
                    "Vous ne pouvez pas prendre de rendez-vous avec ce moniteur pour cette date"
                  );
                }

                this.isWorking.resa = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("Votre solde n'est pas suffisant");
          this.isWorking.resa = false;
        }
      }
    },

    ResaChoixMoniteur(e) {
      for (
        let i = document.querySelector(".select-lieu-resa").length;
        i >= 1;
        i--
      ) {
        document.querySelector(".select-lieu-resa").remove(i);
      }

      let idMoniteur = e.target.value;
      let idLieux = [];
      // let lieuxOfMonitor = [];

      for (const [key, value] of Object.entries(this.choixEleves.choix)) {
        if (key == idMoniteur) {
          idLieux.push(value);
        }
      }

      this.moniteurs.forEach((moniteur) => {
        if (moniteur.idMonos == idMoniteur) {
          moniteur.lieux.forEach((lieu) => {
            idLieux[0].forEach((lieuid) => {
              if (lieu.id == lieuid) {
                this.choixMonoLieu.push(lieu);
              }
            });
          });
        }
      });
    },

    checkRadioButton(e) {
      if (
        e.target.className === "radio-sim mt-3" ||
        e.target.className === "radio-conduite mt-3"
      ) {
        if (e.target.className === "radio-conduite mt-3") {
          this.showMono = true;
          setTimeout(() => {
            this.moniteurchoix = Object.keys(this.choixEleves.choix);

            let moniteurcheckbox = document.querySelectorAll(".monoCheck");
            let lieuxcheckbox = document.querySelectorAll(".lieuCheck");

            for (const [key, value] of Object.entries(this.choixEleves.choix)) {
              moniteurcheckbox.forEach((moniteurcheck) => {
                if (key == moniteurcheck.value) {
                  moniteurcheck.checked = true;
                }
              });

              lieuxcheckbox.forEach((lieucheck) => {
                if (key == lieucheck.id) {
                  value.forEach((v) => {
                    if (v == lieucheck.value) {
                      lieucheck.checked = true;
                    }
                  });
                }
              });
            }
          }, 100);
        } else {
          this.showMono = false;
        }

        this.calendarOptions.events.forEach((event) => {
          if (event.type === "dispo") {
            event.display = "background";
          }
        });
      } else {
        this.calendarOptions.events.forEach((event) => {
          if (event.type === "dispo") {
            event.display = "";
          }
        });
      }

      if (e.target.className === "radio-dispo mt-3") {
        this.showMono = false;
        this.calendarOptions.events.forEach((event) => {
          if (event.type === "resa") {
            event.display = "background";
          }
        });
      } else {
        this.calendarOptions.events.forEach((event) => {
          if (event.type === "resa") {
            event.display = "";
          }
        });
      }
    },

    checkAutoPlanning() {
      let agence = this.$store.state.user.agence;
      let token = this.$store.state.token;

      if (agence.id != null) {
        if (this.$route.name == "Calendrier") {
          this.interval = setInterval(function () {
            window.api
              .get("/agences/" + agence.id, {
                headers: {
                  Authorization: "Bearer " + token,
                },
              })
              .then((response) => {
                if (!response.data.autoPlanning) {
                  document.querySelector(".radio-conduite").disabled = true;
                } else {
                  document.querySelector(".radio-conduite").disabled = false;
                }
                this.spin++;
              })
              .catch((error) => {
                console.log(error);
              });
          }, 30000);
        }
      } else {
        this.spin++;
      }
    },

    getUser() {
      window.api
        .get("/utilisateur/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log("user take rdv ou pas", response.data.utilisateur);
          this.checkCours = response.data.utilisateur[2]["canTakeRdv"];
          this.aac = response.data.utilisateur[3]["aac"];
          this.formation = response.data.utilisateur[4]["formation"];
          this.takeRdvp = response.data.utilisateur[5]["cantakerdvp"];
          this.countRdv = response.data.utilisateur[6]["countRdv"];
          if (response.data.utilisateur[1] != undefined) {
            if (response.data.utilisateur[1]["adresse"] != "null") {
              this.showAdresse = true;
              this.getMoniteurs();
              this.adresse.rue =
                response.data.utilisateur[1]["adresse"][0]["adresse"];
              this.adresse.ville =
                response.data.utilisateur[1]["adresse"][0]["ville"];
              this.adresse.dep =
                response.data.utilisateur[1]["adresse"][0]["codePostal"];
              this.adresse.id =
                response.data.utilisateur[1]["adresse"][0]["id"];
            }
          }

          this.eleve = response.data.utilisateur[0];
          window.api
            .get("/common/hours/" + this.eleve.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.commonHour = response.data.TotHeureMono;
            })
            .catch((error) => {
              console.log(error);
            });

          window.api
            .get("/solde/" + this.eleve.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.soldeEleve = response.data.solde;
              this.spin++;
            })
            .catch((error) => {
              console.log(error);
            });
          if (response.data.utilisateur[1] != undefined) {
            this.choixEleves = response.data.utilisateur[1];
          }

          //this.choix = response.data.choix
        })
        .catch((error) => {
          console.log(error);
        });
    },

    choixEleve(e) {
      let moniteurcheckbox = document.querySelectorAll(".monoCheck");
      let lieux = document.querySelectorAll(".lieuCheck");
      if (e.target.classList[0] == "monoCheck") {
        lieux.forEach((lieu) => {
          if (lieu.attributes[3].value === e.target.attributes[3].value) {
            if (e.target.checked) {
              lieu.checked = true;
            } else {
              lieu.checked = false;
            }
          }
        });
      }

      if (e.target.classList[0] == "checkbox-conduite") {
        let find = false;

        lieux.forEach((lieu) => {
          if (lieu.attributes[3].value === e.target.attributes[3].value) {
            if (lieu.checked === true) {
              find = true;
            }
          }
        });

        if (!find) {
          moniteurcheckbox.forEach((mono) => {
            if (mono.attributes[3].value === e.target.attributes[3].value) {
              mono.checked = false;
            }
          });
        }
      }

      let moniteurId = [];

      moniteurcheckbox.forEach((moniteur) => {
        if (moniteur.checked) {
          moniteurId.push(moniteur.value);
        }
      });

      let choix = {};

      moniteurId.forEach((id) => {
        let lieuchoix = [];
        lieux.forEach((lieu) => {
          if (lieu.checked) {
            if (id == lieu.id) {
              lieuchoix.push(lieu.value);
            }
          }
        });
        choix[id] = lieuchoix;
      });

      if (this.choixEleves == null) {
        window.api
          .post(
            "/choix_dispo_eleves",
            {
              choix: choix,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.choixEleves = response.data;
            this.moniteurchoix = Object.keys(this.choixEleves.choix);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .put(
            "/choix_dispo_eleves/" + this.choixEleves.id,
            {
              choix: choix,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.choixEleves = response.data;
            this.moniteurchoix = Object.keys(this.choixEleves.choix);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    showOption(e) {
      if (!this.expanded) {
        e.target.parentNode.nextSibling.style.display = "block";
        this.expanded = true;
      } else {
        e.target.parentNode.nextSibling.style.display = "none";
        this.expanded = false;
      }
    },

    putDispoEleve(arg) {
      if (
        arg.event["_def"].title == "Dispo" &&
        document.getElementsByClassName("radio-dispo")[0].checked == true
      ) {
        window.api
          .put(
            "/disponibilites/" + arg.event._def.publicId,
            {
              start: arg.event._instance.range.start,
              end: arg.event._instance.range.end,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => { })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    deleteEvent(e) {
      if (
        e.event["_def"].title == "Dispo" &&
        document.getElementsByClassName("radio-dispo")[0].checked == true
      ) {
        this.deleteDispo(e);
      } else if (
        e["event"]["_def"]["extendedProps"]["motif"] == false &&
        e["event"]["_def"]["extendedProps"]["done"] == false
      ) {
        let nowe = e["event"]["_context"]["dateProfileGenerator"]["nowDate"];
        let startdatee = e["event"]["_instance"]["range"]["start"];
        let hDiffe = this.diff_hours(startdatee, nowe);
        let confirmCancel = false;

        if (hDiffe < 48) {
          confirmCancel = confirm(
            " " +
            startdatee.toLocaleString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }) +
            "  : Souhaitez-vous annuler votre cours de conduite ? L'annulation intervient moins de 48h avant le début du cours et sera donc facturé"
          );
        } else {
          confirmCancel = confirm(
            "  " +
            startdatee.toLocaleString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }) +
            " : Annulation du cours : Souhaitez-vous annuler votre cours de conduite ?"
          );
        }

        if (confirmCancel) {
          let now = new Date();
          now.setHours(now.getHours() + 1);
          window.api
            .patch(
              "/apointments/" + e.event["_def"]["publicId"],
              {
                motif: "api/motifs/2",
                cancelRdv: now,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                  "Content-Type": "application/merge-patch+json",
                },
              }
            )
            .then((res) => {
              window.api
                .get("/solde/" + this.eleve.id, {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                })
                .then((response) => {
                  this.soldeEleve = response.data.solde;
                })
                .catch((error) => {
                  console.log(error);
                });
              let CancelDate = new Date(res.data.cancelRdv);
              let startDate = new Date(res.data.start);
              let hDiff = this.diff_hours(startDate, CancelDate);
              let resa = this.calendarOptions.events.find((event) => {
                if (event.id == res.data.id && event.type == "resa") {
                  return true;
                }
              });
              resa.color = "red";
              resa.motif = true;
              let resultResa = this.calendarOptions.events.filter((event) => {
                if (event.id != res.data.id && event.type == "resa") {
                  return true;
                }
              });
              let resultDispo = this.calendarOptions.events.filter((event) => {
                if (event.type == "dispo") {
                  return true;
                }
              });
              let result = [];

              if (hDiff < 48) {
                // si l'annulation est dans les 48h on affiche en rouge
                resultResa.push(resa);
              }

              resultDispo.forEach((resDispo) => {
                result.push(resDispo);
              });

              resultResa.forEach((resResa) => {
                result.push(resResa);
              });
              this.checkIfCours();
              window.api
                .post(
                  "/sendMail",
                  {
                    type: "rdv annnule eleve",
                    id: res.data.id,
                    email: this.$store.state.user.email,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => { })
                .catch((error) => {
                  console.log(error);
                });

              this.calendarOptions.events = result;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (e["event"]["_def"]["ui"]["backgroundColor"] == "red") {
        window.api
          .post(
            "/rdv/exist",
            {
              start: e["event"]["_instance"]["range"]["start"],
              end: e["event"]["_instance"]["range"]["end"],
              id: e["event"]["_def"]["publicId"],
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            if (response.data.cani == true) {
              window.api
                .patch(
                  "/apointments/" + e.event["_def"]["publicId"],
                  {
                    motif: null,
                    cancelRdv: null,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                      "Content-Type": "application/merge-patch+json",
                    },
                  }
                )
                .then((res) => {
                  this.getApointments();

                  window.api
                    .post(
                      "/sendMail",
                      {
                        type: "rdv annnule eleve",
                        id: res.data.id,
                        email: this.$store.state.user.email,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then(() => { })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              alert("Vous ne pouvez plus réserver ce créneau");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteDispo(e) {
      window.api
        .delete("/disponibilites/" + e.event["_def"].publicId, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
          let res = this.calendarOptions.events.filter((event) => {
            return event.id != e.event["_def"].publicId;
          });
          this.calendarOptions.events = res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEvents() {
      this.getDispos();
      this.getApointments();
    },

    closeModalResaConduite(e) {
      if (e.target.id == "resa") {
        document.getElementById("modalResaEleve").style.display = "none";
      } else if (e.target.id == "map") {
        document.getElementById("modalMapEleve").style.display = "none";
      }
    },

    select(arg) {
      if (document.getElementsByClassName("radio-dispo")[0].checked) {
        this.postDispos(arg);
      } else {
        this.openModalResa(arg);
      }
    },
    openModalResa(arg) {
      document.querySelector(".start-resa").id = arg.startStr;
      document.querySelector(".start-resa").innerHTML =
        arg.start.toLocaleString("fr-FR", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
      document.querySelector(".end-resa").id = arg.endStr;
      document.querySelector(".end-resa").innerHTML = arg.end.toLocaleString(
        "fr-FR",
        {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }
      );
      document.getElementById("modalResaEleve").style.display = "block";
      if (this.showMono) {
        for (
          let i = document.querySelector(".select-lieu-resa").length;
          i >= 1;
          i--
        ) {
          document.querySelector(".select-lieu-resa").remove(i);
        }
      }
    },
    postDispos(arg) {
      window.api
        .post(
          "/disponibilites",
          {
            start: moment(arg.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(arg.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            eleve: "api/utilisateurs/" + this.$store.state.user.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.calendarOptions.events.push({
            id: response.data.id,
            title: "Dispo",
            start: moment(arg.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(arg.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            color: "blue",
            type: "dispo",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDispos() {
      window.api
        .get("/eleves/" + this.$store.state.user.id + "/dispos", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          let dispos = response.data.disponibilites;

          for (let i = 0; i < dispos.length; i++) {
            this.calendarOptions.events.push({
              id: dispos[i].id,
              start: dispos[i].start.date,
              end: dispos[i].end.date,
              title: "Dispo",
              color: "blue",
              type: "dispo",
            });
          }
          this.spin++;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    diff_hours(dt2, dt1) {
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;
      return Math.abs(Math.round(diff));
    },
    getApointments() {
      window.api
        .get("/eleves/" + this.$store.state.user.id + "/apointments", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          let apointment = response.data.apointments;

          for (let i = 0; i < apointment.length; i++) {
            if (apointment[i].motif == "null") {
              if (apointment[i].done == true) {
                this.calendarOptions.events.push({
                  id: apointment[i].id,
                  start: apointment[i].start.date,
                  end: apointment[i].end.date,
                  title:
                    apointment[i].lieu.nom +
                    " " +
                    apointment[i].moniteur.fullname,
                  color: "mediumturquoise",
                  display: "background",
                  type: "resa",
                  done: apointment[i].done,
                  editable: false,
                  motif: false,
                });
              } else {
                this.calendarOptions.events.push({
                  id: apointment[i].id,
                  start: apointment[i].start.date,
                  end: apointment[i].end.date,
                  title:
                    apointment[i].lieu.nom +
                    " " +
                    apointment[i].moniteur.fullname,
                  color: "green",
                  display: "background",
                  type: "resa",
                  done: apointment[i].done,
                  editable: false,
                  motif: false,
                });
              }
            } else {
              if (apointment[i].typeMotif == "Eleve Absent") {
                let hDiff = this.diff_hours(
                  new Date(apointment[i].start.date),
                  new Date(apointment[i].cancelRdv.date)
                );
                if (hDiff < 48) {
                  this.calendarOptions.events.push({
                    id: apointment[i].id,
                    start: apointment[i].start.date,
                    end: apointment[i].end.date,
                    title:
                      apointment[i].lieu.nom +
                      " " +
                      apointment[i].moniteur.fullname,
                    color: "red",
                    display: "background",
                    type: "resa",
                    done: apointment[i].done,
                    editable: false,
                    motif: true,
                  });
                }
              } else if (apointment[i].typeMotif == "Incident") {
                this.calendarOptions.events.push({
                  id: apointment[i].id,
                  start: apointment[i].start.date,
                  end: apointment[i].end.date,
                  title:
                    apointment[i].lieu.nom +
                    " " +
                    apointment[i].moniteur.fullname,
                  color: "yellow",
                  textColor: "black",
                  display: "background",
                  type: "resa",
                  done: apointment[i].done,
                  editable: false,
                  motif: true,
                });
              }
            }
          }
          this.spin++;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAgence() {
      if (this.$store.state.user.agence != null) {
        window.api
          .get("/agences/" + this.$store.state.user.agence.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.agence = response.data;

            this.findA = true;
            if (this.adresse.rue == "") {
              this.adresse.rue = response.data.adresse;
              this.adresse.dep = response.data.codePostal;
              this.adresse.ville = response.data.ville;
            }

            if (!this.agence.autoPlanning) {
              document.querySelector(".radio-conduite").disabled = true;
            } else {
              document.querySelector(".radio-conduite").disabled = false;
            }
            this.spin++;
          })
          .catch((error) => {
            console.log(error);
          });

        window.api
          .get("/moniteur/7", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            console.log("le simu : ", response.data);
            this.lesimu = response.data.moniteur[0];
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.spin++;
      }
    },

    getMoniteurs() {
      if (this.$store.state.user.agence != null) {
        window.api
          .get(
            "/moniteurs/agence/" +
            this.$store.state.user.agence.id +
            "/eleve/" +
            this.$store.state.user.id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.moniteurs = response.data.Moniteur;
            if (this.moniteurs.length === 0) {
              this.haveMono = false;
            } else {
              this.haveMono = true;
            }
            this.showAdresse = true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .get("/allmoniteurs/eleve/" + this.$store.state.user.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.moniteurs = response.data.Moniteur;
            if (this.moniteurs.length === 0) {
              this.haveMono = false;
            } else {
              this.haveMono = true;
            }
            this.showAdresse = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },

    ordreVueMobile(e) {
      if (e.target.id == "btnSuivantVueMobile") {
        document.getElementById("reservation").style.display = "none";
        document.getElementById("calendrier").style.display = "block";
      } else if (e.target.id == "btnRetourVueMobile") {
        document.getElementById("calendrier").style.display = "none";
        document.getElementById("reservation").style.display = "initial";
      }
    },
  },
};
</script>

<style scoped>
/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */

  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  -webkit-animation-name: fadeIn;
  /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* Modal Content */
.modal-content {
  margin: auto;
  margin-top: 5em;
  background-color: #fefefe;
  width: 80%;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #93c2ff;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #ffffff;
  color: white;
}

div.header-page {
  margin-top: 1em;
  display: flex;
  cursor: pointer;
}

div.header-page img {
  width: 12%;
  height: 12%;
  margin-top: 1em;
}

div.header-page i {
  margin-top: 40%;
}

div.inputadress {
  display: flex;
}

div.Conduite {
  margin-top: 2em;
}

.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

div.header-calendar {
  margin-bottom: 2em;
  border-bottom: 1px solid #e4e4e4;
}

div.adress {
  border: 1px solid black;
}

div.head-adresse {
  border-bottom: 1px solid black;
  height: 10%;
  width: 100%;
}

div.head-adresse input {
  border-bottom: 1px solid black;
  margin-left: 10%;
  margin-top: 5%;
  width: 80%;
}

div.content-adresse .monitor {
  margin-top: 2%;
}

span.start-resa {
  color: red;
}

span.end-resa {
  color: red;
}

#mapContainer {
  height: 500px;
  width: 70%;
}

#namesize {
  font-size: 1.3em;
}

#reservation p {
  display: flex;
  align-content: flex-start;
  font: normal normal 600 20px/27px Galano Grotesque Alt;
  font-size: 20pt;
}

#reservation {
  min-height: 100vh;
  min-width: 100%;
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
}

#calendrier {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  background-color: #f2f2f2;
  background-size: cover;
}

#conteneur {
  display: grid;
  grid-template-columns: 40% 60%;
}

#fullCalendar {
  margin: auto;
}

div#adresse {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 2em;
}

div#adresse input.dp {
  width: 30%;
}

div#adresse input.dp {
  width: 30%;
}

div#adresse input.rue {
  width: 100%;
}

div#adresse input.ville {
  width: 60%;
}

#adresseInput {
  width: 50%;
  margin: auto;
}

.sizeTitrechoix {
  font-size: 1.3em;
}

.btnBlue {
  background: #07989f 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 10px #00000029;
  border: 1px solid #e4e4e4;
  border-radius: 13px;
  text-decoration: none;
}

input[type="checkbox"] {
  width: 1.15em;
  height: 1.15em;
}

#btnCalendar {
  display: flex;
  justify-content: space-evenly;
}

label {
  font-size: 0.85rem !important;
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  #btnSuivantVueMobile {
    display: none;
  }

  #lieus {
    font-size: 0.8rem !important;
  }

  #btnRetourVueMobile {
    display: none;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  #lieus {
    font-size: 0.8rem !important;
  }

  #calendrier {
    display: none;
    background: none;
  }

  #btnRetourVueMobile {
    display: initial;
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  #fullCalendar {
    margin: auto;
    width: 200%;
  }

  #conteneur {
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
  }

  #reservation {
    background: none;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #lieus {
    font-size: 0.75rem !important;
  }

  #calendrier {
    display: none;
    background: none;
  }

  #btnRetourVueMobile {
    display: initial;
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  #fullCalendar {
    margin: auto;
    width: 200%;
  }

  #reservation {
    background: none;
  }

  #conteneur {
    grid-template-columns: none;
    justify-content: flex-start;
    align-items: center;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #lieus {
    font-size: 0.8rem !important;
  }

  #calendrier {
    display: none;
    background: none;
  }

  #btnRetourVueMobile {
    display: initial;
  }

  #reservation {
    background: none;
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  #conteneur {
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
  }

  /* Styles */
}
</style>

<style>
/* Mobile Styles */
@media only screen and (max-width: 400px) {
  .fc .fc-toolbar-title {
    display: none !important;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  .fc .fc-toolbar-title {
    display: none !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .fc .fc-toolbar-title {
    display: none !important;
  }

  /* Styles */
}
</style>
