<template>
  <div id="conteneurEleve">
    <Toast />
    <div id="eleve" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreEleve">Eleve</h1>
      </header>
      <div id="tableauEleve">
        <div id="archive">
          <!--
          <div class="flex justify-self-flex-end">
            <span class="p-input-icon-left">
              <input
                class="ms-5"
                v-model="filters['global'].value"
                placeholder="Rechercher"
              />
            </span>
          </div>
          -->
          <div class="flex justify-self-flex-end">
            <SimpleTypeahead
                id="typeahead_id"
                placeholder="Rechercher un élève..."
                :items="elevesName"
                :minInputLength="1"
                :itemProjection="
                        (item) => {
                          return item.fullname;
                        }
                      "
                @selectItem="selectItem"
            >
            </SimpleTypeahead>
            
          </div>
          <button class="btn btn-danger" @click="cancelSearch" v-if="cancelS == true">X</button>

          <select class="selectTab" name="modes" id="modes" v-model="dossier.step" @change="changeModeForm()">
                <option value="">Tous</option>
                <option value="1/5">1/5</option>
                <option value="2/5">2/5</option>
                <option value="3/5">3/5</option>
                <option value="4/5">4/5</option>
                <option value="5/5">5/5</option>
          </select>

          <select class="selectTab" name="modes" id="modes" v-model="dossier.statut" @change="changeMode()">
                <option value="">Tous</option>
                <option value="Non Payé">Non Payé</option>
                <option value="Incomplet">Incomplet</option>
                <option value="Validées">Validées</option>
                <option value="À traiter">À traiter</option>
                <option value="Saisi">Saisi</option>
                <option value="Enregistrer">Enregistrer</option>
          </select>


          <select class="selectTab" name="modes" id="modes" v-model="dossier.code" @change="changeModeCode()">
                <option value="">Tous</option>
                <option value="Non Payé">Non Payé</option>
                <option value="Payé">Payé</option>
                <option value="Obtenu">Obtenu</option>
          </select>




          <router-link to="/ListeArchive"
            ><Button id="archiveBtn">Archive</Button></router-link
          >
        </div>
        <DataTable
          :value="this.Elevesagence"
          :rows="7"
          v-model:filters="filters"
          v-model:selection="selectedItem"
          selectionMode="single"
          filterDisplay="menu"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'date',
            'nom',
            'prenom',
            'Dossier.social',
            'Dossier.lycee',
            'contrat.tab',
          ]"
          dataKey="id"
          @click="onSelected"
        >
          <Column
            field="dateInsc"
            header="date"
            :sortable="true"
            [sortOrder]="-1"
            style="min-width: 5rem"
          >
          </Column>

          <Column
            field="nom"
            header="Nom"
            :sortable="true"
            style="min-width: 5rem"
          >
          </Column>

          <Column
            field="prenom"
            header="Prenom"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="stepForm"
            header="Form."
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="statutDossier"
            header="Dossier"
            :sortable="true"
            style="min-width: 6rem"
          >
          </Column>
          <Column
            field="code"
            header="Code"
            :sortable="true"
            style="min-width: 6rem"
          >
          </Column>
          <Column :exportable="false" style="min-width: 6rem">
            <template #body="slotProps">
              <i
                class="pi pi-times me-3"
                id="iconHome"
                @click="deleteUtilisateur(slotProps.data.id)"
                style="font-size: 1.7rem"
              ></i>
            </template>
          </Column>
          <Column header="Archiver" :exportable="false" style="min-width: 6rem">
            <template #body="slotProps">
              <Checkbox
                :binary="true"
                @click="archiveEleve(slotProps.data.id)"
              />
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="10" :totalRecords="elevesName.length" @page="onPaginate"></Paginator>
      </div>
    </div>

    <div id="right">
      <div v-if="this.selectedItem === null" id="styleTitreChoix">
        <p id="titreChoix">Cliquez sur un Eleve</p>
      </div>

      <template v-if="this.selectedItem != null">
        <template v-if="this.showPaiement != true">
          <div id="containerInfoEleve" class="container-fluid">
            <div id="infoEleve" style="display: flex; flex-direction: row">
              <div>
                <p style="text-align: left">
                  <strong>
                    {{ this.selectedItem.nom }} {{ this.selectedItem.prenom }} -
                    "Statut Dossier"</strong
                  >
                </p>
                <p style="text-align: left">
                  <strong>
                    {{ this.selectedItem.telephone }} -
                    {{ this.selectedItem.email }}</strong
                  >
                </p>
                <p style="text-align: left">
                  <strong> {{ this.selectedItem.Dossier.adresse }} </strong>
                  <strong>
                    {{ this.selectedItem.Dossier.cp }}
                    {{ this.selectedItem.Dossier.ville }}</strong
                  >
                </p>
                <p style="text-align: left">
                  <strong>Forfait : {{ this.selectedItem.Dossier.forfait }} </strong>
                  <br>
                  <strong>
                    Boite Véhicule : {{ this.selectedItem.boite }}
                    <br>
                    Prévoit AAC : <template v-if="this.selectedItem.Dossier.prevoitAac">OUI</template> <template v-else>NON</template></strong
                  >
                </p>
              </div>

              <div>
                <template v-if="this.selectedItem.newContract == 'null'">
                  <button class="btn btn-danger">Contrat non crée</button>
                </template>
                <template v-if="this.selectedItem.newContract == 'wait'">
                  <button @click="showContrats()" class="btn btn-warning">Contrat en attente</button>
                </template>
                <template v-if="this.selectedItem.newContract == 'valide'">
                  <button class="btn btn-success">Contrat validé</button>
                </template>
                
                <br>
                <br>
                <button @click="selectFormModal()" class="btn btn-primary">Formulaire</button>
                <br>
                <br>
                <button @click="solde" class="btn btn-info">Solde</button>
              </div>
            </div>

            <div>
              <Dropdown
                v-model="this.selectedItem.agence"
                :options="this.agences"
                @change="patchEleve()"
                optionLabel="nom"
                id="agence"
                name="agence"
                :placeholder="this.selectedItem.agence.nom"
              />
            </div>

            <div style="margin-top: 15%">
              <!-- <div id="exaCode">
                <p>Examen Code</p>
                <p>
                  {{ this.nbrEchecExaCode }} {{ this.libelleResultatExamCode }}
                </p>
                <p>
                  {{ this.dateReussiteExaCode }}
                </p>
                <template v-if="this.selectedItem.Exam.length > 0">
                  <Accordion
                    v-if="
                      this.selectedItem.Exam[this.selectedItem.Exam.length - 1]
                        .code === 'Echec'
                    "
                  >
                    <AccordionTab header="Prochaine Date">
                      {{
                        this.selectedItem.Exam[this.selectedItem.Exam.length - 1]
                          .date
                      }}
                    </AccordionTab>
                  </Accordion>
                </template>
              </div> -->

              <!-- <div class="mt-4" id="exaConduite">
                <p>Examen de Conduite</p>
                <p>
                  {{ this.nbrEchecExaConduite }}
                  {{ this.libelleResultatExamConduite }}
                </p>
                <p>
                  {{ this.dateReussiteExaConduite }}
                </p>
                <template v-if="this.selectedItem.DemandeExam.length > 0">
                  <Accordion
                    v-if="
                      this.selectedItem.Exam[
                        this.selectedItem.DemandeExam.length - 1
                      ].code === 'Echec'
                    "
                  >
                    <AccordionTab header="Prochaine Date">
                      {{
                        this.selectedItem.DemandeExam[
                          this.selectedItem.DemandeExam.length - 1
                        ].DateExamen
                      }}
                    </AccordionTab>
                  </Accordion>
                </template>
              </div>-->
              <button class="col-md-3 mt-4 col-sm-12 btn btn-primary" @click="getDroitCpf()">Saisir un PAIEMENT CPF</button>
              <br>
              <template v-if="showForm">

                <select class="col-md-3 mt-4 col-sm-12" name="choice" id="choice" v-model="postPaiement.choix">
                  <option value="" disabled>Droit CPF</option>
                  <template v-for="droit in droitCpf" :key="droit.id">
                    <option :value="droit.id">{{ droit.payeur }}</option>
                  </template>
                </select>

                <template class="row">
                  <div class="col-md-4 mt-4 col-sm-12">
                    <input type="date" name="date" id="date" v-model="postPaiement.date">
                  </div>

                  <div class="col-md-4 mt-4 col-sm-12">
                    <input type="number" name="price" id="price" v-model="postPaiement.price">
                  </div>

                  <div class="col-md-4 mt-4 col-sm-12">
                    <select name="mode" id="mode" v-model="postPaiement.mode" @click="changeSelect()">
                      <option value="" disabled>Mode de paiement</option>
                      <option value="CHQ">CHQ</option>
                      <option value="VIR">VIR</option>
                    </select>
                  </div>
                </template>

                <button @click="postCpf" class="mt-4">ok</button>


              </template>

            </div>
          </div>
        </template>
      </template>
      <template v-if="showPaiement == true"><paiement :data="data" /></template>
    </div>
  </div>

  <Dialog v-model:visible="showMeContract" modal header="Contrat" :style="{ width: '50vw' }">
    <p class="resendMail">Vous n'avez pas reçu le mail ? <button @click="resendMail">Renvoyer le mail</button></p>
    <input type="number" name="code" id="Code" placeholder="Saisir le code du mail" v-model="code"> <button @click="signContract">Signer le contrat</button>
  </Dialog>

  <template v-if="formModalSelected !== null">
    <FormulaireModal
      :formModalSelected="formModalSelected"
      :dossierFull="dossierFull"
      @selectFormModal="selectFormModal($event)"
    ></FormulaireModal>
  </template>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import paiement from "@/components/eleve/Paiement.vue";
import FormulaireModal from "@/components/dossier/FormulaireModal.vue";
import DropdownNav from "@/components/DropdownNav.vue";
import SimpleTypeahead from 'vue3-simple-typeahead'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

export default {
  name: "App",
  components: {
    paiement,
    FormulaireModal,
    DropdownNav,
    SimpleTypeahead,
  },
  data() {
    return {
      Elevesagence : [],
      changeTab : [],
      elevesEnregistrer: [],
      elevesAll : [],
      secComment: "",
      showRefusText: false,
      showContrat: false,

      utilisateurs: {
        id: null,
      },

      formModalSelected: false,
      dossierFull: null,

      libelleResultatExamCode: "",
      libelleResultatExamConduite: "",
      dateReussiteExaCode: "",
      dateReussiteExaConduite: "",
      elevesByAgence: [],
      nbrEchecExaCode: null,
      nbrEchecExaConduite: null,
      showPaiement: false,
      data: [],
      selectedAgence: null,
      selectedItem: null,
      toast: useToast(),
      examenDemande: {
        id: null,
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      formExamen: {
        id: null,
        lieu: null,
        moniteur: null,
        dateStart: "",
        numero: "",
      },
      contract: [],
      contractData: "",
      fileList: [],
      putFile: false,
      checkContrat: false,
      questions: "",
      droitCpf : [],
      showForm : false,

      postPaiement : {
        date : "",
        price : 0,
        mode : "",
        payeur : "",
        choix : "",
      },

      dossier : {
        statut: "",
        step : "",
        code: "",
      },
      showMeContract : false,
      elevesName : [],
      cancelS : false,
      itemS : "",
    };
  },
  mounted() {
    this.getEleveAgence(0);
    this.getNameEleve();
    this.getAgences();
    this.getAllEleveAgence();
  },
  methods: {

    nextPage(offset){
      if(this.dossier.code != ""){
        let changeTab = this.$store.state.allElevesPage.filter(p => p.code == this.dossier.code);
        this.Elevesagence = changeTab;
      }
      if(this.dossier.statut != ""){
        let changeTab = this.$store.state.allElevesPage.filter(p => p.statutDossier == this.dossier.statut);
        this.Elevesagence = changeTab;
      }
      if(this.dossier.step != ""){
        let changeTab = this.$store.state.allElevesPage.filter(p => p.stepForm == this.dossier.step);
        this.Elevesagence = changeTab;
      }
      let end = offset + 10;
      this.Elevesagence = this.Elevesagence.slice(offset, end);
    },

    cancelSearch(){
      this.getEleveAgence(0);
      this.cancelS = false;
      this.itemS = "";
    },

    selectItem(e){
      this.cancelS = true;
      this.itemS = e;
      window.api
        .get("/eleve/agence/"+this.$store.state.user.agence.id+"/"+e.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.Elevesagence = response.data.utilisateurs;
          
          this.elevesAll = response.data.utilisateurs;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },  

    getNameEleve(){
      window.api
        .get("/eleves/agence/name/"+ this.$store.state.user.agence.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.elevesName = response.data.utilisateurs;
        })
        .catch((error) => {
          console.log(error);
        });
    },


    onPaginate(e){
      if(this.dossier.step == "" && this.dossier.code == "" && this.dossier.statut == ""){
        this.dossier.step = "";
        this.dossier.code = "";
        this.dossier.statut = "";
        let offset = e.first;
        this.getEleveAgence(offset);
      }else{
        let offset = e.first;
        this.nextPage(offset);
      }

    },

    signContract(){
      if(parseInt(this.code) == this.selectedItem.newContractCode){
        window.api
          .patch(
            "/contracts/" + this.selectedItem.newContractId,
            {
              piece: {"status" : "valide", "code" : this.code, "date" : new Date().toLocaleDateString("fr")},
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.selectedItem.newContract = "valide";
            this.showMeContract = false;
          })
          .catch((error) => {
            // Indique qu'une pièce n'est plus en cours de validation
            this.isWorkingValider = false;
            console.log(error);
          });
      }
    },

    resendMail(){
      window.api
        .get("/confirm/contract/"+ this.selectedItem.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeModeCode(){
      this.Elevesagence = this.elevesAll;
 
      if(this.dossier.code != ""){
        let changeTab = this.$store.state.allElevesPage.filter(p => p.code == this.dossier.code);
        this.Elevesagence = changeTab.slice(0, 10);
      }
    },

    changeMode(){
      this.Elevesagence = this.elevesAll;
      if(this.dossier.statut != ""){
        let changeTab = this.$store.state.allElevesPage.filter(p => p.statutDossier == this.dossier.statut);
        this.Elevesagence = changeTab.slice(0, 10);
      }
    },

    changeModeForm(){
      this.Elevesagence = this.elevesAll;
      if(this.dossier.step != ""){
        let changeTab = this.$store.state.allElevesPage.filter(p => p.stepForm == this.dossier.step);
        this.Elevesagence = changeTab.slice(0, 10);
      }
    },

    getEleveAgence(offset) {
      window.api
        .get("/eleves/agence/" + this.$store.state.user.agence.id+"/"+offset, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.Elevesagence = response.data.utilisateurs;
          this.elevesAll = response.data.utilisateurs;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getAllEleveAgence() {
      window.api
        .get("/eleves/agence/" + this.$store.state.user.agence.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.$store.commit("setAllElevePage", response.data.utilisateurs);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    postCpf(){

      let droit = this.droitCpf.find(e => e.id == this.postPaiement.choix);
      let montant = droit.tot - this.postPaiement.price;
      if(montant < 0){
        montant = 0;
      }

      window.api
        .put(
          "/other_paiements/"+droit.id,
          {
            eleve: "api/utilisateurs/" + this.selectedItem.id,
            description: "Droits CPF",
            totalAmount: montant * 100,
            mode: droit.mode,
            date: droit.date.date,
            payeur: droit.payeur,
            archive: false,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          window.api
            .post(
              "/other_paiements",
              {
                otherPaiementC: "api/other_paiements/" + droit.id,
                eleve: "api/utilisateurs/" + this.selectedItem.id,
                description: "Acpte CPF",
                totalAmount: this.postPaiement.price * 100,
                mode: this.postPaiement.mode,
                date: this.postPaiement.date,
                payeur: this.postPaiement.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              this.checkPaiement();
              
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getDroitCpf(){
      if(!this.showForm){
        window.api.get("/eleve/check/paiement/" + this.selectedItem.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          }
        }).then(response => {
          this.droitCpf = response.data.droitCpf;
          if(this.droitCpf.length != 0 ){
            this.showForm = true;
          }
        }).catch(error => {
              console.log(error)
        });
      }else{
        this.showForm = false;
        this.postPaiement.date = "";
        this.postPaiement.price = 0;
        this.postPaiement.mode = "";
        this.postPaiement.payeur = "";
        this.postPaiement.choix = "";
      }

    },

    validFormContract() {
      let inputs = document.querySelectorAll("#questionCont");

      let responseContract = [];
      let countTot = 0;
      inputs.forEach((input) => {
        if (input.checked) {
          responseContract.push(parseInt(input.attributes[2].value));
          countTot = countTot + parseInt(input.attributes[2].value);
        } else {
          responseContract.push(0);
        }
      });
      responseContract.push(countTot);
      if (countTot <= 3) {
        responseContract.push([20, 40]);
      } else if (countTot > 3 && countTot <= 7) {
        responseContract.push([15, 35]);
      } else if (countTot > 7 && countTot <= 10) {
        responseContract.push([10, 20]);
      }

      window.api
        .post(
          "/contracts",
          {
            eleve: "api/utilisateurs/" + this.selectedItem.id,
            question: responseContract,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.showContrats();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    savePiece(e) {
      let piece = "";
      if (this.fileList.length == 0) {
        piece = this.selectedItem.contrat.piece;
      } else {
        piece = this.fileList;
      }

      let status = "";
      if (e.target.textContent == "VALIDER") {
        status = "Validé";
      } else {
        status = "Refusé";
      }
      if (this.contract.contractEleve.length == 0) {
        window.api
          .post(
            "/contracts",
            {
              piece: piece,
              status: status,
              eleve: "api/utilisateurs/" + this.selectedItem.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            if (status == "Validé") {
              window.api
                .post(
                  "/sendMail",
                  {
                    type: "contrat valide",
                    email: this.selectedItem.email,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.showContrats();
                  location.reload();
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              window.api
                .post(
                  "/sendMail",
                  {
                    type: "contrat refuse",
                    email: this.selectedItem.email,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.showContrats();
                  location.reload();
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .put(
            "/contracts/" + this.contract.contractEleve[0].id,
            {
              piece: piece,
              status: status,
              eleve: "api/utilisateurs/" + this.selectedItem.id,
              secComment: this.secComment,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            if (status == "Validé") {
              window.api
                .post(
                  "/sendMail",
                  {
                    type: "contrat valide",
                    email: this.selectedItem.email,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.showContrats();
                  this.setComment = "";
                  this.showRefusText = false;
                  location.reload();
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              window.api
                .post(
                  "/sendMail",
                  {
                    type: "contrat refuse",
                    email: this.selectedItem.email,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.showContrats();
                  this.setComment = "";
                  this.showRefusText = false;
                  location.reload();
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    addFile() {
      this.fileList = [];
      let input = document.getElementById("file_input_cont");
      const reader = new FileReader();
      reader.onloadend = () => {
        this.fileList.push({
          file_name: input.files[0].name,
          file_data: reader.result,
        });
      };
      reader.readAsDataURL(input.files[0]);

      this.putFile = true;
      this.checkContrat = true;
    },

    showContrats() {
      this.showMeContract = true;
    },

    solde() {
      this.showPaiement = true;
      this.data = this.selectedItem;
    },

    patchEleve() {
      if (this.selectedItem.agence.nom === "non rattaché") {
        window.api
          .patch(
            `utilisateur/` + this.selectedItem.id,
            {
              agence: "null",
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            this.toast.add({
              severity: "success",
              summary: "Agence modifié",
              detail: "l'agence lié à l'élève été modifié avec succès",
              life: 3000,
            });
            console.log("completed", response.data);
            this.getEleveAgence(0);
            this.selectedItem = null;
          })
          .catch((error) => {
            // handle authentication and validation errors here
            console.log("ERRR::", error.response.data);
          });
      } else {
        window.api
          .patch(
            `utilisateur/` + this.selectedItem.id,
            {
              agence: this.selectedItem.agence.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            this.toast.add({
              severity: "success",
              summary: "Agence modifié",
              detail: "l'agence lié à l'élève été modifié avec succès",
              life: 3000,
            });
            console.log("completed", response.data);
            this.getEleveAgence(0);
            this.selectedItem = null;
          })
          .catch((error) => {
            // handle authentication and validation errors here
            console.log("ERRR::", error.response.data);
          });
      }
    },

    refreshOnDeleteOrArchiveEleve(id) {
      this.Elevesagence = this.Elevesagence.filter((e) => e.id !== id);
    },

    async getAgences() {
      try {
        window.api
          .get("/getAllAgences", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            this.agences = res.data.Agences;
            let nonRatacher = { nom: "non rattaché" };
            this.agences.push(nonRatacher);
          });
      } catch (e) {
        // handle authentication error here
      }
    },
    paiement(e) {
      this.data = e;
      this.showPaiement = true;
    },

    selectFormModal() {
      if (this.formModalSelected) {
        this.formModalSelected = false;
        this.dossierFull = null;
      } else {
        this.formModalSelected = true;
        if (this.selectedItem.Dossier.id !== undefined) {
          window.api
            .get("/dossiers/" + this.selectedItem.Dossier.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.dossierFull = {
                id: this.selectedItem.id,
                nom: this.selectedItem.nom,
                prenom: this.selectedItem.prenom,
                telephone : this.selectedItem.telephone,
                mailEleve : this.selectedItem.email,
                dossier: response.data,
              };
            })
            .catch((error) => {
              console.log(error.response.data.message);
            });
        } else {
          this.dossierFull = {
            id: this.selectedItem.id,
            nom: this.selectedItem.nom,
            prenom: this.selectedItem.prenom,
            telephone : this.selectedItem.telephone,
            mailEleve : this.selectedItem.email,
            dossier: {
              nomUsage: "",
              autresPrenom: "",
              telParent: "",
              mailParent: "",
              adresse: "",
              codePostal: "",
              ville: "",
              dateNaissance: "",
              paysNaissance: "",
              depNaissance: "",
              villeNaissance: "",
              nationaliteFR: "null",
              parlerFRCouramment: "null",
              vehiculeAmenage: "null",
              lunettes: "null",
              permis: "null",
              faitSuite: "null",
              motifAlcoolStup: "null",
              prevoitAac: "null",
              social: "null",
              metier: "",
              lycee: "null",
              autreLycee: "",
              numNeph: "",
            },
          };
        }
      }
    },

    onSelected() {
      if (this.selectedItem != null) {
        console.log("select vbie : ", this.selectedItem)
        this.showPaiement = false;
        this.showForm = false;
        document.getElementById("right").style.background = "none";
      }
    },

    // onSelected() {
    //   this.Filteredagences = this.agences.filter(
    //     (a) => a.nom !== "Non rattachés" && a.nom !== "Tous"
    //   );
    //   let nonRatacher = { nom: "non ratacher" };
    //   this.Filteredagences.push(nonRatacher);
    //   if (this.selectedItem != null) {
    //     document.getElementById("right").style.background = "none";
    //   }
    //   //Algo pour l'affichage de l'historique des examens de code pour l'élève sélectionné
    //   // Si l'élève à un dernier examen qui est un Echec on récupère le nombres d'échec et le libelle du resultat devient Echec , pas de date de réussite
    //   if (this.selectedItem.Exam.length != "") {
    //     if (
    //       this.selectedItem.Exam != "" &&
    //       this.selectedItem.Exam[this.selectedItem.Exam.length - 1].code ===
    //         "Echec"
    //     ) {
    //       this.nbrEchecExaCode = this.selectedItem.Exam.filter(
    //         (exa) => exa.code === "Echec"
    //       ).length;
    //       this.libelleResultatExamCode = "Echec";
    //       this.dateReussiteExaCode = "";
    //       // Sinon si l'élève à un dernier examen qui est une Réussite le nb d'echec devient nul et le libelle devient réussite, on recup sa date de réussite
    //     } else if (
    //       this.selectedItem.Exam != "" &&
    //       this.selectedItem.Exam[this.selectedItem.Exam.length - 1].code ===
    //         "Réussite"
    //     ) {
    //       this.nbrEchecExaCode = null;
    //       this.libelleResultatExamCode = "Réussite";
    //       this.dateReussiteExaCode =
    //         this.selectedItem.Exam[this.selectedItem.Exam.length - 1].date;
    //       // Sinon lélève n'a jamais passé d'examen
    //     }
    //   } else {
    //     this.nbrEchecExaCode = null;
    //     this.libelleResultatExamCode = "Aucun Passage enregistré";
    //     this.dateReussiteExaCode = "";
    //   }
    //   // Même Algorithme que les examens de code mais cette fois pour la conduite
    //   // Si l'élève à un dernier examen qui est un Echec on récupère le nombres d'échec et le libelle du resultat devient Echec , pas de date de réussite
    //   if (this.selectedItem.DemandeExam.length > 0) {
    //     if (
    //       this.selectedItem.DemandeExam != "" &&
    //       this.selectedItem.DemandeExam[
    //         this.selectedItem.DemandeExam.length - 1
    //       ].code === "Echec"
    //     ) {
    //       this.nbrEchecExaConduite = this.selectedItem.DemandeExam.filter(
    //         (exa) => exa.code === "Echec"
    //       ).length;
    //       this.libelleResultatExamConduite = "Echec";
    //       this.dateReussiteExaConduite = "";
    //       // Sinon si l'élève à un dernier examen qui est une Réussite le nb d'echec devient nul et le libelle devient réussite , on recup sa date de réussite
    //     } else if (
    //       this.selectedItem.DemandeExam != "" &&
    //       this.selectedItem.DemandeExam[
    //         this.selectedItem.DemandeExam.length - 1
    //       ].code === "Réussite"
    //     ) {
    //       this.nbrEchecExaConduite = null;
    //       this.libelleResultatExamConduite = "Réussite";
    //       this.dateReussiteExaConduite =
    //         this.selectedItem.DemandeExam[
    //           this.selectedItem.DemandeExam.length - 1
    //         ].DateExamen;
    //       // Sinon lélève n'a jamais passé d'examen , pas de date de réussite
    //     }
    //   } else {
    //     this.nbrEchecExaConduite = null;
    //     this.libelleResultatExamConduite = "Aucun Passage enregistré";
    //     this.dateReussiteExaConduite = "";
    //   }
    // },

    async deleteUtilisateur(id) {
      if (this.elevesByAgence.find((e) => e.montantTotal === 0)) {
        try {
          if (confirm("Voulez vous vraiment supprimer ?")) {
            window.api
              .delete("utilisateurs/" + id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.toast.add({
                  severity: "success",
                  summary: "Eleve supprimé",
                  detail: "Votre eleve à été supprimé avec succès",
                  life: 3000,
                });
                this.getEleveAgence(0);
                console.log(response);
              });
          }
        } catch (e) {
          // handle authentication error here
        }
      } else {
        this.toast.add({
          severity: "warn",
          summary: "Cette eleve ne peut pas être supprimé",
          detail: "Cette eleve a au moins un paiement",
          life: 3000,
        });
      }
    },
    async archiveEleve(id) {
      try {
        if (confirm("Voulez vous vraiment Archiver cette eleve ?")) {
          window.api
            .patch(
              "utilisateur/" + id,
              {
                active: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Eleve archivé",
                detail: "Votre eleve à été archivé avec succès",
                life: 3000,
              });
              this.getEleveAgence(0);
              console.log(response);
            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },
    onSubmit() {
      this.creerExamen();
    },
    closeModal(e) {
      if (e.target.id == "closeCreationExam") {
        document.getElementById("myModalCreationExam").style.display = "none";
      }
    },
    async getEleveById(eleve) {
      document.getElementById("myModalCreationExam").style.display = "block";
      window.api
        .get(`/utilisateur/` + eleve.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((result) => {
          this.utilisateurs = result.data.utilisateur[0];
          this.utilisateurs.id = eleve.id;
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.result.data);
        });
    },

    async getExamenDemandeByEleve(id) {
      window.api
        .get(`/getExamenDemandeByEleve/` + id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((result) => {
          this.examenDemande = result.data.ExamenDemande;
          this.examenDemande.id =
            result.data.ExamenDemande[this.examenDemande.length - 1].id;
          this.putExamenDemande(this.examenDemande.id);
          console.log(
            "miam",
            result.data.ExamenDemande[this.examenDemande.length - 1].id
          );
          console.log("aloo", this.examenDemande[0]);
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.result.data);
        });
    },
  },
  computed: {
    lieus() {
      return this.$store.state.lieus;
    },
    moniteurs() {
      return this.$store.state.moniteurs;
    },
  },
};
</script>

<style scoped>
div.bottomButtonContract {
  display: flex;
}

button.buttoncontract {
  background: #07989f;
  border-radius: 11px;
  color: #ffffff;
  border-color: #0d89ec;
}

div.bottomButtonContract button {
  background: #07989f;
  border-radius: 11px;
  color: #ffffff;
  border-color: #0d89ec;
}

.ContractSigne {
  padding-top: 100px;
}

div.postContract {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

div.postContract button {
  background: #07989f;
  border-radius: 11px;
  color: #ffffff;
  border-color: #0d89ec;
}

div.postContract button a {
  color: #ffffff;
}

.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}
#containerInfoEleve {
  margin-top: 5%;
}
#exaCode {
  display: flex;
  flex-direction: row;
  justify-items: space-around;
}
#exaCode > p {
  font-size: 1.3em;
}
#exaConduite > p {
  font-size: 1.3em;
}
#exaConduite {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#archive {
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 2%;
}
#archiveBtn {
  background: #07989f;
  border-radius: 11px;
}
#iconHome {
  cursor: pointer;
}
.btnForm {
  background-color: #de6fa8; /* Green */
  border: none;
}
a {
  text-decoration: none;
}
#histo {
  width: 220px;
}
#formModif {
  margin: auto;
  width: 100%;
}
#head {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 50%;
}
#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}
#titreChoix {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}
#styleTitreChoix {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
  align-self: center;
}
#eleve {
  background-image: url("../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}
#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  justify-content: center !important;
  min-height: 100vh;
  display: flex;
}
#titreEleve {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}
#conteneurEleve {
  display: grid;
  grid-template-columns: 50% 50%;
}
#conteneurEleve div {
  justify-content: space-between;
}
.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

div.input {
  margin-top: 2em;
}

/* input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}
input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}
label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
} */
</style>
