


<template>
    <div class="Conduite row">
        <button @click="jIndispo">J-Indispo</button>
        <div class="col-12">
            <FullCalendar  :options="calendarOptions" />
        </div>
      <!-- The Modal -->
      <div id="modalResaMono" class="modal">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <span id="monoCancelResa" class="close mono" @click="closeModalMono">&times;</span>
            <h2>Rendez-vous</h2>
          </div>
          <div class="modal-body">
            <div class="contentmodalclose">


              <template v-if="choixDateResa != false">
                <router-link :to="{name:'mono-trame', params: {rdvId: this.idRdv, id:this.ideleve}}"><button v-if="!choixDateResa['event']['_def']['extendedProps']['done']">Accéder à la trame</button><button v-if="choixDateResa['event']['_def']['extendedProps']['done']">Modifier la trame</button></router-link>
                <div v-if="!choixDateResa['event']['_def']['extendedProps']['done']">
                  <select id="selectTypeMotif">
                    <option value="choix">Choix un type de motif</option>
                    <template v-for="(type, index) in typeMotif" :key="index">
                      <option :value="type.id">{{type.label}}</option>
                    </template>
                  </select>
                  <input type="text" name="lemotif" id="" placeholder="Saisir le motif" v-model="motif">
                  <button @click="validerCancel">Annuler le cours</button>
                </div>
              </template>

            </div>
          </div>
          <div class="modal-footer"></div>
        </div>

      </div>

      <!-- The Modal -->
      <div id="modalJIndispo" class="modal">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <span id="monoCancelJInspo" class="close mono" @click="closeModalMono">&times;</span>
            <h2>J-Indispo</h2>
          </div>
          <div class="modal-body">
            <div class="contentmodalclose">
              <div >
                <input type="text" name="lemotif" placeholder="Saisir le motif" v-model="motif">
                <button @click="validerJIndispo">Annuler les cours</button>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>

      </div>

    </div>
</template>

<script>

import '@fullcalendar/core/vdom' 
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';

//import axios from 'axios'

export default {
  name: 'Conduite',
  components: {
    FullCalendar, // make the <FullCalendar> tag available

  },
  
  data() {
      return {
        expanded : false,
        adresse:"",
        calendarOptions: {
            plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
            initialView: 'timeGridDay',
            nowIndicator: true,
            eventResizableFromStart: true,
            firstDay: 1,
            allDaySlot: false,
            slotMinTime: "07:00:00",
            slotMaxTime: "21:00:00",
            height: "auto",
            locale: "fr",
            selectable: true,
            timeZone: "local",
            headerToolbar: {
              start: "prev,next today",
              center: "title",
              end: "timeGridWeek,timeGridDay",
            },
            buttonText: {
              today: "Aujourd'hui",
              prev: "<",
              next: ">"
            },
            events:[],
            select: this.select,
            eventClick: this.clickEvent,
            editable: true,
            selectAllow: this.selectAllow,
            eventChange: this.putDispoEleve,
        },
        moniteur: [],
        typeMotif: [],
        motif:"",
        choixDateResa: false,
        idRdv: false,
        ideleve: false,
        rdvjIndispo : [],
      }

  },
  mounted() {
    this.getMoniteur()
    this.getTypeMotif()
  },
  methods: {

    validerJIndispo(){
      window.api.post("/motifs", {
            typeMotif: "api/type_motifs/3",
            label: this.motif
          },
          {
            headers:{
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
            let now = new Date;
            let now2 = new Date;
            now.setHours(now.getHours() +1)
            this.rdvjIndispo.forEach(rdv => {
              window.api.patch("/apointments/"+rdv.id, {
                    motif: response.data["@id"],
                    cancelRdv: now,
                    CancelBy: "api/utilisateurs/"+this.$store.state.user.id.toString()
                  },
                  {
                    headers:{
                      "Authorization": "Bearer " + this.$store.state.token,
                      "Content-Type": "application/merge-patch+json"
                    },
                  }).then(res => {
                let resa = this.calendarOptions.events.find(event => {
                  if(event.id == res.data.id && event.type == "resa"){
                    return true
                  }
                })
                resa.color = "grey";
                resa.motif = "Mono Absent";
                let resultResa = this.calendarOptions.events.filter(event => {
                  if(event.id != res.data.id && event.type == "resa"){
                    return true
                  }
                })
                resultResa.push(resa)
                let resultIndispo = this.calendarOptions.events.filter(event => {
                  if(event.type == "Indispo"){
                    return true
                  }
                })

                let result = []

                resultResa.forEach(resResa => {
                  result.push(resResa)
                })

                resultIndispo.forEach(resIndispo => {
                  result.push(resIndispo)
                })

                this.calendarOptions.events = result

              }).catch(error => {
                console.log(error)
              })
            })

            now.setHours(7);
            now.setMinutes(0);
            now.setSeconds(0);
            now2.setHours(21);
            now2.setMinutes(0);
            now2.setSeconds(0);

            window.api.post("/indisponibilites", {
                  start: moment(now).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(now2).format("YYYY-MM-DDTHH:mm:ss"),
                  moniteur: "api/moniteurs/"+this.moniteur.idMonos,
                },
                {
                  headers:{
                    "Authorization": "Bearer " + this.$store.state.token
                  },
                }).then(response => {
              this.calendarOptions.events.push({id:response.data.id,title:"INDISPO", start:moment(now).format("YYYY-MM-DDTHH:mm:ss"), end:moment(now2).format("YYYY-MM-DDTHH:mm:ss"), color:"grey", type:"Indispo", "motif": false})
            }).catch(error => {
              console.log(error)
            })
        }).catch(error => {
          console.log(error)
        })
      document.getElementById("modalJIndispo").style.display = "none";
    },

    jIndispo(){
      let now = new Date();
      this.moniteur.rdv.forEach(ap => {
        let dateStart = new Date(ap.start.date);
        if(now.getDay() == dateStart.getDay() && now.getMonth() == dateStart.getMonth() && now.getFullYear() == dateStart.getFullYear()){
          this.rdvjIndispo.push(ap);
        }
      })
      if(this.rdvjIndispo.length != 0){
        document.getElementById("modalJIndispo").style.display = "block";
      }

    },

    validerCancel(){
      let selectCancel = document.getElementById("selectTypeMotif");

      let typemot = this.typeMotif.find(typmot => {
        return typmot.id == selectCancel.value
      })
      window.api.post("/motifs", {
            typeMotif: "api/type_motifs/"+selectCancel.value,
            label: this.motif
          },
          {
            headers:{
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        let now = new Date
        now.setHours(now.getHours() +1)
        window.api.patch("/apointments/"+this.choixDateResa.event["_def"]["publicId"], {
              motif: response.data["@id"],
              cancelRdv: now,
              CancelBy: "api/utilisateurs/"+this.$store.state.user.id.toString()
            },
            {
              headers:{
                "Authorization": "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json"
              },
            }).then(res => {
          let resa = this.calendarOptions.events.find(event => {
            if(event.id == res.data.id && event.type == "resa"){
              return true
            }
          })

          if(typemot.label == "Mono Absent"){
            resa.color = "grey";
            resa.motif = "Mono Absent";
          }else if(typemot.label == "Incident"){
            resa.color = "yellow";
            resa.motif = "Incident";
            resa.textColor = "black"
          }

          let resultResa = this.calendarOptions.events.filter(event => {
            if(event.id != res.data.id && event.type == "resa"){
              return true
            }
          })


          let resultIndispo = this.calendarOptions.events.filter(event => {
            if(event.type == "Indispo"){
              return true
            }
          })

          let result = []

          if(typemot.label != "Eleve Absent" && typemot.label != "Autre"){
            resultResa.push(resa)
          }

          resultResa.forEach(resResa => {
            result.push(resResa)
          })

          resultIndispo.forEach(resIndispo => {
            result.push(resIndispo)
          })


          this.calendarOptions.events = result
          document.getElementById("modalResaMono").style.display = "none";
        }).catch(error => {
          console.log(error)
        })


      }).catch(error => {
        console.log(error)
      })
    },

    getTypeMotif(){
      window.api.get("/type_motifs", {
        headers:{
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then( response => {
        this.typeMotif = response.data["hydra:member"]
      }).catch(error => {
        console.log(error)
      })
    },

    closeModalMono(e){
      if(e.target.id === "monoCancelResa"){
        document.getElementById("modalResaMono").style.display = "none";
      }else if(e.target.id === "monoCancelJInspo"){
        document.getElementById("modalJIndispo").style.display = "none";
      }
    },

    clickEvent(e){

      if(e.event["_def"].extendedProps.type == "Indispo"){
        window.api.delete("/indisponibilites/"+e.event["_def"].publicId,
            {
              headers:{
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(() => {
          let res = this.calendarOptions.events.filter(event => {
            return event.id != e.event["_def"].publicId;
          })
          this.calendarOptions.events = res;
        }).catch(error => {
          console.log(error)
        })
      }else if(e["event"]["_def"]["extendedProps"]["type"] == "resa" && e["event"]["_def"]["extendedProps"]["motif"] == false){
        if(e["event"]["_def"]["extendedProps"]["done"] == false){
          this.choixDateResa = e;
          this.idRdv = e.event["_def"].publicId;
          this.ideleve = e.event["_def"]["extendedProps"]["ideleve"];
          document.getElementById("modalResaMono").style.display = "block";
        }else{
          let now = new Date();
          let startDate = new Date(Date.parse(e["event"]["_def"]["extendedProps"]["doneDate"]["date"]));
          let endDate = new Date(startDate.setDate(startDate.getDate() + 1))
          if(now < endDate){
            this.choixDateResa = e;
            this.idRdv = e.event["_def"].publicId;
            this.ideleve = e.event["_def"]["extendedProps"]["ideleve"];
            document.getElementById("modalResaMono").style.display = "block";
          }
        }

      }
    },

    getMoniteur(){
      window.api.get("/moniteur/"+this.$store.state.user.id,
      {
        headers:{
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.moniteur = response.data.moniteur[0];
        this.moniteur.indispos.forEach(indispo => {
          this.calendarOptions.events.push({id:indispo.id, start:indispo.start.date, end:indispo.end.date, title:"INDISPO", color:"grey", type:"Indispo"})
        })

        this.moniteur.rdv.forEach(app => {
          if(app.motif.type == "Mono Absent"){
            this.calendarOptions.events.push({id:app.id,title:app.eleve + "  "+app.lieu+" MONO ABS", start:moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end:moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color:"grey", type:"resa", done:app.done, "editable":false, "motif": "Mono Absent"})
          }else if(app.motif.type == "Incident"){
            this.calendarOptions.events.push({id:app.id,title:app.eleve + "  "+app.lieu, start:moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end:moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color:"yellow",textColor:"black", type:"resa", done:app.done, "editable":false, "motif": "Incident"})
          }else if(app.motif == "null"){
            if(app.done == true){
              this.calendarOptions.events.push({id:app.id,title:app.eleve + "  "+app.lieu, start:moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end:moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color:"mediumturquoise", type:"resa", done:app.done, "editable":false, "motif": false, ideleve:app.ideleve, doneDate: app.doneDate})
            }else{
              this.calendarOptions.events.push({id:app.id,title:app.eleve + "  "+app.lieu, start:moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end:moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color:"green", type:"resa", done:app.done, "editable":false, "motif": false, ideleve:app.ideleve})
            }

          }
        })

      }).catch(error => {
        console.log(error)
      })
    },

    select(e){
      window.api.post("/indisponibilites", {
        start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
        moniteur: "api/moniteurs/"+this.moniteur.idMonos,
      },
      {
        headers:{
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.calendarOptions.events.push({id:response.data.id,title:"INDISPO", start:moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"), end:moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"), color:"grey", type:"Indispo", "motif": false})
      }).catch(error => {
        console.log(error)
      })
    },

    selectAllow(select){
        return moment().diff(select.start) <= 0;
    },
  },
}
</script>

<style scoped>

div.contentmodalclose{
  display: flex;
}
div.Conduite{
  margin-top: 2em;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */

  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s
}

/* Modal Content */
.modal-content {

  margin:auto;
  margin-top:5em;
  background-color: #fefefe;
  width: 50%;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #93C2FF;
  color: white;
}

.modal-body {padding: 2px 16px;}

.modal-footer {
  padding: 2px 16px;
  background-color: #FFFFFF;
  color: white;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}

@keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}


</style>