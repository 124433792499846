<template>
  <div class="trame">
    <h1>Trame</h1>

    <table class="trame-update">
      <thead>
      <tr>
        <th></th>
        <th>Objectifs</th>
        <th>Durée</th>
        <th>Traité</th>
        <th>Assimilé</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(competence, index) in trame['competences']" :key="index">
        <template v-for="(objectif, i) in competence['objectifs']" :key="i">
          <tr>
            <th v-bind:style="{ 'background-color': competence.couleur }"></th>
            <td>{{objectif.label}}</td>
            <td>{{objectif.duree}}</td>
            <template v-if="objectif.objacq == 'traite'">
              <td><input :data-id="objectif.id" type="checkbox" name="traite" checked disabled></td>
              <td><input :data-id="objectif.id" type="checkbox" name="assimile" disabled></td>
            </template>
            <template v-else-if="objectif.objacq === 'assimile'">
              <td><input :data-id="objectif.id" type="checkbox" name="traite" checked disabled></td>
              <td><input :data-id="objectif.id" type="checkbox" name="assimile" checked disabled></td>
            </template>
            <template v-else>
              <td><input :data-id="objectif.id" type="checkbox" name="traite" disabled></td>
              <td><input :data-id="objectif.id" type="checkbox" name="assimile" disabled></td>
            </template>

          </tr>
          <template v-for="(sousObjectif, idx) in objectif['sousObjectifs']" :key="idx">
            <tr>
              <th v-bind:style="{ 'background-color': competence.couleur }"></th>
              <td style="padding-left:50px;">-{{sousObjectif.label}}</td>
              <td>{{sousObjectif.duree}}</td>

              <template v-if="objectif.objacq == 'traite'">
                <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked disabled></td>
                <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile" disabled></td>
              </template>
              <template v-else-if="objectif.objacq === 'assimile'">
                <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked disabled></td>
                <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile" checked disabled></td>
              </template>
              <template v-else>
                <template v-if="sousObjectif.acquis == 'null'">
                  <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" disabled></td>
                  <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile" disabled></td>
                </template>
                <template v-else>
                  <template v-if="sousObjectif.acquis[0].id == 1">
                    <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked disabled></td>
                    <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile" disabled></td>
                  </template>
                  <template v-else>
                    <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked disabled></td>
                    <td><input id="ssobj" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile" checked disabled></td>
                  </template>
                </template>
              </template>

            </tr>
          </template>
        </template>
      </template>
      </tbody>
    </table>
    <router-link to="/PlanningAgence"><b-button variant="outline-primary">Retourner au planning</b-button></router-link>

  </div>
</template>

<script>

export default {
  name: "Trame",
  data() {
    return {
      trame: [],
    }
  },

  mounted() {
    this.getTrame();
  },
  methods: {

    getTrame(){
      window.api.get("/trame/"+ this.$route.params.id, {
        headers:{
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then( response => {
        this.trame = response.data.trame[0];
      }).catch(error => {
        console.log(error)
      })
    },

  },

}
</script>

<style scoped>
table.trame-update {
  width: 90%;
  margin:auto;
}

table.trame-update thead{
  background-color:black;
  color: white;
}
div.trame{
  margin-top: 2em;
}
</style>