<template>
  <div class="main">
    <Toast />
    <template v-if="load == 2">
      <div class="left">

        <header id="head">
          <DropdownNav></DropdownNav>
            <i @click="goToHome()" class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i>
          <h1 class="ms-3" id="titreEleve">Création du dossier</h1>
        </header>


        <div v-if="step < 3">

          <div id="title">
            <p class="title" v-if="step == 1">Etape ({{ step }} / 5)</p>
          </div>

          <div class="row">
            <div class="field offset-lg-2 col-md-4">
              <input
                :value="userData.nom"
                type="text"
                @change="handleChangeNom($event.target.value)"
                id="nom"
                name="nom"
                class="form-control marge"
                placeholder=" "
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkNom }"
              />
              <label class="labelForm">Nom</label>
            </div>

            <div class="field col-lg-4 col-md-4 col-sm-12">
              <input
                :value="userData.prenom"
                @change="handleChangePrenom($event.target.value)"
                type="text"
                id="prenom"
                name="prenom"
                class="form-control marge"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkPrenom }"
                placeholder=" "
              />
              <label class="labelForm">Prénom</label>
            </div>
          </div>

          <div class="row">
            <div
              class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 col-lg- mt-4"
            >
              <input
                :value="userData.telephone"
                @change="handleChangeTelephone($event.target.value)"
                type="text"
                id="telephone"
                name="telephone"
                class="form-control inputsize"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkTelephone }"
                placeholder=" "
              />
              <label class="labelForm">Téléphone</label>
            </div>
          </div>

          <div class="row">
            <div
              class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
            >
              <input
                :value="userData.dossier.nomUsage"
                @change="handleChangeNomUsage($event.target.value)"
                type="text"
                id="nomUsage"
                name="nomUsage"
                class="form-control marge"
                :disabled="desac"
                placeholder=" "
              />
              <label class="labelForm">Nom d'usage</label>
            </div>

            <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
              <input
                :value="userData.dossier.autresPrenom"
                @change="handleChangeAutrePrenom($event.target.value)"
                type="text"
                id="autresPrenom"
                name="autresPrenom"
                class="form-control marge"
                :disabled="desac"
                placeholder=" "
              />
              <label class="labelForm">Autres prénoms</label>
            </div>
          </div>

          <div class="row">
            <div
              class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
            >
              <input
                :value="dateNaissanceFormated"
                @change="handleChangeDateNaissance($event.target.value)"
                type="date"
                id="dateNaissance"
                name="dateNaissance"
                class="form-control marge"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkDateNaissance }"
                placeholder=" "
              />
              <label class="labelForm">Date de naissance</label>
            </div>

            <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
              <input
                :value="userData.dossier.adresse"
                @change="handleChangeAdresse($event.target.value)"
                type="text"
                id="adresse"
                name="adresse"
                class="form-control inputsize"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkAdresse }"
                placeholder=" "
              />
              <label class="labelForm">Adresse</label>
            </div>
          </div>

          <div class="row">
            <div
              class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
            >
              <input
                :value="userData.dossier.codePostal"
                @change="handleChangeCodePostal($event.target.value)"
                type="text"
                id="codePostal"
                name="codePostal"
                class="form-control"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkCodePostal }"
                placeholder=" "
              />
              <label class="labelForm">Code Postal</label>
            </div>

            <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
              <input
                :value="userData.dossier.ville"
                @change="handleChangeVille($event.target.value)"
                type="text"
                id="ville"
                name="ville"
                class="form-control marge"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkVille }"
                placeholder=" "
              />
              <label class="labelForm">Ville</label>
            </div>
          </div>

          <div class="row">
            <div
              class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
            >
              <input
                :value="userData.dossier.telParent"
                @change="handleChangeTelParent($event.target.value)"
                type="text"
                id="telParent"
                name="telParent"
                class="form-control marge"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkTelParent }"
                placeholder=" "
              />
              <label class="labelForm">Téléphone parent</label>
            </div>

            <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
              <input
                :value="userData.dossier.mailParent"
                @change="handleChangeMailParent($event.target.value)"
                type="text"
                id="mailParent"
                name="mailParent"
                class="form-control marge"
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkMailParent }"
                placeholder=" "
              />
              <label class="labelForm">Mail parent</label>
            </div>
          </div>

          <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
            <select
              v-model="qNumNeph"
              id="numNephQ"
              name="numNephQ"
              class="form-select inputsize"
              placeholder=" "
              :disabled="desac"
              :class="{ isNotValid: showValidation && !checkqNeph }"
            >
              <option value="null" disabled>Avez-vous un numéro NEPH ?</option>
              <option value="true">OUI</option>
              <option value="false">NON</option>
            </select>
            <label class="labelForm">Numéro NEPH</label>
          </div>

          <div v-if="qNumNeph == 'true'">
            <div class="field offset-lg-2 col-lg-8 col-md-8 col-sm-12 mt-4">
              <input
                :value="userData.dossier.numNeph"
                @change="handleChangeNumNeph($event.target.value)"
                type="text"
                id="numNeph"
                name="numNeph"
                style="margin: auto"
                class="form-control marge"
                :disabled="desac"
                placeholder=" "
                :class="{ isNotValid: showValidation && !checkNeph }"
              />
              <label class="labelForm">Numéro NEPH</label>
            </div>
          </div>
          <p class="errorMsg" v-if="step == 1">{{ this.error }}</p>
          <b-button @click="suivantEtape" class="btnForm " v-if="step == 1">
            Suivant
          </b-button>
        </div>

        <div v-if="step >= 3">
          <div v-if="step < 5">
            <div id="title">
              <p class="title" v-if="step == 3">Etape ({{ step }} / 5)</p>
            </div>

            <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
              <select
                v-model="this.informationComp.boite"
                id="boite"
                name="boite"
                class="form-select inputsize"
                placeholder=" "
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkBoite }"
              >
                <option value="null" disabled>Avec qu'elle type de boite voulez-vous conduire ?</option>
                <option value="bm">Boite manuelle</option>
                <option value="ba">Boite Automatique</option>
              </select>

              <label class="labelForm">Choisissez votre type de vehicule ?</label>
            </div>

            <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
              <select
                v-model="this.informationComp.forfait"
                id="forfait"
                name="forfait"
                class="form-select inputsize"
                placeholder=" "
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkForfait }"
              >
                <option value="null" disabled>Que voulez faire avec nous ?</option>
                <option value="code">Code</option>
                <option value="code,conduite">Code et Conduite</option>
                <option value="conduite">Conduite</option>
              </select>

              <label class="labelForm">Que voulez-vous réaliser avec nous ?</label>
            </div>

            <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
              <select
                v-model="this.contractValue.code"
                id="code"
                name="code"
                class="form-select inputsize"
                placeholder=" "
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkCode }"
              >
                <option value="null" disabled>Avez-vous eu votre code ?</option>
                <option value="true">OUI</option>
                <option value="false">NON</option>
              </select>

              <label class="labelForm">Avez-vous eu votre code ?</label>
              
            </div>

            <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
              <select
                v-model="this.contractValue.conduite"
                id="conduit"
                name="conduit"
                class="form-select inputsize"
                placeholder=" "
                :disabled="desac"
                :class="{ isNotValid: showValidation && !checkConduite }"
              >
                <option value="null" disabled>Avez-vous déjà conduit ?</option>
                <option value="0">Entre 0 et 4h</option>
                <option value="1">Entre 5 et 10h</option>
                <option value="3">Entre 11 et +20h</option>
              </select>

              <label class="labelForm">Avez-vous déjà conduit ?</label>

            </div>

            <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
              <MultiSelect
                class="mutltiSelect"
                :class="{ isNotValid: showValidation && !checkInfo }"
                v-model="this.contractValue.selectedContrat"
                :options="knows"
                optionLabel="brand"
                placeholder="Savez-vous"
                :disabled="desac"
              />

            </div>

            <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
              <MultiSelect
                class="mutltiSelect"
                :class="{ isNotValid: showValidation && !checkInfoTwo }"
                v-model="this.contractValue.selectedKnows"
                :options="knowsTwo"
                optionLabel="brand"
                placeholder="Savez-vous"
                :disabled="desac"
              />
            </div>

            <p class="errorMsg">{{ this.error }}</p>
            <div class="btnForm" v-if="step == 3">
              <b-button
                @click="cancelStep"
                class="btnForm mt-3 col-md-2 col-sm-12"
              >
                Retour
              </b-button>

              <b-button
                @click="suivantEtape3"
                class="btnForm mt-3 col-md-2 col-sm-12"
              >
                Suivant
              </b-button>
            </div>
          </div>
        </div>


        <div v-if="step == 5">

          <div id="title">
            <p class="title" v-if="step == 5">Etape ({{ step }} / 5)</p>
          </div>

          <div class="contractContainer">

            <template v-if="social != 'Salarié'">
              <Contrat :age="ageEleve" :user="dataForm" :fonc="fonc" :agence="agence" :questions="contract.questions" :social="social" :contractStatus="contractStatus" :contract="contract"/>
            </template>

            <template v-else>
              <ContratPro :age="ageEleve" :user="dataForm" :fonc="fonc" :agence="agence" :questions="contract.questions" :social="social" :contractStatus="contractStatus" :contract="contract"/>
            </template>
          </div>

          <div class="btnForm">
            <b-button
              @click="cancelStep"
              class="btnForm mt-3 col-md-2 col-sm-12"
            >
              Retour
            </b-button>

          </div>

        </div>



      </div>

      <div class="right">
        <div v-if="step >= 1">
          <div v-if="step < 3">
            <div id="title" v-if="step == 2">
              <p class="title">Etape ({{ step }} / 5)</p>
            </div>
            <div class="row" v-if="this.dataForm.dossier.numNeph == ''">
              <div class="field offset-lg-2 offset-md-2 col-md-4 col-xs-10 mt-4">
                <select
                  :value="userData.dossier.nationaliteFR"
                  @change="handleChangeNationaliteFR($event.target.value)"
                  id="nationaliteFR"
                  name="nationaliteFR"
                  class="form-select inputsize"
                  :class="{ isNotValid: showValidation && !checkNationaliteFR }"
                  placeholder=" "
                  :disabled="!desac"
                >
                  <option value="null" disabled>Nationalité française</option>
                  <option value="true">OUI</option>
                  <option value="false">NON</option>
                </select>

                <label class="labelForm">Nationalité française</label>
              </div>

              <div class="field col-md-4 col-sm-12 mt-4">
                <input
                  :value="userData.dossier.paysNaissance"
                  @change="handleChangePaysNaissance($event.target.value)"
                  type="text"
                  id="paysNaissance"
                  name="paysNaissance"
                  class="form-control marge"
                  :class="{ isNotValid: showValidation && !checkPaysNaissance }"
                  placeholder=" "
                  :disabled="!desac"
                />
                <label class="labelForm">Pays de naissance</label>
              </div>

              <div class="field offset-lg-2 offset-md-2 col-md-4 col-sm-12 mt-4">
                <input
                  :value="userData.dossier.villeNaissance"
                  @change="handleChangeVilleNaissance($event.target.value)"
                  type="text"
                  id="villeNaissance"
                  name="villeNaissance"
                  class="form-control inputsize"
                  :class="{
                    isNotValid: showValidation && !checkVilleNaissance,
                  }"
                  placeholder=" "
                  :disabled="!desac"
                />
                <label class="labelForm">Ville de naissance</label>
              </div>

              <div class="field col-md-4 col-sm-12 mt-4">
                <input
                  :value="userData.dossier.depNaissance"
                  @change="handleChangeDepNaissance($event.target.value)"
                  type="text"
                  id="depNaissance"
                  name="depNaissance"
                  class="form-control inputsize"
                  :class="{ isNotValid: showValidation && !checkDepNaissance }"
                  placeholder=" "
                  :disabled="!desac"
                />
                <label class="labelForm">Département de naissance</label>
              </div>
            </div>

            <div class="row">
              <div class="field offset-lg-2 offset-md-2 col-md-4 col-xs-10 mt-4">
                <select
                  :value="userData.dossier.parlerFRCouramment"
                  @change="handleChangeParlerFRCouramment($event.target.value)"
                  id="parlerFRCouramment"
                  name="parlerFRCouramment"
                  class="form-select inputsize"
                  :class="{
                    isNotValid: showValidation && !checkParlerFRCouramment,
                  }"
                  placeholder=" "
                  :disabled="!desac"
                >
                  <option value="null" disabled>Parlez-vous français ?</option>
                  <option value="true">OUI</option>
                  <option value="false">NON</option>
                </select>

                <label class="labelForm">Parlez-vous français</label>

              </div>

              <div class="field col-md-4 col-xs-10 mt-4">
                <select
                  :value="userData.dossier.permis"
                  @change="handleChangePermis($event.target.value)"
                  id="permis"
                  name="permis"
                  class="form-select inputsize marge"
                  :class="{ isNotValid: showValidation && !checkPermis }"
                  placeholder=" "
                  :disabled="!desac"
                >
                  <option value="null" disabled>Permis demandé ?</option>
                  <option value="/api/permis/3">B</option>
                </select>

                <label class="labelForm">Permis demandé ?</label>

              </div>
            </div>
            <div class="row">
              <div class="field offset-lg-2 col-md-4 col-xs-10 mt-4">
                <select
                  :value="userData.dossier.vehiculeAmenage"
                  @change="handleChangeVehiculeAmenage($event.target.value)"
                  id="vehiculeAmenage"
                  name="vehiculeAmenage"
                  class="form-select inputsize"
                  :class="{
                    isNotValid: showValidation && !checkVehiculeAmenage,
                  }"
                  placeholder=" "
                  :disabled="!desac"
                >
                  <option value="null" disabled>
                    Avez-vous un handicap ou une affection ?
                  </option>
                  <option value="true">OUI</option>
                  <option value="false">NON</option>
                </select>

                <label class="labelForm">Avez-vous un handicap ou une affection ?</label>

              </div>

              <div class="field  col-md-4 col-xs-10 mt-4">
                <select
                  :value="userData.dossier.lunettes"
                  @change="handleChangeLunettes($event.target.value)"
                  id="lunettes"
                  name="lunettes"
                  class="form-select inputsize"
                  :class="{ isNotValid: showValidation && !checkLunettes }"
                  placeholder=" "
                  :disabled="!desac"
                >
                  <option value="true">OUI</option>
                  <option value="false">NON</option>
                </select>


                <label class="labelForm">Portez-vous des lunettes ?</label>

              </div>

            </div>

            <div class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4">
              <select
                :value="userData.dossier.faitSuite"
                @change="handleChangeFaitSuite($event.target.value)"
                id="faitSuite"
                name="faitSuite"
                class="form-select inputsize"
                :class="{ isNotValid: showValidation && !checkFaitSuite }"
                placeholder=" "
                :disabled="!desac"
              >
                <option value="null" disabled>
                  La présente demande fait-elle suite à une annulation /
                  invalidation de permis ?
                </option>
                <option value="true">OUI</option>
                <option value="false">NON</option>
              </select>

              <label class="labelForm">La présente demande fait-elle suite à une annulation /
                  invalidation de permis ?</label>

            </div>

            <div
              v-if="this.dataForm.dossier.faitSuite == 'true'"
              class="field offset-lg-2 offset-md-2 col-md-8 col-xs-10 mt-4"
            >
              <select
                :value="userData.dossier.motifAlcoolStup"
                @change="handleChangeMotifAlcoolStup($event.target.value)"
                id="motifAlcoolStup"
                name="motifAlcoolStup"
                class="form-select inputsize"
                :class="{
                  isNotValid: showValidation && !checkMotifAlcoolStup,
                }"
                placeholder=" "
                :disabled="!desac"
              >
                <option value="null" disabled>
                  En raison d'une conduite sous alcoolémie ou stupéfiant ?
                </option>
                <option value="true">OUI</option>
                <option value="false">NON</option>
              </select>

              <label class="labelForm">En raison d'une conduite sous alcoolémie ou stupéfiant ?</label>

            </div>

            <div class="row">
              <div class="field offset-lg-2 col-md-4 col-xs-10 mt-4">
                <select
                  :value="userData.dossier.prevoitAac"
                  @change="handleChangePrevoitAac($event.target.value)"
                  id="prevoitAac"
                  name="prevoitAac"
                  class="form-select inputsize"
                  :class="{ isNotValid: showValidation && !checkPrevoitAac }"
                  placeholder=" "
                  :disabled="!desac"
                >
                  <option value="true">OUI</option>
                  <option value="false">NON</option>
                </select>

                <label class="labelForm">Prévoyez-vous une conduite accompagnée ?</label>
                
              </div>

              <div class="field col-md-4 col-xs-10 mt-4">
                
                <select
                  :value="userData.dossier.social"
                  @change="handleChangeSocial($event.target.value)"
                  id="social"
                  name="social"
                  class="form-select inputsize"
                  :class="{ isNotValid: showValidation && !checkSocial }"
                  placeholder=" "
                  :disabled="!desac"
                >

                  <option
                    v-for="(item, index) in statutSocial"
                    :value="'/api/statut_socials/' + item.id"
                    :key="index"
                  >
                    {{ item.label }}
                  </option>
                </select>

                <label class="labelForm">Statut social</label>

              </div>

              <div
                class="field offset-lg-2 offset-md-2 col-md-4 col-xs-10 mt-4"
                :class="{ isHide: !isLyceen }"
              >
                <select
                  :value="userData.dossier.lycee"
                  @change="handleChangeLycee($event.target.value)"
                  id="lycee"
                  name="lycee"
                  class="form-select inputsize"
                  placeholder=" "
                  :disabled="!desac"
                >
                  <option disabled>Lycée</option>
                  <option value="null">Autre</option>
                  <option
                    v-for="(item, index) in lycees"
                    :value="'/api/lycees/' + item.id"
                    :key="index"
                  >
                    {{ item.nom }}
                  </option>
                </select>

                <label class="labelForm">Lycée</label>

              </div>

              <div
                v-if="userData.dossier.lycee == 'null'"
                class="field col-md-4 col-sm-12 mt-4"
                :class="{ isHide: !isAutreLycee }"
              >
                <input
                  :value="userData.dossier.autreLycee"
                  @change="handleChangeAutreLycee($event.target.value)"
                  type="text"
                  id="autreLycee"
                  name="autreLycee"
                  class="form-control inputsize"
                  :class="{ isNotValid: showValidation && !checkAutreLycee }"
                  placeholder=" "
                  :disabled="!desac"
                />
                <label class="labelForm">Autre Lycée</label>
              </div>
            </div>

            <p class="errorMsg" v-if="step == 2">{{ this.error }}</p>
            <div class="btnForm" v-if="step == 2">
              <b-button
                @click="cancelStep"
                class="btnForm mt-3 col-md-2 col-sm-12"
              >
                Retour
              </b-button>

              <b-button
                @click="suivantEtape2"
                class="btnForm mt-3 col-md-2 col-sm-12"
              >
                Suivant
              </b-button>
            </div>
          </div>
        </div>

        <div v-if="step == 4">
          <div id="title" v-if="step == 4">
            <p class="title">Etape ({{ step }} / 5)</p>
          </div>
          <p class="subTitle">Composez votre forfait</p>
          <template v-if="!this.$store.state.studentDossier">

            <template v-if="!this.$store.state.studentCode">
              <div class="forfait late">
                  <input type="checkbox" id="tard" name="tard" @click="late()">
                  <label for="dossier">Je choisirai plus tard</label>
              </div>
              <p>OU</p>
            </template>

          </template>

          <div class="row">
            <div class="forfait choix">
              <input type="checkbox" id="dossier" name="dossier" checked disabled>
              <label for="dossier">Dossier</label>
            </div>
            <div class="forfait choix">
              <template v-if="!this.$store.state.studentDossier">
                <p v-if="this.dataForm.dossier.numNeph == ''">{{ dossierPrice }} €</p>
                <p v-if="this.dataForm.dossier.numNeph != ''">Vous l'avez déjà</p>
              </template>
              <template v-else>
                <p>Déjà payé</p>
              </template>

            </div>
          </div>

          <div class="row">
            <div class="choix forfait">
              <template v-if="!this.$store.state.studentCode">
                <input @change="changeCodeCbx" type="checkbox" id="codeCbxok" name="code" v-if="this.contract.questions[0] == 0">
                <input type="checkbox" id="codeCbx" name="code" v-if="this.contract.questions[0] == 1" checked disabled>
              </template>
              <template v-else>
                <input type="checkbox" id="codeCbx" name="code" checked disabled>
              </template>
              <label for="code">Code</label>
            </div>
            <div class="choix">
              <template v-if="!this.$store.state.studentCode">
                <template v-if="this.contract.questions != undefined">
                  <p v-if="this.contract.questions[0] == 0">{{ codePrice }} €</p>
                  <p v-if="this.contract.questions[0] == 1">Vous l'avez déjà</p>
                </template>
              </template>
              <template v-else>
                <p>Déjà payé</p>
              </template>

            </div>
          </div>
          <p >Pour le moment, les heures de conduite seront à régler directement auprès de l'agence.</p>
          <!-----
          <div class="row">
            <div class="mt-3 col-md-5 col-sm-12 forfait">
              <input @change="changeConduiteCbx" type="checkbox" id="conduite" name="conduite">
              <label for="conduite" title="Vous n'avez jamais conduit ? Vous devez donc effectuer au moins 20h (minumum légal). Pour cela vous avez 2 possibilité : -Soit payer les 20h maintenant pour les poser sur le planning quand vous voulez ou payer une partie maintenant et le reste plus tard dans la partie conduite.">Conduite</label>
            </div>
            <div class="mt-3 col-md-5 col-sm-12">
              <select name="hour" id="hour" :disabled="!canTakeHour" @change="selectHourChange">
                <option disabled>heures</option>
                <option value="0">0h</option>
                <option value="2">2h</option>
                <option value="4">4h</option>
                <option value="6">6h</option>
                <option value="8">8h</option>
                <option value="10">10h</option>
                <option value="12">12h</option>
                <option value="14">14h</option>
                <option value="16">16h</option>
                <option value="18">18h</option>
                <option value="20">20h</option>
              </select>
              
              X

              {{ conduitePrice }} €

              =

              {{ resultConduitePrice }}
            </div>
          </div>--->
          <template v-if="!this.$store.state.studentDossier">
            <template v-if="!this.$store.state.studentCode">
              <p>Total : {{ priceChoice.price }} €</p>
            </template>
          </template>
          

          <div class="btnForm">
            <b-button
              @click="cancelStep"
              class="btnForm mt-3 col-md-2 col-sm-12"
            >
              Retour
            </b-button>

            <b-button
              @click="suivantEtape4"
              class="btnForm mt-3 col-md-2 col-sm-12"
            >
              Suivant
            </b-button>
          </div>

        </div>

        <div v-if="step == 5">
          <div id="title" v-if="step == 5">
            <p class="title">Etape ({{ step }} / 5)</p>
          </div>
          <template v-if="!contractStatus">
            <p class="resendMail">Vous n'avez pas reçu le mail ? <button @click="resendMail" class="btn btn-primary">Renvoyer le mail</button></p>
            <input type="number" name="code" id="Code" placeholder="Saisir le code du mail" v-model="code"> <input v-if="ageEleve < 18" type="number" name="code" id="Code" placeholder="Code de votre parent" v-model="codeParentForm"> 
            <button class=" btn-primary" @click="signContract">Signer le contrat</button>
          </template>
          <template v-else>
            <p>Vous avez reçu votre contrat signé par mail</p>
            <button class="btn btn-primary" @click="sendContratPdf">Vous n'avez pas reçu votre contrat ? cliquez ici </button>
            <br>

              <template v-if="priceChoice.price != 0">
                <template v-if="lateBtn != true">
                  <template v-if="this.dataForm.dossier.numNeph == ''">
                    <template v-if="!this.$store.state.studentDossier">
                      <template v-if="!this.$store.state.studentCode">
                        <p>
                          Préstations : {{ priceChoice.name }} .
                        </p>
                        <p>
                          Montant : {{ priceChoice.price }}€ .
                        </p>
                        <p>Vous avez la possibilité de régler de 3 manières possibles :</p>
                        <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
                      </template>
                    </template>
                    
                    <template v-if="!this.$store.state.studentDossier">
                      <template v-if="this.$store.state.studentCode">
                        <p>
                          Préstations : {{ priceChoice.name }} .
                        </p>
                        <p>
                          Montant : {{ priceChoice.price }}€ .
                        </p>
                        <p>Vous avez la possibilité de régler de 3 manières possibles :</p>
                        <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
                      </template>
                    </template>


                    <template v-if="this.$store.state.studentDossier">
                      <template v-if="!this.$store.state.studentCode">
                        <p>
                          Préstations : {{ priceChoice.name }} .
                        </p>
                        <p>
                          Montant : {{ priceChoice.price }}€ .
                        </p>
                        <p>Vous avez la possibilité de régler de 3 manières possibles :</p>
                        <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="contract.questions[0] == 0">
                      <template v-if="!this.$store.state.studentDossier">
                        <template v-if="!this.$store.state.studentCode">
                          <p>
                            Préstations : {{ priceChoice.name }} .
                          </p>
                          <p>
                            Montant : {{ priceChoice.price }}€ .
                          </p>
                          <p>Vous avez la possibilité de régler de 3 manières possibles :</p>
                          <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
                        </template>
                      </template>
                      
                      <template v-if="!this.$store.state.studentDossier">
                        <template v-if="this.$store.state.studentCode">
                          <p>
                            Préstations : {{ priceChoice.name }} .
                          </p>
                          <p>
                            Montant : {{ priceChoice.price }}€ .
                          </p>
                          <p>Vous avez la possibilité de régler de 3 manières possibles :</p>
                          <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
                        </template>
                      </template>


                      <template v-if="this.$store.state.studentDossier">
                        <template v-if="!this.$store.state.studentCode">
                          <p>
                            Préstations : {{ priceChoice.name }} .
                          </p>
                          <p>
                            Montant : {{ priceChoice.price }}€ .
                          </p>
                          <p>Vous avez la possibilité de régler de 3 manières possibles :</p>
                          <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
                        </template>
                      </template>
                    </template>
                  </template>

                </template>
              </template>


            <button @click="goToHomeEleve" class=" btn btn-primary home">Retourner à l'accueil</button>
          </template>
        </div>

      </div>
    </template>
    <template v-else>
      <Loader />
    </template>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Contrat from "../contrat/Contrat.vue";
import ContratPro from "../contrat/ContratPro.vue";
import Loader from "@/components/Loader.vue";
import BoutonPaiement from "@/components/eleve/BoutonPaiement.vue";
import { useToast } from "primevue/usetoast";


export default {
  name: "Formulaires",
  components: {
    Contrat,
    ContratPro,
    Loader,
    BoutonPaiement
  },
  computed: {
    ...mapGetters(["permis", "statutSocial", "lycees", "fonctionnalite"]),

    filterFonctionnaliteMontant() {
      // Si les fonctionnalités sont chargées
      if (this.$store.state.fonctionnalite !== null) {
        // Lecture de la valeur de la fonctionnalité dossier
        return this.$store.state.fonctionnalite.filter(
          (el) => el.label === "dossier"
        )[0].montant;
      }
      return "";
    },

    dateNaissanceFormated() {
      // Si le format de la date est "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      // Quand la date provient de la base de donnée
      if (this.dataForm.dossier.dateNaissance.match(/.+[T ]/g) !== null) {
        // Alors conversion en format "yyyy-MM-dd"
        return this.dataForm.dossier.dateNaissance
          .match(/.+[T ]/g)[0]
          .substring(
            0,
            this.dataForm.dossier.dateNaissance.match(/.+[T ]/g)[0].length - 1
          );
      }
      // Sinon la date reste comme elle est
      return this.dataForm.dossier.dateNaissance;
    },

    checkAllStepOne() {
      return (
        this.checkNom &&
        this.checkPrenom &&
        this.checkTelephone &&
        this.checkAdresse &&
        this.checkCodePostal &&
        this.checkVille &&
        this.checkDateNaissance &&
        this.checkTelParent &&
        this.checkMailParent &&
        this.checkqNeph
      );
    },

    checkAllStepTwo() {
      return (
        this.checkParlerFRCouramment &&
        this.checkPermis &&
        this.checkVehiculeAmenage &&
        this.checkPrevoitAac &&
        this.checkMotifAlcoolStup &&
        this.checkFaitSuite &&
        this.checkLunettes &&
        this.checkSocial &&
        this.checkNationaliteFR &&
        this.checkPaysNaissance &&
        this.checkVilleNaissance &&
        this.checkDepNaissance &&
        this.checkAutreLycee
      );
    },

    checkAllStepThree() {
      return (
        this.checkCode &&
        this.checkConduite &&
        this.checkInfo &&
        this.checkInfoTwos
      );
    },

    checkNom() {
      const match = this.dataForm.nom.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.dataForm.nom === match[0];
      }
      return false;
    },
    checkPrenom() {
      const match = this.dataForm.prenom.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.dataForm.prenom === match[0];
      }
      return false;
    },
    checkTelParent() {
      var diff =
        Date.now() - new Date(this.dataForm.dossier.dateNaissance).getTime();
      var age = new Date(diff);

      var age_checked = Math.abs(age.getUTCFullYear() - 1970);

      const match = this.dataForm.dossier.telParent.match(/[0-9]{10}/g);
      if (age_checked < 18) {
        if (match !== null && match.length === 1) {
          return this.dataForm.dossier.telParent === match[0];
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    checkMailParent() {
      var diff =
        Date.now() - new Date(this.dataForm.dossier.dateNaissance).getTime();
      var age = new Date(diff);

      var age_checked = Math.abs(age.getUTCFullYear() - 1970);

      const match = this.dataForm.dossier.mailParent.match(
        /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
      );
      if (age_checked < 18) {
        if (match !== null && match.length === 1) {
          return this.dataForm.dossier.mailParent === match[0];
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    checkTelephone() {
      // Match le numéro de téléphone français
      const match = this.dataForm.telephone.match(/[0-9]{10}/g);
      // Si le numéro de téléphone a un match
      if (match !== null && match.length === 1) {
        // true quand le match est exactement égal au numéro de téléphone
        return this.dataForm.telephone === match[0];
      }
      return false;
    },
    checkDateNaissance() {
      return this.dataForm.dossier.dateNaissance !== "";
    },

    checkAdresse() {
      return this.dataForm.dossier.adresse !== "";
    },

    checkCodePostal() {
      // Match les code postaux français (5) ou belge (4)
      const match = this.dataForm.dossier.codePostal.match(
        /[0-9]{5}|[1-9]{1}[0-9]{3}/g
      );
      // Si le code postal a un match
      if (match !== null && match.length === 1) {
        // true quand le match est exactement égal au code postal
        return this.dataForm.dossier.codePostal === match[0];
      }
      return false;
    },

    checkVille() {
      return this.dataForm.dossier.ville !== "";
    },

    checkNeph() {
      if (this.qNumNeph == "true") {
        return this.dataForm.dossier.numNeph !== "";
      } else {
        return true;
      }
    },

    checkqNeph() {
      return this.qNumNeph !== null;
    },

    checkMotifAlcoolStup() {
      if (this.dataForm.dossier.faitSuite == "true") {
        return this.dataForm.dossier.motifAlcoolStup !== "null";
      } else {
        return true;
      }
    },

    checkDepNaissance() {
      if (this.qNumNeph == "false") {
        return this.dataForm.dossier.depNaissance !== "";
      } else {
        return true;
      }
    },

    checkVilleNaissance() {
      if (this.qNumNeph == "false") {
        return this.dataForm.dossier.villeNaissance !== "";
      } else {
        return true;
      }
    },

    checkPaysNaissance() {
      if (this.qNumNeph == "false") {
        return this.dataForm.dossier.paysNaissance !== "";
      } else {
        return true;
      }
    },

    checkNationaliteFR() {
      if (this.qNumNeph == "false") {
        return this.dataForm.dossier.nationaliteFR !== "null";
      } else {
        return true;
      }
    },

    checkParlerFRCouramment() {
      return this.dataForm.dossier.parlerFRCouramment !== "null";
    },

    checkPermis() {
      return this.dataForm.dossier.permis !== "null";
    },

    checkVehiculeAmenage() {
      return this.dataForm.dossier.vehiculeAmenage !== "null";
    },

    checkFaitSuite() {
      return this.dataForm.dossier.faitSuite !== "null";
    },

    checkPrevoitAac() {
      return this.dataForm.dossier.prevoitAac !== "null";
    },

    checkLunettes() {
      return this.dataForm.dossier.lunettes !== "null";
    },

    checkSocial() {
      if(this.dataForm.dossier.social == null || this.dataForm.dossier.social == "null"){
        return false;
      }else{
        return true;
      }
    },

    checkAutreLycee() {
      if (this.isLyceen && this.dataForm.dossier.lycee === "null") {
        return this.dataForm.dossier.autreLycee !== "";
      } else {
        return true;
      }
    },

    isLyceen() {
      // Si le statut social à été chargé
      if (this.checkSocial && this.statutSocial !== null) {
        // Boucle les statuts sociaux
        for (let i = 0; i < this.statutSocial.length; i++) {
          // Si le statut est "Lycéen"
          if (this.statutSocial[i].label === "Lycéen") {
            // retourne si le statut séléctionné est le statut "Lycéen"
            return (
              "/api/statut_socials/" + this.statutSocial[i].id ===
              this.dataForm.dossier.social
            );
          }
        }
      }
      return false;
    },
    isAutreLycee() {
      // Si le statut social séléctionné est le statut "Lycéen" et que le lycée séléctionné est "Autre Lycée"
      return this.isLyceen && this.dataForm.dossier.lycee === "null";
    },

    checkCode() {
      return this.contractValue.code !== null;
    },

    checkBoite() {
      return this.informationComp.boite !== null;
    },

    checkConduite() {
      return this.contractValue.conduite !== null;
    },

    checkForfait() {
      return this.contractValue.forfait !== null;
    },

    checkInfo() {
      return this.contractValue.selectedContrat !== null;
    },

    checkInfoTwo() {
      return this.contractValue.selectedKnows !== null;
    },
  },
  props: {
    userData: { type: Object },
    showValidation: { type: Boolean },
    isWorking: { type: Boolean },
  },
  data() {
    return {
      load : 0,
      dataForm: this.userData,
      step: 1,
      qNumNeph: null,
      error: "",
      desac: false,
      contract: null,
      contractValue: {
        code: null,
        conduite: null,
        selectedContrat: null,
        selectedKnows: null,
      },

      informationComp: {
        boite : null,
        forfait: null
      },

      knows: [
        { brand: "Démarrer un véhicule", value: "1" },
        { brand: "Aborder une carrefour à sens giratoire", value: "3" },
        { brand: "Aborder une entrée d'autoroute", value: "2" },
        { brand: "Aucune de ses réponses", value: "0" },
      ],
      knowsTwo: [
        { brand: "Allumer tous les feux du véhicule", value: "1" },
        { brand: "Tourner le volant en chevauchant les mains", value: "1" },
        { brand: "Démarrer sur une terrain en cote", value: "1" },
        { brand: "Manipuler les vitesses de 1 à 5", value: "2" },
        { brand: "Aucune de ses réponses", value: "0" },
      ],
      dossierPrice : 0,
      codePrice : 0,
      conduitePrice : 0,
      priceChoice : {
        name : "",
        price : 0
      },
      resultConduitePrice : 0,
      canTakeHour: false,
      fonc : [],
      agence : [],
      social : "",
      code : "",
      contractStatus : false,
      lateBtn : false,
      ageEleve : 0,
      codeParentForm : "",
      toast: useToast(),
    };
  },
  mounted() {
    this.getFonctionnalite();
    this.getAgence();
  },
  methods: {

    sendContratPdf(){
      window.api
        .get("contract/"+this.$store.state.user.id+"/sendmail", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
          this.toast.add({
              severity: "success",
              summary: "Votre contrat a bien été envoyé par mail !",
              detail: "N'oubliez pas de regarder dans vos spams au cas où !",
              life: 3000,
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goToHome(){
      window.location.reload();
    },

    goToPaiement(mode) {

      let fonc = this.$store.state.fonctionnalite;
      fonc.push({"id": 9999999, "label" : this.priceChoice.name, "montant" : this.priceChoice.price});
      this.$store.commit("setFonctionnalite", fonc);

      this.$store.dispatch("startSaveBouton", mode);
      let fonctionnalite = this.priceChoice.name;
      let router = this.$router;
      this.$store.dispatch("endSaveBouton", { mode, fonctionnalite, router });
    },


    goToHomeEleve(){
      window.location.reload();
    },


    late(){
      this.resendMail();
      this.step = this.step + 1;
      this.lateBtn = true;
    },

    selectHourChange(e){
      this.priceChoice.price = this.priceChoice.price - this.resultConduitePrice;
      let hour = parseInt(e.target.value);
      this.resultConduitePrice = hour * this.conduitePrice;
      this.priceChoice.price = this.priceChoice.price + this.resultConduitePrice;

    },

    changeCodeCbx(e){
      this.priceChoice.name = "";
      if(e.target.checked){

        if(this.dataForm.dossier.numNeph == '' && this.$store.state.studentDossier == false){
          this.priceChoice.name = this.priceChoice.name + "dossier";
        }

        if(this.priceChoice.name.length == 0){
          this.priceChoice.name = this.priceChoice.name +  "code";
        }else{
          this.priceChoice.name = this.priceChoice.name +  ",code";
        }

        if(document.getElementById("conduite") != null){
          if(this.contract.questions[0] == 0 && document.getElementById("conduite").checked){
            if(this.priceChoice.name.length == 0){
              this.priceChoice.name = this.priceChoice.name +  "conduite";
            }else{
              this.priceChoice.name = this.priceChoice.name + ",conduite";
            }
          }
        }



        this.priceChoice.price = this.priceChoice.price + this.codePrice;

      }else{

        if(this.dataForm.dossier.numNeph == '' && this.$store.state.studentDossier == false){
          this.priceChoice.name = "dossier";
        }

        if(document.getElementById("conduite") != null){
          if(this.contract.questions[0] == 0 && document.getElementById("conduite").checked){
            if(this.priceChoice.name.length == 0){
              this.priceChoice.name = this.priceChoice.name + "conduite";
            }else{
              this.priceChoice.name = this.priceChoice.name + ",conduite";
            }
          }
        }

        this.priceChoice.price = this.priceChoice.price - this.codePrice;
      }
    },

    changeConduiteCbx(e){
      this.priceChoice.name = "";
      if(e.target.checked){

        if(this.dataForm.dossier.numNeph == '' && this.$store.state.studentDossier == false){
          this.priceChoice.name = this.priceChoice.name + "dossier";
        }

        if(this.contract.questions[0] == 0 && document.getElementById("codeCbxok").checked){
          if(this.priceChoice.name.length == 0){
            this.priceChoice.name = this.priceChoice.name + "code";
          }else{
            this.priceChoice.name = this.priceChoice.name + ",code";
          }
        }

        if(this.priceChoice.name.length == 0){
          this.priceChoice.name = this.priceChoice.name + "conduite";
        }else{
          this.priceChoice.name = this.priceChoice.name + ",conduite";
        }

        this.canTakeHour = true;
      }else{

        if(this.dataForm.dossier.numNeph == '' && this.$store.state.studentDossier == false){
          this.priceChoice.name = this.priceChoice.name + "dossier";
        }

        if(this.contract.questions[0] == 0 && document.getElementById("codeCbxok").checked){
          if(this.priceChoice.name.length == 0){
            this.priceChoice.name = this.priceChoice.name + "code";
          }else{
            this.priceChoice.name = this.priceChoice.name + ",code";
          }
        }

        this.canTakeHour = false;
        this.priceChoice.price = this.priceChoice.price - this.resultConduitePrice;
        this.resultConduitePrice = 0;
      }
    },


    getAgence() {
      window.api
        .get("/agences/"+ this.$store.state.user.agence.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.agence = response.data;
          this.load = this.load + 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },


    getFonctionnalite() {
      window.api
        .get("/fonctionnalites/", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.fonc = response.data["hydra:member"];
          response.data["hydra:member"].forEach((fonc) => {
            if (fonc.label === "dossier") {
              this.dossierPrice = fonc.montant;
            } else if (fonc.label === "code") {
              this.codePrice = fonc.montant;
            } else if (fonc.label === "conduite") {
              this.conduitePrice = fonc.montant;
            }
            
          });
          this.getContract();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    getContract() {
      window.api
        .get("/contract/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.contract = response.data;

          if(this.contract.contractEleve != undefined){
            if(this.contract.contractEleve[0].piece != undefined){
              if(this.contract.contractEleve[0].piece.status == "valide"){
                this.contractStatus = true;
              }
            }
          }
          if(this.$store.state.user.dateInsc == "null"){
            this.step = 1;
          }else{
            if (this.dataForm.dossier.adresse == "") {
              this.step = 1;
              this.dataForm.dossier.social = "null";
              this.dataForm.dossier.parlerFRCouramment = "true";
              this.dataForm.dossier.permis = "/api/permis/3";
              this.dataForm.dossier.vehiculeAmenage = "null";
              this.dataForm.dossier.prevoitAac = "null";
              this.dataForm.dossier.faitSuite = "null";
              this.dataForm.dossier.motifAlcoolStup = "null";
              this.dataForm.dossier.lunettes = "null";
              this.dataForm.dossier.nationaliteFR = "true";
            } else if (
              this.dataForm.dossier.adresse != "" &&
              this.dataForm.dossier.social == undefined
            ) {
              this.dataForm.dossier.social = "null";
              this.dataForm.dossier.parlerFRCouramment = "true";
              this.dataForm.dossier.permis = "/api/permis/3";
              this.dataForm.dossier.vehiculeAmenage = "null";
              this.dataForm.dossier.prevoitAac = "null";
              this.dataForm.dossier.faitSuite = "null";
              this.dataForm.dossier.motifAlcoolStup = "null";
              this.dataForm.dossier.lunettes = "null";
              this.dataForm.dossier.nationaliteFR = "true";
              this.step = 2;
              this.desac = true;
            } else if (
              this.dataForm.dossier.social != "null" &&
              this.contract.questions == "null" 
            ) {
              this.desac = false;
              this.step = 3;
            } else if (this.contract.questions != "null") {
              this.contractValue.selectedContrat = [];
              this.contractValue.selectedKnows = [];
              if(this.contract.questions[0] == 1){
                this.contractValue.code = "true";
              }

              if(this.contract.questions[0] == 0){
                this.contractValue.code = "false";
              }

              this.contractValue.conduite = this.contract.questions[1];


              if(this.contract.questions[2] == 1){
                this.contractValue.selectedContrat.push({ brand: "Démarrer un véhicule", value: "1" });
              }

              if(this.contract.questions[3] == 3){              
                this.contractValue.selectedContrat.push({ brand: "Aborder une carrefour à sens giratoire", value: "3" });
              }

              if(this.contract.questions[4] == 2){              
                this.contractValue.selectedContrat.push({ brand: "Aborder une entrée d'autoroute", value: "2" });
              }

              if(this.contract.questions[5] == 1){              
                this.contractValue.selectedKnows.push({ brand: "Allumer tous les feux du véhicule", value: "1" });
              }

              if(this.contract.questions[6] == 1){              
                this.contractValue.selectedKnows.push({ brand: "Tourner le volant en chevauchant les mains", value: "1" });
              }

              if(this.contract.questions[7] == 1){              
                this.contractValue.selectedKnows.push({ brand: "Démarrer sur une terrain en cote", value: "1" });
              }

              if(this.contract.questions[8] == 2){              
                this.contractValue.selectedKnows.push({ brand: "Manipuler les vitesses de 1 à 5", value: "2" });
              }

              if(this.contract.questions[2] == 0 && this.contract.questions[3] == 0 && this.contract.questions[4] == 0){
                this.contractValue.selectedContrat.push({ brand: "Aucune de ses réponses", value: "0" });
              }

              if(this.contract.questions[5] == 0 && this.contract.questions[6] == 0 && this.contract.questions[7] == 0 && this.contract.questions[8] == 0){
                this.contractValue.selectedKnows.push({ brand: "Aucune de ses réponses", value: "0" });
              }



              this.step = 4;
              if(this.dataForm.dossier.numNeph == ''){
                if(!this.$store.state.studentDossier){
                  this.priceChoice.name = "dossier";
                  this.priceChoice.price = this.priceChoice.price + this.dossierPrice;
                }



                var diff =
                Date.now() - new Date(this.dataForm.dossier.dateNaissance).getTime();
                var age = new Date(diff);
                var age_checked = Math.abs(age.getUTCFullYear() - 1970);
                this.ageEleve = age_checked;

              }

            }
          }


          this.load = this.load + 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // LES HANDLES CHANGE POUR LES DATAS

    handleChangeNom(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.nom = data;
      this.handleChange(userDataTmp);
    },

    handleChangePrenom(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.prenom = data;
      this.handleChange(userDataTmp);
    },

    handleChangeTelephone(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.telephone = data;
      this.handleChange(userDataTmp);
    },

    handleChangeNomUsage(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.nomUsage = data;
      this.handleChange(userDataTmp);
    },

    handleChangeAutrePrenom(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.autresPrenom = data;
      this.handleChange(userDataTmp);
    },

    handleChangeDateNaissance(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.dateNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangeAdresse(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.adresse = data;
      this.handleChange(userDataTmp);
    },

    handleChangeCodePostal(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.codePostal = data;
      this.handleChange(userDataTmp);
    },

    handleChangeVille(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.ville = data;
      this.handleChange(userDataTmp);
    },

    handleChangeTelParent(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.telParent = data;
      this.handleChange(userDataTmp);
    },

    handleChangeMailParent(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.mailParent = data;
      this.handleChange(userDataTmp);
    },

    handleChangeNumNeph(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.numNeph = data;
      this.handleChange(userDataTmp);
    },

    handleChangeDepNaissance(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.depNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangeVilleNaissance(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.villeNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangePaysNaissance(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.paysNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangeNationaliteFR(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.nationaliteFR = data;
      this.handleChange(userDataTmp);
    },

    handleChangeParlerFRCouramment(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.parlerFRCouramment = data;
      this.handleChange(userDataTmp);
    },

    handleChangePermis(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.permis = data;
      this.handleChange(userDataTmp);
    },
    handleChangeVehiculeAmenage(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.vehiculeAmenage = data;
      this.handleChange(userDataTmp);
    },

    handleChangePrevoitAac(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.prevoitAac = data;
      this.handleChange(userDataTmp);
    },

    handleChangeFaitSuite(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.faitSuite = data;
      this.handleChange(userDataTmp);
    },

    handleChangeLunettes(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.lunettes = data;
      this.handleChange(userDataTmp);
    },

    handleChangeSocial(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.social = data;
      this.handleChange(userDataTmp);
    },

    handleChangeLycee(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.lycee = data;
      this.handleChange(userDataTmp);
    },

    handleChangeAutreLycee(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.autreLycee = data;
      this.handleChange(userDataTmp);
    },

    handleChangeMotifAlcoolStup(data) {
      let userDataTmp = this.dataForm;
      userDataTmp.dossier.motifAlcoolStup = data;
      this.handleChange(userDataTmp);
    },

    handleChange(userDataUpdated) {
      // Envoie l'update du formulaire
      this.$emit("update", userDataUpdated);
    },

    suivantEtape() {
      let check = this.checkAllStepOne;

      if (!check) {
        this.error = "Champ(s) invalide(s) :";
        if (!this.checkNom) {
          this.error += " Nom, ";
        }
        if (!this.checkPrenom) {
          this.error += " Prénom, ";
        }

        if (!this.checkTelephone) {
          this.error += " Téléhpone, ";
        }

        if (!this.checkDateNaissance) {
          this.error += " Date de naissance, ";
        }

        if (!this.checkAdresse) {
          this.error += " Adresse, ";
        }

        if (!this.checkCodePostal) {
          this.error += " Code Postal, ";
        }

        if (!this.checkVille) {
          this.error += " Ville, ";
        }

        if (!this.checkTelParent) {
          this.error += " Téléphone parent, ";
        }

        if (!this.mailParent) {
          this.error += " Mail parent, ";
        }

        if (!this.checkqNeph) {
          this.error += " Question numéro Neph, ";
        }

        if (!this.checkNeph) {
          this.error += " Numéro Neph, ";
        }

        this.error += "veuillez changer vos réponses";
      } else {
        let mode = "null";


        this.$emit("saveDossier", mode);

        let userCo = this.$store.state.user;
        userCo.nom = this.userData.nom;
        userCo.prenom = this.userData.prenom;
        this.desac = true;
        this.$store.commit("setUser", userCo);
        this.error = "";
        this.step = this.step + 1;
      }
    },

    suivantEtape2() {
      let check = this.checkAllStepTwo;
      if (!check) {
        this.error = "Champ(s) invalide(s) :";
        if (!this.checkParlerFRCouramment) {
          this.error += " Parler FR, ";
        }
        if (!this.checkPermis) {
          this.error += " Permis demandé, ";
        }

        if (!this.checkVehiculeAmenage) {
          this.error += " Véhicule aménagé, ";
        }

        if (!this.checkPrevoitAac) {
          this.error += " Conduite accompagnée, ";
        }

        if (!this.checkMotifAlcoolStup) {
          this.error += " Motif alcool stup, ";
        }

        if (!this.checkFaitSuite) {
          this.error += " Fait suite à une annulation, ";
        }

        if (!this.checkLunettes) {
          this.error += " Lunettes, ";
        }

        if (!this.checkSocial) {
          this.error += " Status social, ";
        }

        if (!this.checkNationaliteFR) {
          this.error += " Nationalité FR, ";
        }

        if (!this.checkPaysNaissance) {
          this.error += " Pays de naissance, ";
        }

        if (!this.checkVilleNaissance) {
          this.error += " Ville de naissance, ";
        }

        if (!this.checkVilleNaissance) {
          this.error += " Département de naissance, ";
        }

        if (!this.checkAutreLycee) {
          this.error += " Autre lycée, ";
        }

        this.error += "veuillez changer vos réponses";
      } else {
        let social = this.statutSocial.find(e => e.id == this.userData.dossier.social.substring(20));

        if(social["label"] == "Lycéen"){
          localStorage.removeItem("lyceen");
          localStorage.setItem("lyceen", "true");
          this.$store.commit("setLyceen", true);
        }

        let mode = "null";
        this.$emit("saveDossier", mode);
        this.error = "";
        this.step = this.step + 1;
        this.desac = false;
      }
    },

    suivantEtape3() {
      if (
        !this.checkCode ||
        !this.checkConduite ||
        !this.checkInfo ||
        !this.checkInfoTwo ||
        !this.checkBoite ||
        !this.checkForfait
      ) {
        this.error = "Champ(s) invalide(s) :";
        if (!this.checkCode) {
          this.error += " Code, ";
        }
        if (!this.checkConduite) {
          this.error += " Avez-vous conduit, ";
        }

        if (!this.checkInfo) {
          this.error += " Le 1er savez-vous, ";
        }

        if (!this.checkInfoTwo) {
          this.error += " Le 2ème savez-vous, ";
        }

        if (!this.checkBoite) {
          this.error += " Type de boite, ";
        }

        if (!this.checkForfait) {
          this.error += " Forfait, ";
        }

        this.error += "veuillez changer vos réponses";
      } else {
        let questions = [];
        let code = 0;
        if (this.contractValue.code == "true") {
          code = 1;
        }

        questions.push(code);
        questions.push(parseInt(this.contractValue.conduite));

        let findQ1 = false;
        let findQ2 = false;
        let findQ3 = false;

        this.contractValue.selectedContrat.forEach((response) => {
          if(response.brand == "Démarrer un véhicule"){
            findQ1 = true;
          }

          if(response.brand == "Aborder une carrefour à sens giratoire"){
            findQ2 = true;
          }

          if(response.brand == "Aborder une entrée d'autoroute"){
            findQ3 = true;
          }
        });

        if(findQ1){
          questions.push(1);
        }else{
          questions.push(0);
        }

        if(findQ2){
          questions.push(3);
        }else{
          questions.push(0);
        }

        if(findQ3){
          questions.push(2);
        }else{
          questions.push(0);
        }

        let find2Q1 = false;
        let find2Q2 = false;
        let find2Q3 = false;
        let find2Q4 = false;

        this.contractValue.selectedKnows.forEach((response) => {
          if(response.brand == "Allumer tous les feux du véhicule"){
            find2Q1 = true;
          }

          if(response.brand == "Tourner le volant en chevauchant les mains"){
            find2Q2 = true;
          }

          if(response.brand == "Démarrer sur une terrain en cote"){
            find2Q3 = true;
          }

          if(response.brand == "Manipuler les vitesses de 1 à 5"){
            find2Q4 = true;
          }
        });

        if(find2Q1){
          questions.push(1);
        }else{
          questions.push(0);
        }

        if(find2Q2){
          questions.push(1);
        }else{
          questions.push(0);
        }

        if(find2Q3){
          questions.push(1);
        }else{
          questions.push(0);
        }

        if(find2Q4){
          questions.push(2);
        }else{
          questions.push(0);
        }

        let count = 0;
        questions.forEach((q) => {
          count = count + q;
        });

        let heures = [];
        if (count >= 0 && count <= 3) {
          heures = [25, 35];
        } else if (count > 3 && count <= 7) {
          heures = [15, 25];
        } else if (count > 7) {
          heures = [10, 20];
        }
        

        var diff =
            Date.now() - new Date(this.dataForm.dossier.dateNaissance).getTime();
          var age = new Date(diff);

          var age_checked = Math.abs(age.getUTCFullYear() - 1970);
          let codeParent = 'null';
          if(age_checked < 18){
            codeParent = Math.floor(Math.random() * 9000 + 1000);
          }

          this.ageEleve = age_checked;

        questions.push(heures);
        if (this.contract.questions == "null" || this.contract.questions == undefined) {



          window.api
            .post(
              "/contracts",
              {
                eleve: "/api/utilisateurs/" + this.$store.state.user.id,
                question: questions,
                piece: {"status" : "En attente", "code" : Math.floor(Math.random() * 9000 + 1000), "codeParent" : codeParent, "date": "null"}
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              window.api
                .get("/contract/" + this.$store.state.user.id, {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                })
                .then((response) => {
                  let idBoite = 1;
                  if(this.informationComp.boite == "bm"){
                    idBoite = 2;
                  }

                  window.api
                    .patch(
                      "/utilisateur/" + this.$store.state.user.id,
                      {
                        boite: idBoite,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                          "Content-Type": "application/merge-patch+json",
                        },
                      }
                    )
                    .then(() => {
                      console.log("test suite")
                      window.api
                          .post(
                            "/setforfait/"+this.$store.state.user.id,
                            {
                              forfait: this.informationComp.forfait
                            },
                            {
                              headers: {
                                Authorization: "Bearer " + this.$store.state.token,
                                "Content-Type": "application/merge-patch+json",
                              },
                            }
                          )
                          .then(() => {
                            this.contract = response.data;
                            this.error = "";
                            this.desac = true;
                            this.step = this.step + 1;

                            if(this.dataForm.dossier.numNeph == ''){
                              this.priceChoice.name = "dossier";
                              this.priceChoice.price = this.priceChoice.price + this.dossierPrice;
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              // handle authentication and validation errors here
              console.log("ERRR::", error.response.data);
            });
        } else {
          window.api
            .patch(
              "/contracts/" + this.contract.contractEleve[0].id,
              {
                question: questions,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                  "Content-Type": "application/merge-patch+json",
                },
              }
            )
            .then(() => {
              window.api
                .get("/contract/" + this.$store.state.user.id, {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                })
                .then((response) => {
                  let idBoite = 1;
                  if(this.informationComp.boite == "bm"){
                    idBoite = 2;
                  }

                  window.api
                    .patch(
                      "/utilisateur/" + this.$store.state.user.id,
                      {
                        boite: idBoite,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                          "Content-Type": "application/merge-patch+json",
                        },
                      }
                    )
                    .then(() => {
                      window.api
                          .patch(
                            "/dossiers/"+this.$store.state.user.dossier.id,
                            {
                              forfait: this.informationComp.forfait
                            },
                            {
                              headers: {
                                Authorization: "Bearer " + this.$store.state.token,
                                "Content-Type": "application/merge-patch+json",
                              },
                            }
                          )
                          .then(() => {
                            this.contract = response.data;
                            this.error = "";
                            this.desac = true;
                            this.step = this.step + 1;
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                    })
                    .catch((error) => {
                      console.log(error);
                    });

                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              // Indique qu'une pièce n'est plus en cours de validation
              this.isWorkingValider = false;
              console.log(error);
            });
        }
      }
    },

    suivantEtape4(){

      if(!this.contractStatus){
        window.api
        .get("/confirm/contract/"+ this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {

          this.resendMail();
          this.step = this.step + 1;

          if(this.dataForm.dossier.social == "/api/statut_socials/1"){
            this.social = "Etudiant";
          }else if(this.dataForm.dossier.social == "/api/statut_socials/2"){
            this.social = "Lycée";
          }else if(this.dataForm.dossier.social == "/api/statut_socials/3"){
            this.social = "Salarié";
          }else if(this.dataForm.dossier.social == "/api/statut_socials/4"){
            this.social = "Sans emploi";
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }else{
        this.error = "";
        this.step = this.step + 1;
      }


    },

    signContract(){
      if(this.ageEleve >= 18){
          if(parseInt(this.code) == this.contract.contractEleve[0].piece.code){
          window.api
            .patch(
              "/contracts/" + this.contract.contractEleve[0].id,
              {
                piece: {"status" : "valide", "code" : this.code, "codeParent" : "null", "date" : new Date().toLocaleDateString("fr")},
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                  "Content-Type": "application/merge-patch+json",
                },
              }
            )
            .then(() => {
              this.sendContratPdf();
              this.contractStatus = true;
              this.toast.add({
                severity: "success",
                summary: "Félicitation !",
                detail: "Contrat signé avec succès !",
                life: 3000,
              });
            })
            .catch((error) => {
              // Indique qu'une pièce n'est plus en cours de validation

              this.toast.add({
                severity: "error",
                summary: "Erreur !",
                detail: "Veuillez vérifier que vous saisissez le bon code !",
                life: 3000,
              });

              this.isWorkingValider = false;
              console.log(error);
            });
        }else{
          this.toast.add({
                severity: "error",
                summary: "Erreur !",
                detail: "Veuillez vérifier que vous saisissez le bon code !",
                life: 3000,
              });
        }
      }else{
        if(parseInt(this.code) == this.contract.contractEleve[0].piece.code && parseInt(this.codeParentForm) == this.contract.contractEleve[0].piece.codeParent){
          window.api
            .patch(
              "/contracts/" + this.contract.contractEleve[0].id,
              {
                piece: {"status" : "valide", "code" : this.code, "codeParent" :this.codeParentForm, "date" : new Date().toLocaleDateString("fr")},
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                  "Content-Type": "application/merge-patch+json",
                },
              }
            )
            .then(() => {
              this.contractStatus = true;
              this.sendContratPdf();
              this.toast.add({
                  severity: "success",
                  summary: "Félicitation !",
                  detail: "Contrat signé avec succès !",
                  life: 3000,
                });

            })
            .catch((error) => {
              // Indique qu'une pièce n'est plus en cours de validation
              this.isWorkingValider = false;
              console.log(error);

              this.toast.add({
                  severity: "error",
                  summary: "Erreur !",
                  detail: "Veuillez vérifier que vous saisissez le bon code !",
                  life: 3000,
                });

            });
        }else{
          this.toast.add({
                severity: "error",
                summary: "Erreur !",
                detail: "Veuillez vérifier que vous saisissez le bon code !",
                life: 3000,
              });
      }
      }

    },

    resendMail(){
      window.api
        .get("/confirm/contract/"+ this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {

          this.toast.add({
              severity: "success",
              summary: "Le mail a bien été envoyé",
              detail: "N'oubliez pas de regarder dans vos spams au cas où !",
              life: 3000,
            });

        })
        .catch((error) => {
          console.log(error);
        });
    },



    cancelStep() {

      if(this.desac){
        this.desac = false;
      }else{
        this.desac = true;
      }






      this.step = this.step - 1;

      if (this.step == 3) {
        this.desac = false;
      }

      this.priceChoice.price = 0;
      if(this.dataForm.dossier.numNeph == ''){
        this.priceChoice.name = "dossier";
      }else{
        this.priceChoice.name = "";
      }
      

      if(this.dataForm.dossier.numNeph == ''){
        this.priceChoice.price = this.dossierPrice + this.priceChoice.price;
      }
      this.error = "";
      this.lateBtn = false;
    },
  },
};
</script>

<style>
.field {
  position: relative;
}

.labelForm {
  position: absolute;
  pointer-events: none;
  left: 1.25rem;
  top: 7.75px;
  transition: 0.2s ease all;
  font-size: 15px;
}

.field > input:focus ~ label,
input:not(:placeholder-shown) ~ label,
.field > select:focus ~ label,
select:not(:placeholder-shown) ~ label {
  top: -15px;
  font-size: 12px;
}

.left {
  margin: auto;
  width: 100%;
  font-size: 25px;
  height: calc(100vh);
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
}

.right {
  margin: auto;
  padding-top: 4%;
  width: 100%;
  font-size: 25px;
  height: calc(100vh);
  background: rgb(255, 255, 255);
}

.main {
  display: flex;
}

.title {
  font-size: 50px;
}

.desc {
  text-align: left;
  margin-top: 8em;
}

.subTitle {
  text-align: left;
  margin-top: 2em;
  margin-left : 2em;
  font-size: 35px;
}

.isNotValid {
  border-color: red;
}

.isHide {
  display: none;
}

p.errorMsg {
  color: red;
  margin-top: 2em;
  font-size: 20px;
}

.mutltiSelect {
  width: 100%;
}

div.btnForm {
  display: flex;
  justify-content: space-around;
}

div.late{
  width: fit-content;
}

div.choix{
  display: flex;
  width: fit-content;
}

div.forfait {
  margin-left: 2em;
  margin-bottom: 2em;
}

div.forfait input{
  width: 25px;
  height: 25px;
}

div.forfait label{
  font-size: 25px;
  margin-left: 20px;
}

div.contractContainer{
  border : 1px solid black;
  width: 90%;
  margin : auto;
  height: 60vh;
  overflow : scroll;
}

p.resendMail {
  margin-top: 5px;
}

p.resendMail button{
  background-color: #1E5DCA;
  color : white;
  border-radius: 7%;
}

#head {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 100%;
  margin-left: 1em;
}

#titreEleve {
  font-size: 25pt;
  font-weight: bold;
  color: #000000;
}

#iconHome {
  cursor: pointer;
}

</style>
