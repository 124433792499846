<template>
  <div class="box">
    <div class="connexion">
      <router-link to="/"><h1 class="mainTitle">Monpermis.fr</h1></router-link>
      <router-link to="/"
        ><img
          id="iconConnexion"
          :src="require('../assets/images/icon_connexion_.png')"
          class="mb-5"
      /></router-link>

      <div class="container">
        <form @submit.prevent="inscription">
          <div class="row">
            <div class="field col-md-6">
              <input
                type="text"
                id="lnom"
                name="nom"
                placeholder=" "
                v-model="form.nom"
                required
              />
              <label class="labelForm">Nom élève</label>
            </div>
            <div class="field col-md-6">
              <input
                type="text"
                id="lpnom"
                name="pnom"
                placeholder=" "
                v-model="form.pnom"
                required
              />
              <label class="labelForm">Prénom élève</label>
            </div>
          </div>

          <div class="row">
            <div class="field col-md-6">
              <input
                type="email"
                id="fmail"
                name="mail"
                placeholder=" "
                v-model="form.email"
                required
              />
              <label class="labelForm">E-mail</label>
            </div>
            <div class="field col-md-6">
              <input
                type="tel"
                id="ltel"
                name="tel"
                placeholder=" "
                v-model="form.tel"
                required
              />
              <label class="labelForm">Téléphone</label>
            </div>
          </div>

          <div class="row">
            <div class="field col-md-6">
              <input
                type="password"
                id="mdp"
                name="pass"
                placeholder=" "
                v-model="form.password"
              />
              <label class="labelForm">Saisir le mot de passe</label>
              
            </div>
            <div class="field col-md-6">
              <input
                type="password"
                id="mdpConfirmation"
                name="pass2"
                placeholder=" "
                v-model="form.passwordTest"
                required
              />
              <label class="labelForm">Confirmer le mot de passe</label>
              <i
                v-if="
                  this.visibilityMdp === null ||
                  this.visibilityMdp.type === 'password'
                "
                class="pi pi-eye-slash mt-3 ms-4"
                style="font-size: 2.5rem; cursor: pointer"
                @click="changeMdpVisibility()"
              ></i>
              <i
                v-else-if="this.visibilityMdp.type === 'text'"
                class="pi pi-eye mt-3 ms-4"
                style="font-size: 2.5rem; cursor: pointer"
                @click="changeMdpVisibility()"
              ></i>
            </div>
          </div>

          <div class="row">
            <label for="agence">Agence</label>
            <select
              id="agence"
              name="agence"
              class="form-select mb-5"
              v-model="form.choixAgence"
              required
            >
              <!--<option value="noAgence">Ne pas se rattacher à une agence</option>-->
              <option
                v-for="(agence, index) in agences"
                :value="agence.id"
                :key="index"
              >
                {{ agence.nom }}
              </option>
            </select>
          </div>

          <template v-if="isWorking === false">
            <button
              class="btn btn-m mt-2 mb-5"
              type="submit"
              value="inscription"
              id="btnConnexion"
            >
              <span id="textButton"> Creer un compte </span>
            </button>
          </template>
          <template v-else>
            <button
              class="btn btn-m mt-2 mb-5"
              type="submit"
              value="inscription"
              id="btnConnexion"
              disabled
            >
              <span id="textButton"
                ><i class="fa fa-spinner fa-spin"></i> Creer un compte
              </span>
            </button>
          </template>
        </form>
        <b-alert variant="danger" v-if="error != ''" show>{{ error }}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
export default {
  data() {
    return {
      isWorking: false,
      agences: [],
      visibilityMdp: null,
      visibilityConfirm: null,
      form: {
        email: "",
        pnom: "",
        nom: "",
        tel: "",
        password: "",
        passwordTest: "",
        choixAgence: "",
      },
      error: "",
    };
  },
  mounted() {
    this.getAgences();
  },
  methods: {
    // Fonction permettant de changer la visibilité du mdp en modifiant le type du input en "text" ou "password"
    changeMdpVisibility() {
      this.visibilityMdp = document.getElementById("mdp");
      this.visibilityConfirm = document.getElementById("mdpConfirmation");

      if (this.visibilityMdp.type === "password" && this.visibilityMdp.type === "password") {
        this.visibilityMdp.type = "text";
        this.visibilityConfirm.type = "text";
      } else {
        this.visibilityMdp.type = "password";
        this.visibilityMdp = null;
        this.visibilityConfirm.type = "password";
        this.visibilityConfirm = null;
      }
    },

    getAgences() {
      window.api
        .get("/agences")
        .then((response) => {
          this.agences = response.data["hydra:member"];
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    inscription(event) {
      event.preventDefault();
      this.isWorking = true;
      if (this.form.tel.length > 10) {
        this.error = "Veuillez saisir un numéro de téléhone inférieur à 10";
      } else if (this.form.password != this.form.passwordTest) {
        this.error =
          "Veuillez saisir les mêmes mots de passe dans les champs correspondants";
      } else {
        this.error = "";

        if (this.form.choixAgence == "noAgence") {
          window.api
            .post("/signup", {
              email: this.form.email,
              password: this.form.password,
              prenom: this.form.pnom,
              nom: this.form.nom,
              telephone: this.form.tel,
              active: 1,
            })
            .then(() => {
              this.login();
            })
            .catch((error) => {
              this.isWorking = false;
              console.log(error);
            });
        } else {
          window.api
            .post("/signup", {
              email: this.form.email,
              password: this.form.password,
              prenom: this.form.pnom,
              nom: this.form.nom,
              telephone: this.form.tel,
              active: 1,
              agence: this.form.choixAgence,
            })
            .then((response) => {
              if(response.data.message !== undefined) {
                this.error = response.data.message;
                this.isWorking = false;
              } else {
                this.login();
              }
            })
            .catch((error) => {
              this.isWorking = false;
              console.log(error);
            });
        }
      }
    },

    //permet de stocker dans le store le token et le user connecté
    login() {
      window.api
        .post("/login_check", {
          username: this.form.email,
          password: this.form.password,
        })
        .then((response) => {
          this.error = "";
          let user = jwt_decode(response.data.token);
          this.$store.commit("setUser", user);
          this.$store.commit("setToken", response.data.token);

          window.api.post("/sendMail", {
            type: "inscription",
            email: this.form.email,
          },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(() => {
            this.$router.push("/home");
            this.isWorking = false;
          }).catch(error => {
            console.log(error)
          })


        })
        .catch(() => {
          this.isWorking = false;
          this.error = "Mot de passe ou mail incorrect";
        });
    },
  },
};
</script>

<style scoped>

h1.mainTitle{
  font-size: 50px;
}
.field {
  position: relative;
  display: flex;
  justify-content: center;
}

.labelForm {
  position: absolute;
  pointer-events: none;
  left: 1.25rem !important;
  top: 22px !important;
  transition: 0.2s ease all;
  font-size: 20px !important;
  color: #444 !important;
}

.field input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: -20px !important;
  font-size: 12px !important;
}

input {
  width: 100%;
}

a {
  text-decoration: none;
}
.box {
  background: transparent linear-gradient(299deg, #fbbb5b 0%, #de6fa8 100%) 0%
    0% no-repeat padding-box !important;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
}

#iconConnexion {
  width: 40%;
}

::placeholder {
  color: #f5f5f5;
  font-size: 15px;
  padding-left: 2%;
}

#btnConnexion {
  background: #2e9ea3 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  width: 60%;
  height: 70px;
}

#btnConnexion span {
  font-size: 170%;
  text-transform: uppercase;
  font: normal normal 800 30px/41px Galano Grotesque Alt;
  letter-spacing: 0px;
  color: #fbfbfb;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  input,
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  #textButton {
    font-size: 1.2em !important;
  }

  #iconConnexion {
    width: 60%;
  }

  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 30px;
    box-sizing: border-box;
  }

  #btnConnexion {
    background: #2e9ea3 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    width: 90%;
    height: 70px;
  }

  h1 {
    color: #ffece0;
    font-size: 1.5rem;
    font-family: Galano Grotesque Alt Medium;
    word-wrap: break-word;
  }

  input[type="password"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  div.connexion {
    border-radius: 10px;
    width: 90%;
    margin: auto;
    padding: 60px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #textButton {
    font-size: 1.2em !important;
  }
  h1 {
    color: #ffece0;
    font-size: 2.5rem;
    word-wrap: break-word;
  }

  #iconConnexion {
    width: 100%;
  }

  input,
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  #btnConnexion {
    background: #2e9ea3 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    width: 90%;
    height: 70px;
  }

  input[type="password"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  div.connexion {
    border-radius: 10px;
    width: 80%;
    margin: auto;
    padding: 60px;
  }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  h1 {
    color: #ffece0;
    font-size: 6rem;
    word-wrap: break-word;
  }

  div.connexion {
    border-radius: 10px;
    width: 60%;
    margin: auto;
  }

  h2 {
    color: #ffece0;
    font-size: 2.5em;
    border-width: 0.1px;
    display: inline-block;
  }

  a {
    color: #ffece0;
  }

  .text-field,
  text {
    position: relative;
    margin: 10px 2.5px 20px 2.5px;
  }

  input {
    display: inline-block;
    border: solid 3px #fafafa;
    margin-bottom: 8%;
    color: #444;
    padding: 5px 5px 5px 5px;
    height: 75px;
    background: transparent;
    border-radius: 10px;
  }

  input:focus {
    border: thin solid #ffffff;
    border-bottom: solid medium #ffffff;
    background: transparent;
  }
}
</style>
